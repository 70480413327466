import { axiosPrivate } from '../hooks/axios/axios';
import { t } from 'i18next';

async function getBrandsByOwnerID(owner_id) {
  const url = '/api/brands/brand/owner';

  try {
    const response = await axiosPrivate.get(`${url}?ownerID=${owner_id}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    const { data, status } = response;

    if (status === 200) {
      return {
        status: data.status,
        message: data.message,
        data: data.brands,
      };
    } else {
      return {
        status: data.status,
        message: data.message,
      };
    }
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

async function searchBrands(payload) {
  // Guard Clause
  let req_fields = [];
  !payload && req_fields.push(t('routes.req_fields.payload'));

  if (req_fields.length > 0) {
    throw new Error(
      t('routes.responses.req_fields', { requiredFields: req_fields }),
    );
  }

  try {
    const controller = new AbortController();
    const signal = controller.signal;
    const url = '/api/brands/search';

    const request = await axiosPrivate.post(url, JSON.stringify(payload), {
      signal,
    });

    const response = request.data;

    if (request.status) {
      return response;
    }
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

async function addBrands(brands_list, companyInfo) {
  const url = '/api/brands/add';

  const payload = brands_list.map((brand) => ({
    ...brand,
    owner_id: companyInfo._id,
  }));

  try {
    const response = await axiosPrivate.post(url, JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    const { data, status } = response;

    if (status === 200) {
      return {
        data: data.data,
        status: data.status,
        statusCode: data.statusCode,
        message: data.message,
      };
    } else {
      return {
        statusCode: data.statusCode,
        status: data.status,
        message: data.message,
      };
    }
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

async function getBrandsToRepresent() {
  try {
    const { data, status } = await axiosPrivate.get(
      '/api/brands/brands-to-represent',
    );

    if (status === 200) {
      return data.data;
    }
  } catch (error) {
    console.error('error', error);
  }
}

export { getBrandsByOwnerID, addBrands, searchBrands, getBrandsToRepresent };
