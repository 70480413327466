// Proides Context to MapBox maps, in paticular where we map locations such as contact us, user address, company address etc.

import { createContext, useState } from 'react';
import { geoCodeAddress } from '../../../routes/mapRoutes';
import useResponseHandling from '../../../hooks/useResponseHandler';

export const MapLocationByAddressContext = createContext({});

const MapLocationByAddressProvider = ({ children }) => {
  const { handleErrorResponse } = useResponseHandling();

  const [mapAddress, setMapAddress] = useState({
    name: '',
    street: '',
    postcode: '',
    state: '',
    country: '',
    coordinates: { latitude: '', longitude: '' },
  });

  // Get Updated Coordinates
  async function getUpdatedCoordinates(param) {
    try {
      const getLocation = await geoCodeAddress(param);

      if (getLocation.statusCode === 200) {
        setMapAddress({
          name: param.name,
          street: param.street,
          postcode: param.postcode,
          state: param.state,
          country: param.country,
          coordinates: {
            latitude: getLocation.location.lat,
            longitude: getLocation.location.lat,
          },
        });
        return getLocation;
      } else {
        return getLocation;
      }
    } catch (err) {
      return handleErrorResponse(err);
    }
  }

  return (
    <MapLocationByAddressContext.Provider
      value={{ mapAddress, setMapAddress, getUpdatedCoordinates }}
    >
      {children}
    </MapLocationByAddressContext.Provider>
  );
};

export default MapLocationByAddressProvider;
