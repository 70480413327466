// Handles Form Title, Description
// To DO: Consier merging it with LayoutContextProvider

import { createContext, useState } from 'react';

export const FormBodyContext = createContext();

const FormContextProvider = ({ children }) => {
  const [formTitle, setFormTitle] = useState(null);
  const [formDescription, setFormDescription] = useState(null);
  const [showForm, setShowForm] = useState(true);

  return (
    <FormBodyContext.Provider
      value={{
        formTitle,
        setFormTitle,
        formDescription,
        setFormDescription,
        showForm,
        setShowForm,
      }}
    >
      {children}
    </FormBodyContext.Provider>
  );
};

export default FormContextProvider;
