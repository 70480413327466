import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ListItemButton,
  ListItemText,
  Divider,
  List,
  Grid,
  Collapse,
  useTheme,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import SubMenu from '../drawer/SubMenu';
import { motion } from 'framer-motion';
import {
  NavList,
  NavItem,
  LanguageItem,
} from '../../../framer-motion/animations';
import { useTranslation } from 'react-i18next';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker';
import DrawerContent from '../drawer/DrawerContent';
import { NavBarDrawerContext } from '../../../context/providers/NavBarDrawerContextProvider';

export const SubNavDrawerContext = createContext({});

const NonAuthNavItems = ({ mode }) => {
  const navigate = useNavigate();
  const { handleDrawerClose } = useContext(NavBarDrawerContext);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const { gaEventTracker } = useAnalyticsEventTracker();

  const handleSubMenuOpen = () => {
    setOpenSubmenu(!openSubmenu);
  };

  const { t } = useTranslation('buttons', {
    keyPrefix: 'navigation',
  });

  const theme = useTheme();

  let generalNavItems = [
    {
      index: 20,
      key: 'Home',
      label: t('home'),
      icon: '',
      target: () => handleNavigate('Home'),
      event: 'Home',
      visible: 'drawer',
    },
    {
      index: 30,
      key: 'HowItWorks',
      label: t('learn_more'),
      icon: '',
      target: () => handleNavigate('HowItWorks'),
      event: 'Learn More',
      visible: 'drawer',
      variant: 'contained',
      color: 'primary',
    },
    {
      index: 50,
      key: 'Events',
      label: t('events'),
      icon: '',
      event: 'Events',
      visible: 'drawer',
      children: [
        {
          isChildren: true,
          index: 51,
          parent: 'events',
          key: 'salesEvents',
          label: t('sales_events'),
          icon: '',

          target: () => handleNavigate('SalesEvents'),
          event: 'SalesEvents',
          visible: 'drawer',
        },
        {
          isChildren: true,
          parent: 'events',
          index: 52,
          key: 'vendorEvents',
          label: t('vendor_events'),
          icon: '',

          target: () => handleNavigate('VendorEvents'),
          event: 'VendorEvents',
          visible: 'drawer',
        },
      ],
    },

    {
      index: 40,
      key: 'Investors',
      label: t('investors'),
      icon: '',

      target: () => handleNavigate('Invest'),
      event: 'Investors',
      visible: 'drawer',
      variant: 'contained',
      color: 'warning',
    },
    {
      index: 60,
      key: 'Company',
      label: t('company'),
      icon: '',

      target: () => handleNavigate('Company'),
      event: 'About Company',
      visible: 'drawer',
    },
    {
      index: 70,
      key: 'Contact',
      label: t('contact_us'),
      icon: '',

      target: () => handleNavigate('Contact'),
      event: 'Contact Us',
      visible: 'drawer',
    },
    {
      index: 80,
      key: 'Blog',
      label: t('blog'),
      icon: '',
      target: () => handleNavigate('Blog'),
      event: 'Blog',
      visible: 'drawer',
    },

    { index: 97, key: 'Divider', label: '', visible: 'drawer' },
    {
      index: 98,
      key: 'SignIn',
      label: t('sign_in'),
      icon: '',

      target: () => handleNavigate('SignIn'),
      event: 99,
      visible: 'all',
      variant: 'outlined',
      drawerButton: true,
    },
    {
      index: 100,
      key: 'SignUp',
      label: t('sign_up'),
      icon: '',
      target: () => handleNavigate('SignUp'),
      event: 'Sign Up',
      visible: 'all',
      variant: 'submit',
      drawerButton: true,
      color: 'success',
    },
  ];

  // DEV MENU ITEMS
  if (process.env.REACT_APP_ENV === 'development') {
    generalNavItems = [
      ...generalNavItems,
      {
        index: 10,
        key: 'Products',
        label: t('products'),
        icon: '',
        target: () => handleNavigate('Products'),
        event: 'Products',
        visible: 'drawer',
      },
      {
        index: 90,
        key: 'Links',
        label: t('links'),
        icon: '',

        target: () => handleNavigate('Links'),
        event: 'Links',
        visible: 'drawer',
      },
    ];
  }

  // Sort Maw Items
  generalNavItems.sort(function (a, b) {
    var keyA = a.index,
      keyB = b.index;
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  function handleNavigate(target) {
    handleDrawerClose();
    if (target === 'Home') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Home',
      });
      return navigate('/');
    } else if (target === 'Blog') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Blog',
        variant: 'outlined',
      });
      return navigate('/blog');
    } else if (target === 'SignUp') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User Registration',
        variant: 'outlined',
      });
      return navigate('/account/signup');
    } else if (target === 'SignIn') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User SignIn',
        variant: 'contained',
      });
      return navigate('/account/signin');
    } else if (target === 'HowItWorks') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'How It Woks',
      });
      return navigate('/how-it-works');
    } else if (target === 'SalesEvents') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Sales Events',
      });
      return navigate('/events/sales');
    } else if (target === 'VendorEvents') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Vendor Events',
      });
      return navigate('/events/vendors');
    } else if (target === 'Company') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'About the Company',
      });
      return navigate('/company');
    } else if (target === 'Contact') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Contact us',
      });
      return navigate('/contact');
    } else if (target === 'Invest') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Invest in us',
      });
      return navigate('/investor');
    } else if (target === 'Products') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Products',
      });
      return navigate('./products');
    } else if (target === 'Links') {
      return navigate('/credits');
    }
  }

  if (mode === 'NavBar') {
    return <DrawerContent generalNavItems={generalNavItems} mode={mode} />;
  } else {
    return (
      <SubNavDrawerContext.Provider
        value={{
          openSubmenu,
          setOpenSubmenu,
          handleNavigate,
          handleDrawerClose,
        }}
      >
        {generalNavItems.map((menuItem) =>
          (menuItem.visible === 'drawer' || menuItem.visible === 'all') &&
          menuItem.key === 'Divider' ? (
            <Divider key={menuItem.index} sx={theme.components.DrawerDivider} />
          ) : (
            <Grid item key={menuItem.index} sx={{ margin: '0px 5px 0 5px' }}>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  // bgcolor: 'background.paper',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <motion.div
                  animate="visible"
                  variants={NavList}
                  initial="hidden"
                >
                  {menuItem.drawerButton === true ? (
                    <motion.div
                      variants={LanguageItem}
                      whileHover={{ scale: 1.025 }}
                    >
                      <Button
                        sx={{ width: '100%' }}
                        key={`${mode}.button.${menuItem.index}`}
                        variant={menuItem.variant}
                        color={menuItem?.color || 'primary'}
                        type="submit"
                        onClick={menuItem.target}
                      >
                        {menuItem.label}
                      </Button>
                    </motion.div>
                  ) : menuItem?.children?.length > 0 ? (
                    <motion.li variants={NavItem}>
                      <ListItemButton
                        sx={{
                          height: '2rem',
                          justifyContent: 'initial',
                          px: 2.5,
                        }}
                        onClick={handleSubMenuOpen}
                        key={menuItem.key}
                      >
                        <ListItemText
                          primary={menuItem.label}
                          disableTypography
                        />

                        {openSubmenu ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {menuItem.children.map((subItem) => (
                            <SubMenu menuItem={subItem} key={subItem.key} />
                          ))}
                        </List>
                      </Collapse>
                    </motion.li>
                  ) : (
                    <motion.li variants={NavItem} whileHover={{ scale: 1.05 }}>
                      <ListItemButton
                        sx={{
                          height: '2rem',
                          justifyContent: 'initial',
                          px: 2.5,
                        }}
                        onClick={() => {
                          menuItem.target();
                          handleDrawerClose();
                        }}
                        key={menuItem.key}
                      >
                        <ListItemText
                          secondary={menuItem.label}
                          disableTypography
                        />
                      </ListItemButton>
                    </motion.li>
                  )}
                </motion.div>
              </List>
            </Grid>
          ),
        )}
      </SubNavDrawerContext.Provider>
    );
  }
};

export default NonAuthNavItems;
