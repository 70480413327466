import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsTracker';
import { useTranslation } from 'react-i18next';

const FooterComponent = () => {
  const gaEventTracker = useAnalyticsEventTracker();

  const { t } = useTranslation('translation', { keyPrefix: 'context.footer' });

  return (
    <footer>
      {/* Footer Nav */}
      <Grid container direction="column" className="intu__footer dark-text">
        <Grid
          item
          xs={12}
          container
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
        >
          {/* Column 1 */}
          <Grid
            item
            xs={4}
            container
            direction="row"
            textAlign="center"
            className="intu__footer-column"
          >
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h5">{t('resources')}</Typography>
            </Grid>

            {/* Privacy Policy */}
            <Grid item xs={12}>
              <Link
                className="footer-link"
                to="privacy"
                onClick={() =>
                  gaEventTracker({
                    category: 'Navigation',
                    action: 'click',
                    label: 'Privacy',
                  })
                }
              >
                {t('privacy')}
              </Link>
            </Grid>

            {/* Terms of Service */}
            <Grid item xs={12}>
              <Link
                className="footer-link"
                to="terms-of-use"
                onClick={() =>
                  gaEventTracker({
                    category: 'Navigation',
                    action: 'click',
                    label: 'Terms of Service',
                  })
                }
              >
                {t('tos')}
              </Link>
            </Grid>
            {/* Credits Explainer */}
            {/* <Grid item xs={12}>
              <Link
                className="footer-link"
                to="credits"
                onClick={() =>
                  gaEventTracker({
                    category: 'Navigation',
                    action: 'click',
                    label: 'Credits Explainer',
                  })
                }
              >
                {t('links')}
              </Link>
            </Grid> */}

            {/* Contact */}
            <Grid item xs={12}>
              <Link
                className="footer-link"
                to="contact"
                onClick={() =>
                  gaEventTracker({
                    category: 'Navigation',
                    action: 'click',
                    label: 'Contact',
                  })
                }
              >
                {t('contact')}
              </Link>
            </Grid>
            {/* About US */}
            <Grid item xs={12}>
              <Link
                className="footer-link"
                to="company"
                onClick={() =>
                  gaEventTracker({
                    category: 'Navigation',
                    action: 'click',
                    label: 'About',
                  })
                }
              >
                {t('company')}
              </Link>
            </Grid>
            {/* Blog */}
            <Grid item xs={12}>
              <Link
                className="footer-link"
                to="blog"
                onClick={() =>
                  gaEventTracker({
                    category: 'Navigation',
                    action: 'click',
                    label: 'Blog',
                  })
                }
              >
                {t('blog')}
              </Link>
            </Grid>
          </Grid>
          {/* Column 2 */}
          <Grid
            item
            xs={4}
            container
            direction="row"
            textAlign="center"
            className="intu__footer-column"
          >
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h5">{t('follow')}</Typography>
            </Grid>
            {/* Social */}
            <Grid item xs={12}>
              <Stack spacing={1} justifyContent="center" direction="row">
                <IconButton
                  aria-label="linkedin"
                  target="_blank"
                  href="https://www.linkedin.com/company/intutec/"
                  className="social"
                  onClick={() =>
                    gaEventTracker({
                      category: 'Social',
                      action: 'click',
                      label: 'LinkedIn',
                    })
                  }
                >
                  <LinkedInIcon fontSize="large" className="social" />
                </IconButton>
                <IconButton
                  aria-label="youtube"
                  target="_blank"
                  href="https://www.youtube.com/@In2tecIO"
                  className="social"
                  onClick={() =>
                    gaEventTracker({
                      category: 'Social',
                      action: 'click',
                      label: 'YouTube',
                    })
                  }
                >
                  <YouTubeIcon fontSize="large" className="social" />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {/* Bottom Section */}
        <Grid
          container
          direction="column"
          className="intu__footer-bar light-text"
        >
          <Grid item xs={12}>
            {t('copyright', {
              year: new Date().getFullYear(),
            })}
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

export default FooterComponent;
