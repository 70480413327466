import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {
  Drawer,
  Box,
  Grid,
  IconButton,
  Divider,
  List,
  Toolbar,
  useTheme,
  Skeleton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Animation
import { motion } from 'framer-motion';
import { NavContainer } from '../../framer-motion/animations';

// Nav Context
import AllAccountsNavItems from './navitems/AllAccountsNavItems';
import AdminNavItems from './navitems/AdminNavItems';
import UserNavItems from './navitems/UserNavItems';
import { NavBarDrawerContext } from '../../context/providers/NavBarDrawerContextProvider';
import useUser from '../../hooks/useUser';
import { IntuAppBar } from '../../mui/appBar';
import DrawerFooterContent from './drawer/DrawerFooterContent';
import { DrawerHeaderContent } from './drawer/DrawerHeaderContent';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';

const AuthNavbarComponent = () => {
  const { isAdmin } = useAuth();
  const { handleLanguageChange } = useUser();
  const { theme, userTheme } = useContext(IntuThemeContext);
  const navigate = useNavigate();
  const { handleDrawerClose, handleDrawerOpen, drawerOpen } =
    useContext(NavBarDrawerContext);

  const DrawerBodyContent = () => {
    return (
      <>
        <List>
          <AllAccountsNavItems />
        </List>
        <Divider sx={theme.components.DrawerDivider} />
        <List sx={theme.components.DrawerListItem}>
          {isAdmin === true ? <AdminNavItems /> : <UserNavItems />}
        </List>
      </>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {!userTheme?.logo_url ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={theme.components.MuiAppBar.styleOverrides.root.height}
        />
      ) : (
        <IntuAppBar position="fixed" open={drawerOpen}>
          <Toolbar variant="dense" sx={{ maxWidth: '100%' }}>
            <Box display="flex" alignItems="center" flex={1}>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  mr: 2,
                  ...(drawerOpen && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                onClick={() => navigate('/')}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  },
                }}
              >
                <Box
                  component="img"
                  alt={userTheme.logo_alt}
                  sx={{
                    width: '100%',
                    height: '60px',
                    margin: '10px 0 10px 0',
                    backgroundColor: 'transparent',
                  }}
                  src={userTheme?.logo_url}
                  style={{ backgroundColor: 'transparent' }}
                />
              </IconButton>
            </Box>
          </Toolbar>
          <motion.div>
            <Drawer
              sx={{
                flexShrink: 0,
              }}
              variant="temporary"
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerClose}
              variants={NavContainer}
              animate={drawerOpen ? 'visible' : 'hidden'}
              initial="hidden"
            >
              <Grid container direction={'column'}>
                <Grid item>
                  <DrawerHeaderContent />
                </Grid>
                <Grid item>
                  <DrawerBodyContent />
                </Grid>
                <Grid item>
                  <DrawerFooterContent
                    onLanguageChange={handleLanguageChange}
                  />
                </Grid>
              </Grid>
            </Drawer>
          </motion.div>
        </IntuAppBar>
      )}
    </Box>
  );
};

export default AuthNavbarComponent;
