import i18next from 'i18next';
import * as Yup from 'yup';

export const contentCreationPluginValidation = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    content_type: Yup.string().required(t('content_type')),
    content_text: Yup.string()
      .min(20, ({ value, min }) => {
        let characterCount = value.trim().length;
        return t('content_too_short', { characterCount, min });
      })
      // .max(1000, ({ value, max }) => {
      //   let characterCount = value.trim().length;
      //   return t('content_too_long', { characterCount, max });
      // })
      .required(t('content')),
    addressee: Yup.array().when('content_mode', {
      is: 'create' || 'tweak',
      then: () => Yup.array().required(t('addressee')),
    }),
    language: Yup.string().when('content_mode', {
      is: 'translate',
      then: () => Yup.string().required(t('languages_min')),
    }),
    content_mode: Yup.string().required(t('content_mode')),
  });
};
