import React, { useContext, useEffect, useState } from 'react';
import ClusteredMap from '../../../maps/ClusteredMap';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { PieChartContext } from '../../../../charts/pieChart';
import { Grid, Typography } from '@mui/material';
import useIntuTheme from '../../../../hooks/useIntuTheme';

const ExistingCustomersByIndustry = () => {
  // Subscribe to Context
  const { handleErrorResponse } = useResponseHandling();
  const { mobileView } = useIntuTheme();

  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.howItWorks.lead_context',
  });

  // SetUp Axios
  const axios = useAxiosPrivate();

  // SetUp States
  const [customerLocations, setCustomerLocations] = useState([]);
  const [customerIndustries, setCustomerIndustries] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  async function getCustomers() {
    const controller = new AbortController();
    const { signal } = controller;
    const url = '/api/customers/public/list';

    try {
      const request = await axios.get(url, {
        signal,
        headers: { 'Content-Type': 'application/json' },
      });

      const response = request?.data;
      if (request.status === 200) {
        const { data } = response;

        // Prepare Customer Locations
        let locations = [];
        let industries = [];

        for (let i = 0; i < data.length; i++) {
          const location = data[i].location;
          const customer = data[i].company;
          const industry = data[i].industry;
          if (location?.lng && location?.lat) {
            locations.push({
              name: customer,
              content: `<h3>${customer}</h3><p>${industry}</p>`,
              category: industry,
              coordinates: {
                lat: location.lat,
                lng: location.lng,
              },
            });
          }

          if (industry) {
            industries.push(industry);
          }
        }

        // Prepare Industries Chart
        const industryChartData = categorizeAndCount(industries);

        // Here you can use industryChartData to do what you need to do next.
        // For example, if you need to set it to state:
        setCustomerIndustries({
          id: 'customerIndustries',
          title: t('industry_chart_title'),
          description: t('industry_chart_desc'),
          data: industryChartData,
        });

        // Filter the locations based on the category
        const filteredLocations = locations.filter((location) =>
          industryChartData.some(
            (categoryItem) => categoryItem.category === location.category,
          ),
        );

        // Now you have a list of locations that match the categories
        // from the categorizeAndCount function
        setCustomerLocations(filteredLocations);

        //SetUp Customer Locations based on the categorizeAndCountFunction
      }
      setIsDataLoaded(true);
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    } finally {
      controller.abort(signal);
    }
  }

  // Categorize Customer Industries
  function categorizeAndCount(array) {
    const countMap = array.reduce((acc, value) => {
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});

    const categorizedArray = Object.keys(countMap)
      .map((key) => {
        return { category: key, value: countMap[key] };
      })
      .filter((item) => item.value > 200);

    // Instead of setting the state, just return the array
    return categorizedArray;
  }

  useEffect(() => {
    async function preLoadFormData() {
      await getCustomers();
    }

    preLoadFormData();
  }, []);

  return (
    <>
      <Typography variant="h4" textAlign="center">
        {t('cust_industry_title')}
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        sx={{ marginBottom: '2rem' }}
      >
        {t('cust_industry_content')}
      </Typography>
      <ClusteredMap
        initialZoom={2}
        locations={customerLocations}
        popup={true}
        cluster={false}
        projection="globe"
        center={[-98.5795, 39.8283]}
        fullwidth={true}
        isDataLoaded
      />

      <Grid item container xs={12}>
        <PieChartContext
          data={customerIndustries?.data}
          chartId={customerIndustries?.id}
          mobileView={mobileView}
          displayValueAsPercentage={false}
          displayValueAsLabel="Customers"
        />
      </Grid>
    </>
  );
};

export { ExistingCustomersByIndustry };
