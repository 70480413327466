import { useEffect, useState } from 'react';
import {
    Grid,
    Button,
    Typography,
    Chip,
    Box,
    Skeleton,
    useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useContext } from 'react';
import ClusteredMap from '../../components/maps/ClusteredMap';
import { useParams } from 'react-router-dom';
import { CustomerMapContext } from '../providers/maps/CustomerMapProvider';
import { UserPublicProfileContext } from '../providers/profile/UserPublicProfileProvider';
import { useProcessingHandler } from '../../hooks/useProcessingHandler';
import { ProfileHeaderComponent } from '../../components/profile/ProfileHeaderComponent';
import useResponseHandling from '../../hooks/useResponseHandler';
import AuthContext from '../providers/AuthProvider';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import { ProfileAnalyticsChart } from '../../components/profile/ProfileAnalyticsChart';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import IntuThemeContext from '../providers/IntuThemeProvider';
import { searchBrands } from '../../routes/brandRoutes';
import { searchProductTypes } from '../../routes/productsRoutes';
import { Link } from 'react-router-dom';
import RecruitDialog from '../../components/profile/RecruitDialog';

export const UserPublicProfileContent = () => {
    const { getUserInfo, handleSearchForAssociatedCustomers, requestToRecruit } =
        useContext(UserPublicProfileContext);
    const {
        findMedian,
        calculateDistanceInMiles,
        calculateFurthestPoints,
        center,
        setCenter,
        furthestPoints,
        setFurthestPoints,
        zoomLevel,
        setZoomLevel,
    } = useContext(CustomerMapContext);
    const { id } = useParams();
    const [currentUserInfo, setCurrentUserInfo] = useState({});
    const [customerList, setCustomerList] = useState([]);
    const [customerLocations, setCustomerLocations] = useState([]);
    const [isLoadingMapData, setIsLoadingMapData] = useState(true);
    const [userOrders, setUserOrders] = useState([]);
    const [userOffers, setUserOffers] = useState([]);
    const { setIsLoading } = useProcessingHandler();
    const [userMiscInfo, setUserMiscInfo] = useState({
        member_since: '',
        location: '',
        brands: 0,
        sales_yoy: 0,
    });
    const [isRecruitDialogOpen, setIsRecruitDialogOpen] = useState(false);
    const { handleErrorResponse } = useResponseHandling();
    const { auth } = useContext(AuthContext);
    const axios = useAxiosPrivate();
    const [orderChartData, setOrderChartData] = useState([]);
    const [offerChartData, setOfferChartData] = useState([]);
    const [isChartLoading, setIsChartLoading] = useState(false);
    const [activeChartData, setActiveChartData] = useState('order');
    const [userBrands, setUserBrands] = useState([]);
    const { mobileView, mode } = useContext(IntuThemeContext);
    const theme = useTheme();

    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.PublicProfile',
    });
    const { t: transTypes } = useTranslation('types');

    // Grab user's profile using ID param
    async function fetchUserInfo(id) {
        const userInfo = await getUserInfo(id);
        setCurrentUserInfo(userInfo);
    }

    // Grab customers associated to user
    async function getCustomerList(id) {
        const customers = await handleSearchForAssociatedCustomers(id);
        const filteredCustomers = customers.filter(customer => customer.location !== null && customer.location !== undefined && customer.location !== '');
        setCustomerList(filteredCustomers);
    }

    // Handle get Orders
    async function getOrders(id) {
        // Guard Clause
        if (!auth.user_info && !auth.auth_info) {
            return;
        }
        const url = '/api/orders/orders/search';
        const payload = {
            data: [
                {
                    key: 'associate',
                    value: [id],
                },
            ],
        };

        try {
            const response = await axios.post(url, JSON.stringify(payload), {
                headers: { 'Content-Type': 'application/json' },
            });
            const { data, status } = response;

            if (status === 200) {
                return data.data;
            } else {
                handleErrorResponse(data);
            }
        } catch (error) {
            handleErrorResponse(error);
        }
    }

    // Handle getOffers
    async function getOffers(id) {
        // Guard Clause
        if (!auth.user_info && !auth.auth_info) {
            return;
        }
        const url = '/api/offers/offers/search';
        const payload = {
            data: [
                {
                    key: 'associate',
                    value: [id],
                },
            ],
        };
        try {
            const response = await axios.post(url, JSON.stringify(payload), {
                headers: { 'Content-Type': 'application/json' },
            });
            const { data, status } = response;

            if (status === 200) {
                return data.data;
            } else {
                handleErrorResponse(data);
            }
        } catch (error) {
            handleErrorResponse(error);
        }
    }

    // Preload Info
    useEffect(() => {
        preLoadInfo();
    }, []);

    async function preLoadInfo() {
        setIsLoading({
            status: true,
            text: `Loading ${currentUserInfo?.name}'s Profile`,
            type: 'spinner',
        });
        await fetchUserInfo(id);
        await getCustomerList(id);
        const orders = await getOrders(id);
        const offers = await getOffers(id);
        setUserOrders(orders);
        setUserOffers(offers);
        setIsLoading({
            status: false,
            text: '',
            type: 'spinner',
        });
    }

    function setMiscInfo() {
        const start_date = new Date(currentUserInfo?.created?.date);
        const user_location = {
            city: currentUserInfo?.address?.city,
            state: currentUserInfo?.address?.state,
        };
        const total_revenue = getTotalYearRevenue().toFixed(2);
        setUserMiscInfo({
            ...userMiscInfo,
            member_since: start_date.toLocaleDateString(),
            location: user_location,
            sales_yoy: '$' + Number(total_revenue).toLocaleString(),
            brands: currentUserInfo?.brands?.length,
        });
    }

    function getPastYear(arr) {
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 12);
        const filtered = arr?.filter((item) => {
            const itemDate = new Date(item.created.date);
            return itemDate >= sixMonthsAgo;
        });
        return filtered;
    }

    function getTotalYearRevenue() {
        let total = 0;
        const pastYearOrders = getPastYear(userOrders);
        pastYearOrders.map((order) => {
            total += order.grand_total;
        });
        return total;
    }

    // Format Order and Offers data by month nad year
    function formatDataByMonth(arr, type) {
        const newArr = [];
        arr?.forEach((element) => {
            const date = type === 'order' ? new Date(element.order_date) : new Date(element.offer_date);
            const month = date.getMonth();
            const year = date.getFullYear();
            const month_name = date.toLocaleString('default', { month: 'long' });

            // Create a category string that includes both month and year
            const category = `${month_name} ${year}`;

            // Search if month-year key exists
            const lowercase_month = month_name.toLowerCase();
            const trans_month = transTypes(`months.${lowercase_month}`);
            const month_year_obj = newArr.find((item) => item.category === category);

            // If month-year combination doesn't exist
            if (!month_year_obj) {
                newArr.push({
                    category: `${trans_month} ${year}`,
                    value: element.grand_total,
                    date: date // Store the full date for sorting
                });
            } else {
                month_year_obj.value += element.grand_total;
            }
        });

        // Sort the array by date
        newArr.sort((a, b) => a.date - b.date);

        return newArr;
    }

    async function getBrandsOwnerIDs(brandsIdArr) {
        const payload = {
            key: '_id',
            values: brandsIdArr,
        };
        try {
            const { data, status } = await searchBrands(payload);
            if (status === 'success') {
                return data;
            }
        } catch (error) {
            handleErrorResponse(error);
        }
    }

    async function getBrandParentCompanyInfo(ownerIdArr) {
        const companiesData = await Promise.all(
            ownerIdArr?.map(async (owner) => {
                const payload = {
                    _id: owner.owner_id,
                };
                const url = `/api/companies/company/public/`;

                try {
                    const { data, status } = await axios.post(
                        url,
                        JSON.stringify(payload),
                        {
                            headers: { 'Content-Type': 'application/json' },
                        },
                    );

                    if (status === 200) {
                        let image = '';
                        if (data.data.profile_picture?.mimetype) {
                            image = `data:${data.data.profile_picture.mimetype};base64,${data.data.profile_picture.buffer.toString('base64')}`;
                        }
                        let date;
                        if (data.data.created) {
                            date = new Date(data.data.created?.date);
                        } else {
                            date = new Date();
                        }
                        const companyInfo = {
                            id: data.data._id,
                            name: data.data.name,
                            logo: image,
                            region: {
                                state: data.data.address.state,
                                country: data.data.address.country.label,
                            },
                            website: data.data.website,
                            product_types: await handleGetProductTypes(
                                data.data.product_types,
                            ),
                            start_date: date.toLocaleDateString(),
                        };
                        return companyInfo;
                    }
                } catch (error) {
                    handleErrorResponse(error);
                }
            }),
        );
        return companiesData;
    }

    // Load DataGrid Info
    useEffect(() => {
        async function loadDataGridInfo() {
            const ownerIds = await getBrandsOwnerIDs(currentUserInfo?.brands);
            if (ownerIds?.length > 0) {
                const companies = await getBrandParentCompanyInfo(ownerIds);
                setUserBrands(companies);
            }
        }
        setMiscInfo();
        loadDataGridInfo();
    }, [currentUserInfo, userOrders]);

    // Search Product Types
    async function handleGetProductTypes(productTypeId) {
        // Guard Clause
        if (productTypeId?.length === 0) {
            return;
        }
        try {
            const payload = {
                key: '_id',
                values: productTypeId,
            };
            const { status, data } = await searchProductTypes(payload);
            if (status === 'success') {
                return data;
            }
        } catch (err) {
            handleErrorResponse(err);
        }
    }

    // Format and Set Chart Data
    useEffect(() => {
        setIsChartLoading(true);
        // Format Order Data By Month for Bar Chart
        const pastYearOrders = getPastYear(userOrders);
        const orderSalesData = formatDataByMonth(pastYearOrders, 'order');
        orderSalesData.forEach((month) => (month.value = +month.value.toFixed(2)));
        setOrderChartData([
            {
                data: orderSalesData,
                seriesName: t('revenue'),
            },
        ]);
        const pastYearOffers = getPastYear(userOffers);
        const offerData = formatDataByMonth(pastYearOffers, 'offer');
        offerData.forEach((month) => (month.value = +month.value.toFixed(2)));
        setOfferChartData([
            {
                data: offerData,
                seriesName: t('offers'),
            },
        ]);

        setIsChartLoading(false);
    }, [userOrders, userOffers, i18n.language]);

    const columns = [
        {
            field: 'companyName',
            headerName: t('company'),
            flex: 3,
            editable: false,
            renderCell: ({ row }) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <img
                                src={row.logo}
                                alt=""
                                style={{ maxHeight: '100px', maxWidth: '100px' }}
                            />
                        </Grid>
                        <Grid item ml={2}>
                            <Typography>{row.name}</Typography>
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: 'products',
            headerName: t('products'),
            flex: 4,
            editable: false,
            renderCell: ({ row }) => {
                return (
                    <Grid container spacing={2}>
                        {row.product_types?.map((product) => {
                            return (
                                <Grid item key={Math.random()}>
                                    <Chip label={product.type_name} />
                                </Grid>
                            );
                        })}
                    </Grid>
                );
            },
        },
        {
            field: 'start_date',
            headerName: t('start_date'),
            type: 'number',
            flex: 1,
            editable: false,
        },
        {
            field: 'region',
            headerName: t('region'),
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Grid container>
                        <Typography>
                            {row.region.state} {row.region.country}
                        </Typography>
                    </Grid>
                );
            },
        },
        {
            field: 'website',
            headerName: t('website'),
            sortable: false,
            flex: 2,
            renderCell: ({ row }) => {
                return (
                    <Grid container>
                        <Button component={Link} to={row.website} variant="contained">
                            {row.name}
                        </Button>
                    </Grid>
                );
            },
        },
    ];

    const filterCustomerLocations = (arr) => {
        const fullStateNames = arr.map(obj => {
            const stateName = obj.state.toUpperCase();
            return stateAbbreviations[stateName] || obj.state;
        });

        const uniqueStates = [...new Set(fullStateNames)];
        return uniqueStates;
    };

    const [salesData, setSalesData] = useState([]);
    const [offersData, setOffersData] = useState([]);
    const [barChartData, setBarChartData] = useState([]);

    const chartSettings = {
        chartHeight: 500,
        label: '$',
        type: 'Bar Chart',
        minValue: 0,
        maxValue: 50000,
        maxPrecision: 10,
        showCursor: false,
        showDataLabels: false,
        showLegend: false,
    };

    let coordinatesArr = [];

    const clusteredMapData = customerList
        ?.filter((customer) => customer.industry !== null)
        .map((customer) => {
            coordinatesArr.push(customer.location);

            return {
                name: customer.first_name + ' ' + customer.last_name,
                category: customer.industry,
                coordinates: customer.location,
            };
        });

    // Preload Map and Chart Data
    useEffect(() => {
        setIsLoadingMapData(true);
        setBarChartData(salesData);
        const median = findMedian(coordinatesArr);
        setCenter(median);
        const points = calculateFurthestPoints(coordinatesArr);
        setFurthestPoints(points);
        const distance = calculateDistanceInMiles(
            furthestPoints[0]?.lat,
            furthestPoints[0]?.lng,
            furthestPoints[1]?.lat,
            furthestPoints[1]?.lng,
        );

        switch (true) {
            case distance <= 150:
                setZoomLevel(6);
                break;
            case distance > 150 && distance <= 500:
                setZoomLevel(5);
                break;
            case distance > 500 && distance <= 1000:
                setZoomLevel(4);
                break;
            case distance > 1000 && distance <= 3500:
                setZoomLevel(3);
                break;
            default:
                setZoomLevel(2);
        }
        setIsLoadingMapData(false);
    }, [currentUserInfo, center, zoomLevel]);

    const handleConfirm = async (message) => {
        await requestToRecruit(message, id, currentUserInfo.name);
    };

    useEffect(() => {
        const locations = filterCustomerLocations(customerList);
        setCustomerLocations(locations)
    }, [customerList])

    const stateAbbreviations = {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        AS: 'American Samoa',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: "Delaware",
        DC: 'District of Columbia',
        FL: "Florida",
        GA: 'Georgia',
        GU: 'Guam',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        MP: 'Northern Mariana Islands',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        PR: 'Puerto Rico',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        VI: 'Virgin Islands',
        WA: 'Washington',
        WV: 'West Virigina',
        WI: 'Wisconsin',
        WY: 'Wyoming',
        AB: 'Alberta',
        BC: 'British Columbia',
        MB: 'Manitoba',
        NB: 'New Brunswick',
        NL: 'Newfoundland and Labrador',
        NT: 'Northwest Territories',
        NU: 'Nunavut',
        PE: 'Prince Edward Island',
        ON: 'Ontario',
        QC: 'Quebec',
        SK: 'Saskatchewan',
        YK: 'Yukon'
    };

    return (
        <Grid container>
            {/* Header */}
            <ProfileHeaderComponent
                profileInfo={currentUserInfo}
                miscInfo={userMiscInfo}
                setIsDialogOpen={() => setIsRecruitDialogOpen(true)}
                type="sales"
            />
            {/* Main Content */}
            <Grid container item flexDirection="column" xs={12} mt={4}>
                {/* Languages */}
                <Grid item mb={4}>
                    <Typography variant="h5">{t('languages')}</Typography>
                </Grid>
                <Grid
                    item
                    container
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    pb={4}
                >
                    {currentUserInfo?.languages?.map((language) => {
                        return (
                            <>
                                <Grid item>
                                    <img
                                        src={`https://flagcdn.com/w80/${language.flagCode.toLowerCase()}.png`}
                                        alt=""
                                        srcSet={`https://flagcdn.com/w160/${language.flagCode.toLowerCase()}.png 2x`}
                                    />
                                </Grid>
                                <Grid item pl={1} pr={4}>
                                    <Typography>{language.name}</Typography>
                                </Grid>
                            </>
                        );
                    })}
                </Grid>

                <Grid
                    container
                    justifyContent={{ xs: 'center', md: 'space-around' }}
                    flexDirection={{ xs: 'column', md: 'row' }}
                >
                    {/* Areas Served */}
                    <Grid container item md={5.95} flexDirection="column">
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '16px',
                                backgroundColor: theme.palette.profile[mode],
                            }}
                        >
                            <Grid item container ml={2} mt={2} flexDirection='column'>
                                <Grid item>
                                    <Typography variant="h6">{t('territories')}</Typography>
                                </Grid>
                                <Grid item container flexDirection='row' xs={11} justifyContent='center'>
                                    {customerLocations?.map((customer) => {
                                        return (
                                            <Grid item>
                                                <Chip label={customer} key={Math.random()} />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                            <Grid item>
                                {isLoadingMapData ? (
                                    <Grid>
                                        <Skeleton variant="rectangular" height={500} />
                                    </Grid>
                                ) : (
                                    <ClusteredMap
                                        locations={clusteredMapData || []}
                                        initialZoom={zoomLevel}
                                        popup={true}
                                        cluster={false}
                                        projection="globe"
                                        median={center}
                                        disableBorder
                                        isDataLoaded={isLoadingMapData}
                                    />
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                    {/* Analytics */}
                    <Grid
                        container
                        item
                        md={5.95}
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <ProfileAnalyticsChart
                            activeChartData={activeChartData}
                            setActiveChartData={setActiveChartData}
                            isChartLoading={isChartLoading}
                            chartSettings={chartSettings}
                            mobileView={mobileView}
                            data1={orderChartData}
                            data2={offerChartData}
                            data={[]}
                            type="sales"
                        />
                    </Grid>
                </Grid>

                {/* Current Brands */}
                <Grid mt={4}>
                    <Typography variant="h5">{t('brands')}</Typography>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={userBrands}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 3,
                                    },
                                },
                            }}
                            pageSizeOptions={[3]}
                            disableRowSelectionOnClick
                            rowHeight={97}
                            sx={{
                                '.MuiDataGrid-columnHeader': {
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                },
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <RecruitDialog
                isOpen={isRecruitDialogOpen}
                handleClose={() => setIsRecruitDialogOpen(false)}
                handleConfirm={handleConfirm}
            />
        </Grid>
    );
};
