// Provides context about Profuct Families, Product Groups, Product Types including adding, updating etc.

import React, { createContext, useEffect, useState } from 'react';
import useResponseHandling from '../../hooks/useResponseHandler';
import { addProductTypes, getProductTypes } from '../../routes/productsRoutes';
import i18n from '../../i18n';
import useAuth from '../../hooks/useAuth';
import useUser from '../../hooks/useUser';

export const ProductTypeContext = createContext();

export const ProductTypeProvider = ({ children }) => {
  // Product Dialog States
  const { auth } = useAuth();
  const { user } = useUser();

  const [newProductDialogOpen, setNewProductDialogOpen] = useState(false);
  const [productNew, setProductNew] = useState({
    type_name: '',
    product_family: { family_id: '', family_name: '' },
    product_group: { group_id: '', group_name: '' },
  });
  const [productOptions, setProductOptions] = useState([]);
  const [newProductTypeList, setNewProductTypeList] = useState([]);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  // PreLoad Form
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    //Get Product Types Options
    async function handleGetProductTypeOptions() {
      try {
        let productTypesList = await getProductTypes();
        if (productTypesList.status === 'success') {
          setProductOptions(productTypesList.data);
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    async function preLoadformInfo() {
      await handleGetProductTypeOptions();
    }

    preLoadformInfo().then(() => {
      controller.abort(signal);
    });
  }, [auth?.user_info?.account_type, user.location, i18n.language]);

  const addNewProductTypesToDatabase = async () => {
    // Guard Clause
    if (newProductTypeList.length === 0) {
      return;
    }

    try {
      const response = await addProductTypes(newProductTypeList);

      if (response.statusCode === 200) {
        setNewProductTypeList([]);
        return response.data;
      } else {
        handleRegularResponse({
          open: true,
          status: 'warning',
          message: response.message,
        });
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  return (
    <ProductTypeContext.Provider
      value={{
        newProductDialogOpen,
        setNewProductDialogOpen,
        productNew,
        setProductNew,
        newProductTypeList,
        setNewProductTypeList,
        addNewProductTypesToDatabase,
        productOptions,
        setProductOptions,
      }}
    >
      {children}
    </ProductTypeContext.Provider>
  );
};
