import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const RecruitDialog = ({ isOpen, handleConfirm, handleClose }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleConfirmClick = () => {
    handleConfirm(message);
    handleClose();
  };

  const handleCloseClick = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          height: '80%',
          maxHeight: '600px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant="h6">Request to Recruit</Typography>
        <Typography variant="body2">
          Recruit sales reps today! Connect to over 10,000 experienced sales
          professionals ready to represent your brand and grow your business in
          targeted regions. Don't miss out on expanding your reach—recruit now
          and start hearing back from your sales partners within 1-2 days.
        </Typography>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <FormControl fullWidth>
          <TextField
            type="text"
            multiline
            rows={12}
            label="Brief explanation on why they should work for you."
            variant="outlined"
            size="small"
            name="message"
            value={message}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </FormControl>
        <Typography variant="body2">
          By clicking send you agree to honor the terms and services of a brand.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button
          onClick={handleCloseClick}
          color="secondary"
          variant="contained"
        >
          {t('buttons.close')}
        </Button>
        <Button
          onClick={handleConfirmClick}
          color="primary"
          type="submit"
          disabled={!message.length}
          variant="contained"
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecruitDialog;
