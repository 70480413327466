import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../../../formik/FormikFieldWrapper';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Skeleton,
  TextField,
  useTheme,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import useAuth from '../../../../hooks/useAuth';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../../helpers/getNestedValuesIn';

const AccountTypeField = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  fieldID = 'account_type', // ID of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'AccountTypeField', // KeyPrefix which holds label and placeholder
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  // Auth Context
  const { isAdmin } = useAuth();

  const theme = useTheme();

  // Context
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <FormControl disabled={disabled}>
        <TextField
          select
          required={required}
          disabled={disabled}
          id={fieldID}
          name={fieldID}
          label={t('label')}
          placeholder={t('placeholder')}
          aria-invalid={errors?.fieldID ? 'true' : 'false'}
          aria-describedby="uidnote"
          variant="outlined"
          type="text"
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
          }}
          onBlur={handleBlur}
          inputProps={{
            IconComponent: disabled ? () => null : ArrowDropDownIcon,
          }}
          value={fieldValue}
          error={fieldError && fieldTouched ? true : false}
        >
          <MenuItem disabled value="">
            <em>{t('options.select')}</em>
          </MenuItem>
          <MenuItem key={1} value="manufacturer">
            {t('options.manufacturer')}
          </MenuItem>
          <MenuItem key={2} value="salesrep">
            {t('options.salesrep')}
          </MenuItem>
          <MenuItem key={3} value="influencer">
            {t('options.influencer')}
          </MenuItem>
          {isAdmin && (
            <MenuItem key={5} value="intutec">
              {t('options.intutec')}
            </MenuItem>
          )}
        </TextField>
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errors[fieldID] && touched[fieldID] && errors[fieldID]}
        </FormHelperText>
      </FormControl>
    </FormikFieldWrapper>
  );
};

export default AccountTypeField;
