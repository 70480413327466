import { UserPublicProfileContent } from "../../context/users/UserPublicProfileContent";
import IntuPage from "../../components/layout/IntuPage";
import CustomerMapProvider from "../../context/providers/maps/CustomerMapProvider";
import { UserPublicProfileProvider } from "../../context/providers/profile/UserPublicProfileProvider";

function UserPublicProfile() {
    return (
        <UserPublicProfileProvider>
            <CustomerMapProvider>
                <IntuPage>
                    <UserPublicProfileContent />
                </IntuPage>
            </CustomerMapProvider>
        </UserPublicProfileProvider>
    )
}

export default UserPublicProfile;