import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import { UserPostsList } from '../../components/blog/lists/UserPostsList';
import IntuPage from '../../components/layout/IntuPage';
import DashboardLayoutComponent from '../../components/layout/DashboardLayout';

function UserPosts() {
  const { t } = useTranslation();
  return (
    <DashboardLayoutComponent
      dashboardTitle={t('form.post.user.title')}
      dashboardTitleText={t('form.post.user.text')}
    >
      <UserPostsList />
    </DashboardLayoutComponent>
  );
}

export default UserPosts;
