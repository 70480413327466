export const pageBuilderTranslations = ({ t }) => {
  return {
    'Edit blocks': t('menu.edit'),
    'Add blocks': t('menu.add'),
    'Move blocks': t('menu.move'),
    Close: t('menu.close'),
    'Resize blocks': t('menu.resize'),
    'Preview blocks': t('menu.preview'),
    'Add blocks to page': t('page.add'),
    'Preview page': t('page.preview'),
    'Search for blocks': t('fields.search.placeholder'),
    'Click to add or drag and drop it somewhere on your page!':
      t('popover.drag'),
    'Drag me!': t('popover,gragme'),
    'Content is visible to': t('popover.visible'),
    Text: t('plugin.text.title'),
    Image: t('plugin.image.title'),
    'Add an Image to your page.': t('plugin.image.description'),
    'An advanced rich text area.': t('plugin.text.description'),
    'AI Generated Content': t('plugin.content_ai.title'),
    'Use a little magic to create or tweak your content': t(
      'plugin.content_ai.description',
    ),
    Divider: t('plugin.divider.title'),
    'Implement a Divider': t('plugin.divider.description'),
    Spacer: t('plugin.spacer.title'),
    'Resizeable, horizontal and vertical empty space.': t(
      'plugin.spacer.description',
    ),
    'Video Player': t('plugin.videoPlayer.title'),
    'Playing a variety of URLs, including file paths, YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, DailyMotion and Kalturan':
      t('plugin.videoPlayer.description'),
    'Pie Chart': t('plugin.pieChart.title'),
    'Create a Pie Chart': t('plugin.pieChart.description'),
    'XY Chart': t('plugin.xyChart.title'),
    'Create a XY Column or Line Chart': t('plugin.xyChart.description'),
    'Create a Tex Section': t('plugin.text.title'),
    'An Advanced rich text section.': t('plugin.text.description'),
    'No blocks found': t('search.no_result'),
    'Reset to English ⚠️': t('localization.reset_english'),
    'Reset to German ⚠️': t('localization.reset_german'),
    'Reset to Italian ⚠️': t('localization.reset_italian'),
    'English (default)': t('localization.default_english'),
    'German (default)': t('localization.default_german'),
    'Italian (default)': t('localization.default_italian'),
  };
};
