import { useContext, useState } from 'react';
import {
  Autocomplete,
  ListSubheader,
  Skeleton,
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import FormikFieldWrapper from '../formik/FormikFieldWrapper.jsx';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import { getNestedValue } from '../../helpers/getNestedValuesIn.js';
import { IndustriesContext } from '../../context/providers/IndustriesContextProvider.jsx';

const IndustriesFieldContent = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'industries', // Define the Fields Name and ID
  autocompleteID = 'industries', // Define the Fields Name and ID
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'IndustriesField', // KeyPrefix which holds label and placeholder
  transition = true, // Transition true = fade in, false = fade out
  disabled = false, // Disable the Field
  required = false, // Field Required
  variant = 'outlined', // Set the Variant type standard or outlined
}) => {
  const { values, setFieldValue, handleBlur, touched, errors } =
    useFormikContext();

  const { isLoading } = useProcessingHandler();

  const { industryOptions } = useContext(IndustriesContext);

  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <>
      <FormikFieldWrapper
        fieldTitle={fieldTitle}
        fieldDescription={fieldDescription}
        transition={transition}
      >
        <Autocomplete
          multiple
          freeSolo
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          filterSelectedOptions
          disableCloseOnSelect
          value={getNestedValue(values, autocompleteID)}
          required={required}
          disabled={disabled || isLoading?.status ? true : false}
          id={fieldID}
          name={fieldID}
          options={industryOptions?.sort(
            (a, b) => -b.industry_name.localeCompare(a.industry_name),
          )}
          getOptionLabel={(option) => option.industry_name}
          isOptionEqualToValue={(option, value) =>
            option.industry_id === value.industry_id
          }
          className="form-select-field"
          onChange={(e, options) => {
            setFieldValue(fieldID, options);
          }}
          onBlur={handleBlur}
          sx={{ paddingBottom: '50px' }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              variant={variant}
              label={!disabled ? t('label') : null}
              placeholder={!disabled ? t('placeholder') : null}
              error={errors[fieldID] && touched[fieldID] ? true : false}
              helperText={
                errors[fieldID] && touched[fieldID] ? errors[fieldID] : null
              }
            />
          )}
        />
      </FormikFieldWrapper>
    </>
  );
};

const IndustriesField = (props) => {
  return <IndustriesFieldContent {...props} />;
};

export default IndustriesField;
