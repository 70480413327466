import React, { useContext } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Skeleton, TextField } from '@mui/material';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';

const ImageSizeField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'width', // Determines the Name and IDD of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'ImageSizeField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined',
  onchange = () => {},
  ...props
}) => {
  // Translation
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  //   Formik Context
  const { values, touched, errors, handleBlur, setFieldValue } =
    useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        {...props}
        disabled={disabled}
        required={required}
        id={fieldID}
        name={fieldID}
        autoComplete="off"
        className="form-select-field"
        aria-invalid={errors[fieldID] ? 'true' : 'false'}
        aria-describedby="uidnote"
        variant={variant}
        type="number"
        label={transFields('label')}
        placeholder={transFields('placeholder')}
        inputProps={{
          autoComplete: 'off',
        }}
        onBlur={(e) => handleBlur(e)}
        value={fieldValue}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      />
    </FormikFieldWrapper>
  );
};

export default ImageSizeField;
