import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import logoLight from '../../assets/logos/logo-dark-full.png';
import logoDark from '../../assets/logos/logo-light-full.png';
import useIntuTheme from '../../hooks/useIntuTheme';
import { useTranslation } from 'react-i18next';
import { RiveAnimationComponent } from '../../components/animations/RiveAnimationComponent';
import { useContext, useEffect } from 'react';
import IntuPage from '../../components/layout/IntuPage';
import { LayoutBodyContext } from '../../context/providers/LayoutContextProvider';

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.unauthorized',
  });
  const { t: transButtons } = useTranslation('buttons');

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ paddingBottom: '5rem' }}
    >
      <Grid item>
        <RiveAnimationComponent
          src={
            'https://in2tec.nyc3.cdn.digitaloceanspaces.com/animations/401_page.riv'
          }
          stateMachines="State Machine 1"
        />
      </Grid>

      <Grid item>
        <Typography textAlign="center" variant="h1">
          {t('title')}
        </Typography>
      </Grid>
      <Grid item sx={{ marginBottom: '1rem' }}>
        <Typography textAlign="center" variant="body1">
          {t('description')}
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" type="submit" onClick={goBack}>
          {transButtons('back')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UnauthorizedPage;
