import React, { useContext, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

// AM5
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5Themes_IntuTheme from './theme';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark';

// MUI
import { Typography, Grid } from '@mui/material';

// theme mode
import IntuThemeContext from '../context/providers/IntuThemeProvider';

const BarChartContext = ({
  chartId,
  description: chartDescription = '',
  title: chartTitle = '',
  descriptionColor: chartDescriptionColor = '',
  settings,
  data,
  mobileView = true,
  hideYaxis,
  hideXaxis,
  thinColumns,
}) => {
  // Set state
  const [isChartRotated, setIsChartRotated] = useState(false);

  // Get Theme mode Context
  const { mode } = useContext(IntuThemeContext);

  useEffect(() => {
    // Guard Clause
    if (!chartId) {
      return;
    }

    //SetUp Chart
    let root = am5.Root.new(chartId);

    // Set themes
    if (mode === 'dark') {
      root.setThemes([
        am5themes_Animated.new(root),
        am5themes_Responsive.new(root),
        am5Themes_IntuTheme.new(root),
        am5themes_Dark.new(root),
      ]);
    } else {
      root.setThemes([
        am5themes_Animated.new(root),
        am5themes_Responsive.new(root),
        am5Themes_IntuTheme.new(root),
      ]);
    }

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        panX: false,
        layout: root.verticalLayout,
      }),
    );

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: settings?.minValue || 0,
        max: settings?.maxValue || 100,
        maxPrecision: settings?.maxPrecision || 20,
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 100,
        }),
        rotation: setIsChartRotated ? 0 : 90,
        visible: hideYaxis ? false : true,
      }),
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: 'category',
        visible: hideXaxis ? false : true,
      }),
    );
    // xAxis.data.setAll(data);
    const allCategories = [
      ...new Set(
        data.flatMap((series) => series.data.map((item) => item.category)),
      ),
    ];
    xAxis.data.setAll(allCategories.map((category) => ({ category })));

    // X-Axis label off-center
    let xRenderer = xAxis.get('renderer');
    xRenderer.labels.template.setAll({
      rotation: settings?.seriesLabelRotation || -30,
      oversizedBehavior: 'fit',
      fontSize: settings?.seriesLabelSize || '1rem',
      centerY: am5.p50,
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, data, label) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          categoryXField: 'category',
        }),
      );

      series.columns.template.setAll({
        tooltipText: '{name} - {category} - {value} ' + label,
        width: thinColumns ? 10 : am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
        templateField: 'columnSettings',
      });

      // Show Data Labels
      let showLabels = settings.showDataLabels !== false;
      if (showLabels) {
        if (mobileView === false) {
          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: settings?.dataLabelPositionX || 0.5,
              locationY: settings?.dataLabelPositionY || 0.5,
              sprite: am5.Label.new(root, {
                text: '{valueY} ' + label,
                fill:
                  settings?.dataLabelColor ||
                  root.interfaceColors.get('alternativeText'),
                centerX: am5.p50,
                centerY: am5.p50,
                populateText: true,
              }),
            });
          });
        }
      }

      series.data.setAll(data);
      series.appear(1000, 100);
    }

    data.map((series) => {
      makeSeries(series.seriesName, series.data, settings.label);
    });
    // Add legend
    let showLegend = settings?.showLegend ? settings?.showLegend : false;
    if (showLegend === true) {
      let legend = chart.children.push(
        am5.Legend.new(root, {
          x: am5.percent(50),
          centerX: am5.percent(50),
          // rotation: setIsChartRotated? 0 : 90
        }),
      );
      legend.data.setAll(chart.series.values);
    }

    // Add cursor
    if (settings?.showCursor === true) {
      chart.set('cursor', am5xy.XYCursor.new(root, {}));
    }

    // Check screen size and set rotation
    const checkScreenWidth = () => {
      setIsChartRotated(window.innerWidth <= 830);
    };

    window.addEventListener('resize', checkScreenWidth);

    checkScreenWidth();

    chart.appear(1000, 100);

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
      root.dispose();
    };
  }, [isChartRotated, mode, data]);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          color={chartDescriptionColor ? chartDescriptionColor : null}
        >
          {ReactHtmlParser(chartTitle)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          color={chartDescriptionColor ? chartDescriptionColor : null}
        >
          {ReactHtmlParser(chartDescription)}
        </Typography>
      </Grid>
      <Grid
        item
        id={chartId}
        xs={12}
        style={{
          width: '100%',
          height: mobileView ? 350 : settings?.chartHeight || 500,
        }}
      />
    </>
  );
};

export { BarChartContext };
