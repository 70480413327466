import IntuPage from '../../components/layout/IntuPage';
import CustomerMapProvider from '../../context/providers/maps/CustomerMapProvider';
import { CompanyPublicProfileContent } from '../../context/company/profile/CompanyPublicProfileContent';
import { CompanyPublicProfileProvider } from '../../context/providers/CompanyPublicProfileProvider';

function CompanyPublicProfile() {
    return (
        <CompanyPublicProfileProvider>
            <CustomerMapProvider>
                <IntuPage>
                    <CompanyPublicProfileContent />
                </IntuPage>
            </CustomerMapProvider>
        </CompanyPublicProfileProvider>
    );
}

export default CompanyPublicProfile;
