import { memo } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';

const Find = ({ filters, list, columns, onFilterChange }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  if (!list || !list.length) return null;
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr 1fr"
        gap={2}
        bgcolor="tertiary.backgroundColor"
        p={4}
        borderRadius="12px"
        boxShadow={6}
      >
        {filters.map((filter) => {
          if (filter.type === 'autocomplete') {
            return (
              <Autocomplete
                key={filter.id}
                multiple
                value={filter.value}
                label={filter.label}
                options={filter.options}
                onChange={(e, newValue, reason, details) =>
                  onFilterChange(newValue, reason, details, filter.id)
                }
                renderInput={(params) => (
                  <TextField {...params} label={filter.label} />
                )}
              />
            );
          } else {
            return null;
          }
        })}
      </Box>
      <Box mt={4}>
        <DataGrid
          disableRowSelectionOnClick
          rows={list}
          columns={columns}
          columnVisibilityModel={{
            applications: !isMobileView,
            product_types: !isMobileView,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            sorting: {
              sortModel: [{}],
            },
          }}
          pageSizeOptions={[5, 10, 100]}
          checkboxSelection={false}
          disableColumnMenu
          slots={{
            Row: MemoizedRow,
            ColumnHeaders: MemoizedColumnHeaders,
          }}
          sx={{
            borderRadius: '12px',
            boxShadow: 6,
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          }}
        />
      </Box>
    </>
  );
};

export default Find;
