import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import CookieConsent from 'react-cookie-consent';
import { Trans, useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import useUser from '../../hooks/useUser';

const CookieConsentComponent = () => {
  const { cookieSettings, setCookieSettings } = useUser();
  const { t } = useTranslation('translation', { keyPrefix: 'cookieConsent' });

  function handleChange(e, values) {
    setCookieSettings({
      ...cookieSettings,
      [e.target.name]: values,
    });
  }

  return (
    <Grid>
      <Typography variant="body2">
        <Trans
          t={t}
          i18nKey="disclaimer"
          components={{
            anchorLink: <a href="/privacy/#5"></a>,
            spanText: <span style={{ color: 'var(--intu-darkGreen)' }}></span>,
          }}
        >
          We are using Cookies for an improved user experience such as staying
          signed in, or for showing you sales reps in your area. For detailed
          information about our use of cookies click{' '}
          <anchorLink>
            <spanText>here</spanText>
          </anchorLink>
        </Trans>
      </Typography>
      <FormControlLabel
        control={
          <Switch
            name="auth"
            checked={cookieSettings.auth}
            onChange={(e, values) => handleChange(e, values)}
          />
        }
        label={
          <Typography variant="body2">
            {i18n.format(t('auth'), 'capitalize')}
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Switch
            name="exp"
            checked={cookieSettings.exp}
            onChange={(e, values) => handleChange(e, values)}
          />
        }
        label={
          <Typography variant="body2">
            {i18n.format(t('exp'), 'capitalize')}
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Switch
            name="trck"
            checked={cookieSettings.trck}
            onChange={(e, values) => handleChange(e, values)}
          />
        }
        label={
          <Typography variant="body2">
            {i18n.format(t('marketing'), 'capitalize')}
          </Typography>
        }
      />
    </Grid>
  );
};

export const CookieConsentContainer = () => {
  const { cookieSettings } = useUser();

  return (
    <CookieConsent
      location="bottom"
      buttonText="Save"
      cookieName="intu_cookie_settings"
      cookieValue={`auth=${cookieSettings.auth};exp=${cookieSettings.exp};trck=${cookieSettings.trck}`}
      overlay
      style={{
        background: 'var(--cookie-consent-background)',
        color: 'var(--cookie-context-color)',
      }}
      buttonStyle={{
        backgroundColor: 'var(--intu-darkGreen)',
        color: 'var(--intu-darkGrey)',
        fontSize: '13px',
      }}
      expires={150}
      extraCookieOptions={{ domain: '.intutec.io' }}
    >
      <CookieConsentComponent />
    </CookieConsent>
  );
};
