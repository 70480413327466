import { useContext, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import UserContext from '../../context/providers/UserInfoProvider';
import ChatSenders from '../../components/chat/ChatSenders';
import ChatRoom from '../../components/chat/ChatRoom';

const Chat = () => {
  const { conversations, sendMessage, markMessagesRead } =
    useContext(UserContext);
  const [selectedSender, setSelectedSender] = useState(null);
  const [selectedConversations, setSelectedConversations] = useState({});
  const [dropdownValue, setDropdownValue] = useState('');

  const senders = useMemo(() => {
    if (!conversations || !conversations.length) return new Map();

    const sendersMap = new Map();
    conversations.forEach((conversation) => {
      const { name, profile_picture } = conversation.sender;
      if (sendersMap.has(name)) {
        const cur = sendersMap.get(name).unread_count;
        sendersMap.set(name, {
          profile_picture,
          unread_count: cur + conversation.unread_count,
          most_recent_message: conversation.most_recent_message || '',
        });
      } else {
        sendersMap.set(name, {
          profile_picture,
          unread_count: conversation.unread_count,
          most_recent_message: conversation.most_recent_message || '',
        });
      }
    });
    return sendersMap;
  }, [conversations]);

  const conversationOptions = useMemo(() => {
    if (!conversations || !conversations.length) return [];
    return conversations.filter(
      (conversation) => conversation.sender.name === selectedSender,
    );
  }, [selectedSender, conversations]);

  const handleConversationChange = (e) => {
    setDropdownValue(e.target.value);
    const found = conversations.find(
      (conversation) => conversation.chat_title === e.target.value,
    );

    setSelectedConversations((prevState) => {
      return {
        ...prevState,
        [found.sender.name]: found.chat_id,
      };
    });
    if (found.unread_count > 0) {
      markMessagesRead(found.chat_id);
    }
  };

  const showingConversation = useMemo(() => {
    if (!conversations || !selectedConversations) return null;

    const conversationId = selectedConversations[selectedSender];
    return conversations.find(
      (conversation) => conversation.chat_id === conversationId,
    );
  }, [selectedConversations, conversations, selectedSender]);

  const handleSenderChange = (newSender) => {
    setSelectedSender(newSender);
    const conversation = conversations.find(
      (conversation) => conversation.sender.name === newSender,
    );
    if (selectedConversations[newSender] && conversation) {
      setDropdownValue(conversation.chat_title);
    }
  };

  const handleSend = async (message, conversation) => {
    sendMessage(message, conversation.chat_id);
  };

  return (
    <Box display="flex" p={2} gap={4} height="90dvh">
      <ChatSenders
        senders={senders}
        handleSenderChange={handleSenderChange}
        selectedSender={selectedSender}
      />
      <ChatRoom
        senders={senders}
        selectedSender={selectedSender}
        conversationOptions={conversationOptions}
        handleConversationChange={handleConversationChange}
        dropdownValue={dropdownValue}
        showingConversation={showingConversation}
        handleSend={handleSend}
      />
    </Box>
  );
};

export default Chat;
