import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Create a function to handle the theme creation logic
export const createCustomUserTheme = (baseTheme, userTheme) => {
  return responsiveFontSizes(
    createTheme({
      ...baseTheme,
      palette: {
        ...baseTheme.palette,
        primary: {
          ...baseTheme.palette.primary,
          main: userTheme.primary || baseTheme.palette.primary.main,
        },
        secondary: {
          ...baseTheme.palette.secondary,
          main: userTheme.secondary || baseTheme.palette.secondary.main,
        },
        tertiary: {
          ...baseTheme.palette.tertiary,
          main: userTheme.tertiary || baseTheme.palette.tertiary.main,
        },
      },
      typography: {
        ...baseTheme.typography,
        h1: {
          ...baseTheme.typography.h1,
          color: userTheme.primary || baseTheme.typography.h1.color,
        },
        h2: {
          ...baseTheme.typography.h2,
          color: userTheme.primary || baseTheme.typography.h2.color,
        },

        dialogBoxTitle: {
          ...baseTheme.typography.dialogBoxTitle,
          color: userTheme.primary || baseTheme.typography.dialogBoxTitle.color,
        },
      },
      components: {
        ...baseTheme.components,
        MuiAppBar: {
          styleOverrides: {
            ...baseTheme.components.MuiAppBar.styleOverrides,
            root: {
              ...baseTheme.components.MuiAppBar.styleOverrides.root,
              backgroundColor: userTheme.primary
                ? userTheme.primary
                : baseTheme.components.MuiAppBar.styleOverrides.root
                    .backgroundColor,
              boxShadow: userTheme.primary
                ? `0px 1px 12px ${userTheme.primary}`
                : baseTheme.components.MuiAppBar.styleOverrides.root.boxShadow,
            },
          },
          menuIcon: userTheme.primary
            ? userTheme.primary
            : baseTheme.components.MuiAppBar.menuIcon,
        },
        MuiAutocompleteDropDown: {
          ...baseTheme.components.MuiAutocompleteDropDown,
          header: {
            ...baseTheme.components.MuiAutocompleteDropDown.header,
            backgroundColor: userTheme.primary
              ? userTheme.primary
              : baseTheme.components.MuiAutocompleteDropDown.header
                  .backgroundColor,
            color: userTheme?.contrastColor
              ? userTheme.contrastColor
              : baseTheme.components.MuiAutocompleteDropDown.header.color,
          },
          icon: {
            ...baseTheme.components.MuiAutocompleteDropDown.icon,
            color: userTheme?.contrastColor
              ? userTheme.contrastColor
              : baseTheme.components.MuiAutocompleteDropDown.icon.color,
          },
        },
        MuiChip: {
          ...baseTheme.components.MuiChip,
          styleOverrides: {
            ...baseTheme.components.MuiChip.styleOverrides,
            root: {
              ...baseTheme.components.MuiChip.styleOverrides.root,
              color: userTheme?.contrastColor
                ? userTheme.contrastColor
                : baseTheme.components.MuiChip.styleOverrides.root.color,
              backgroundColor: userTheme?.primary
                ? userTheme.primary
                : baseTheme.components.MuiChip.styleOverrides.root
                    .backgroundColor,
            },
            deleteIcon: {
              color: 'var(--color-tags-primary-dark-mode)',
            },
          },
        },
      },
    }),
  );
};
