import { useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useUser from '../../../../hooks/useUser.js';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsTracker.jsx';
import { useNavigate } from 'react-router-dom';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate.js';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';
import { ProductTypeContext } from '../../../../context/providers/ProductTypeContextProvider.jsx';
import { ApplicationsContext } from '../../../../context/providers/ApplicationsContextProvider.jsx';
import { axiosPrivate } from '../../../../hooks/axios/axios.js';
import { BusinessDetailsForm } from './BusinessDetailsForm.jsx';
import { TermsOfServiceForm } from './TermsOfServiceForm.jsx';
import PersonalDetailsForm from './PersonalDetailsForm.jsx';
import ApplicationsAndProductsForm from './ApplicationsAndProductsForm.jsx';
import { BrandsContext } from '../../../../context/providers/BrandsProvider.jsx';
import {
  createInitialValuesIndustriesAndApplicationsForm,
  createInitialValuesPersonalDetailsform,
  createInitialValuesTermsOfServiceForm,
} from './InitialValues.js';
import FormikStepper, { FormikStep } from '../../../formik/FormikStepper.jsx';
import {
  createApplicationsProductsValidationSchema,
  createPersonalDetailsValidationSchema,
  createTermsOfServiceValidationSchema,
} from './ValidationSchema.js';
import { createBusinessDetailsFormValidationSchema } from './ValidationSchema.js';
import { createInitialValuesBusinessDetailsForm } from './InitialValues.js';
import { CompanyProfileContext } from '../../../../context/providers/CompanyProfileProvider.jsx';
import AuthContext from '../../../../context/providers/AuthProvider.jsx';
import { UserProfileContext } from '../../../../context/providers/UserProfileProvider.jsx';

function SignUpCompleteForm() {
  const axios = useAxiosPrivate();

  const { newApplicationList, addNewApplicationsToDatabase } =
    useContext(ApplicationsContext);

  const { newProductTypeList, addNewProductTypesToDatabase } =
    useContext(ProductTypeContext);

  const { newBrandsList, addNewBrandsToDatabase } = useContext(BrandsContext);
  const { newCompany } = useContext(CompanyProfileContext);
  const { gaEventTracker } = useAnalyticsEventTracker();
  const { t } = useTranslation();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const { auth, setAuth } = useContext(AuthContext);
  const { userProfile } = useContext(UserProfileContext);
  const { user } = useUser();
  const navigate = useNavigate();
  const errRef = useRef();

  // Business Details Stepper Initialization and Validation Schema
  const initialFormValuesBusinessDetailsForm =
    createInitialValuesBusinessDetailsForm({
      user,
      auth,
    });

  const validationSchemaBusinessDetailsForm =
    createBusinessDetailsFormValidationSchema(t);

  // Applications & Products Stepper Initialization and Validation Schema
  const initialFormValuesApplicationProductsForm =
    createInitialValuesIndustriesAndApplicationsForm({
      user,
      auth,
    });

  const validationSchemaApplicationProductsForm =
    createApplicationsProductsValidationSchema(t);

  // Applications & Products Stepper Initialization and Validation Schema
  const initialFormValuesPersonalDetailsForm =
    createInitialValuesPersonalDetailsform({
      user,
      auth,
      userProfile,
    });

  const validationSchemaPersonalDetailsForm =
    createPersonalDetailsValidationSchema(t);

  // TOS Stepper Initialization and Validation Schema
  const initialFormValuesTermsOfServiceForm =
    createInitialValuesTermsOfServiceForm({
      user,
      auth,
    });

  const validationSchemaTermsOfServiceForm =
    createTermsOfServiceValidationSchema(t);

  //handle form submission process
  async function submitForm(values, helpers) {
    let url;

    let payload;
    helpers.setSubmitting(true);
    helpers.validateForm(true);
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('context.account.SignUpComplete.status.loading'),
    });

    try {
      // Add New Product Types to Database
      let productTypesByID = values?.product_types || [];
      if (productTypesByID.length > 0) {
        productTypesByID = values?.product_types
          .filter((productType) => productType.type_id)
          .map((productType) => productType.type_id);
      }

      if (newProductTypeList.length > 0) {
        const newProductData = await addNewProductTypesToDatabase();
        if (newProductData?.length > 0) {
          const newProductTypesById = newProductData.map(
            (product) => product.type_id,
          );

          productTypesByID = [...productTypesByID, ...newProductTypesById];
        }
      }

      // Restructure Industries by id's
      let industriesByID = values?.industries || [];
      if (industriesByID.length > 0) {
        industriesByID = values.industries
          .filter((industry) => industry.industry_id)
          .map((industry) => industry.industry_id);
      }

      // Restructure applications by id's
      let applicationsByID = values?.applications || [];
      if (applicationsByID.length > 0) {
        applicationsByID = values.applications
          .filter((application) => application.application_id)
          .map((application) => application.application_id);
      }

      // Add New Applications to the Database
      if (newApplicationList.length > 0) {
        const newApplicationData = await addNewApplicationsToDatabase();
        if (newApplicationData?.length > 0) {
          const newApplicationsById = newApplicationData.map(
            (application) => application.application_id,
          );

          applicationsByID = [...applicationsByID, ...newApplicationsById];
        }
      }

      // Add Company if it doesnt exist
      if (values.business_type === 'company' && newCompany === true) {
        url = '/api/companies/company/create';
        payload = {
          name: values.company.name,
          address: values.company.address,
          product_types: productTypesByID,
          applications: applicationsByID,
          industries: industriesByID,
          phone: values.company.phone,
          email: values.company.email,
          website: values.company.website,
          type: values.account_type,
          ssa_accepted: values.ssa_accepted,
          tos_accepted: values.tos_accepted,
          ppa_accepted: values.ppa_accepted,
        };

        // Handle Subsidiaries
        if (values?.company?.isSubsidiary === true) {
          payload = {
            ...payload,
            isSubsidiary: values.company.isSubsidiary,
            parent_id: values.parent_id,
          };
        }

        try {
          const response = await axios.post(url, JSON.stringify(payload), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          });

          const { data, status } = response;
          if (status === 200) {
            // update company details

            values.company = {
              ...values.company,
              _id: data.data._id,
              name: values.company.name,
            };
            gaEventTracker({
              category: 'Account Creation',
              action: 'Event',
              label: 'New Company added',
            });
          } else {
            return handleRegularResponse({
              open: true,
              status: 'warning',
              message: data.message,
            });
          }
        } catch (error) {
          handleErrorResponse(error);
          console.error('error', error);
        }
      }

      // Add New ABrands to the Database
      const existingBrandsByID = values.company.brands
        .filter((brand) => brand._id)
        .map((brand) => brand._id);
      if (newBrandsList.length > 0) {
        const newBrandsData = await addNewBrandsToDatabase(values.company);

        // Update Company Account
        const newBrandIDs = newBrandsData.map((brand) => brand._id);
        const url = '/api/companies/company/update';

        const payload = {
          _id: values?.company?._id,
          brands: [...existingBrandsByID, ...newBrandIDs],
          notification: true,
        };

        try {
          await axios.put(url, JSON.stringify(payload), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          });
        } catch (error) {
          handleErrorResponse(error);
          console.error('error', error);
        }
      }

      // Get Cookie Settings
      const authSettings = user?.cookieSettings?.auth;

      // Update User Account
      url = '/api/users/account/create/complete';
      payload = {
        account_type: values.account_type,
        address: values.individual.address,
        phone: values.individual.phone,
        mobile: values.mobile,
        profile_link: values.individual.profile_link,
        linkedin: values.individual.linkedin,
        product_types: productTypesByID,
        applications: applicationsByID,
        industries: industriesByID,
        cookies: authSettings,
        business_type: values.business_type,
        ssa_accepted: values.ssa_accepted,
        tos_accepted: values.tos_accepted,
        account_notifications: values.account_notifications,
        ppa_accepted: values.ppa_accepted,
        mobile_verified: values.mobile_verified,
      };

      if (values?.company._id) {
        payload = {
          ...payload,
          company: values?.company?._id,
        };
      }

      const response = await axiosPrivate.put(url, JSON.stringify(payload));

      const { data, status, message } = response;
      if (status === 200) {
        gaEventTracker({
          category: 'Account Creation',
          action: 'Event',
          label: 'User Account Completed',
        });

        const { user_info, auth_info, navigate_to } = data;
        let image = '';
        // Set Profile Picture
        if (user_info.profile_picture) {
          image = `data:${user_info.profile_picture.mimetype};base64,${user_info.profile_picture.buffer.toString('base64')}`;
        }

        // Update Auth Key and User Info
        setAuth({
          auth_info,
          user_info: {
            ...user_info,
            profile_picture_view_url: image,
          },
        });

        // Create Stripe Account
        url = '/api/stripe/connect/account/create';
        payload = {
          account_type: values.business_type,
          ssa_accepted: values.ssa_accepted,
        };

        try {
          await axios.post(url, JSON.stringify(payload), {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth_info}`,
            },
            withCredentials: true,
          });
        } catch (error) {
          handleErrorResponse(error);
          console.error('error', error);
          return errRef.current?.focus();
        }

        // Set User Auth State
        navigate(navigate_to, {
          replace: true,
          state: { from: 'SignUpCompleteForm' },
        });
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
      return errRef.current?.focus();
    } finally {
      helpers.setSubmitting(false);
      setIsLoading({ status: false, type: '', text: '' });
    }
  }

  return (
    <Grid width="100%" item xs={12}>
      <FormikStepper
        onSubmit={async (values, helpers) => {
          await submitForm(values, helpers);
        }}
      >
        <FormikStep
          key="business_details"
          label={t('context.account.SignUpComplete.steps.business')}
          initialValues={initialFormValuesBusinessDetailsForm}
          validationSchema={validationSchemaBusinessDetailsForm}
        >
          <BusinessDetailsForm />
        </FormikStep>
        <FormikStep
          key="product_applications"
          label={t('context.account.SignUpComplete.steps.applications')}
          initialValues={initialFormValuesApplicationProductsForm}
          validationSchema={validationSchemaApplicationProductsForm}
        >
          <ApplicationsAndProductsForm />
        </FormikStep>
        <FormikStep
          key="personal_details"
          label={t('context.account.SignUpComplete.steps.personal')}
          initialValues={initialFormValuesPersonalDetailsForm}
          validationSchema={validationSchemaPersonalDetailsForm}
        >
          <PersonalDetailsForm />
        </FormikStep>
        <FormikStep
          key="tos"
          includeNav={false}
          label={t('context.account.SignUpComplete.steps.tos')}
          initialValues={initialFormValuesTermsOfServiceForm}
          validationSchema={validationSchemaTermsOfServiceForm}
        >
          <TermsOfServiceForm />
        </FormikStep>
      </FormikStepper>
    </Grid>
  );
}

export { SignUpCompleteForm };
