// TODO:  ELIMINATE IT

import React, { createContext, useState } from 'react';

export const TermsOfServiceContext = createContext({});

export const TermsOfServiceProvider = ({ children }) => {
  // States
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  return (
    <TermsOfServiceContext.Provider
      value={{
        dialogOpen,
        setDialogOpen,
        dialogType,
        setDialogType,
        dialogTitle,
        setDialogTitle,
      }}
    >
      {children}
    </TermsOfServiceContext.Provider>
  );
};
