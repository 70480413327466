import { Grid, Typography } from '@mui/material';

// The rich text area plugin (Slate)
import '@in2tec/plugins-slate/lib/index.css';

// Video Plugin
import videoPlugin from './plugins/video/videoPlugin';

// Image Plugin
import imagePlugin from './plugins/image/imagePlugin';

// Divider & Spacer PLugin
import divider from './plugins/divider/dividerPlugin';
import spacer from '@in2tec/plugins-spacer';

// Slate Plugin
import { customSlate } from './plugins/color/pageColorPicker';

// Chart PLugins
import pieChartPlugin from './plugins/charts/pieChartPlugin';
import xyChartPlugin from './plugins/charts/xyChartPlugin';

// Content Ceation Plugin
import contentCreationPlugin from './plugins/content/contentCreationPlugin';

// Page Editor
import Editor from '@in2tec/page-editor';
import '@in2tec/page-editor/lib/index.css';
import i18next from 'i18next';

import languagesList from '../../data/LanguagesData.json';
import { useCallback, useContext } from 'react';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';
import { useTranslation } from 'react-i18next';
import { pageBuilderTranslations } from './localization/translations';

const IntuPageBuilderReadOnly = ({
  values, // You must provide values as a prop (For Editing use IntuPageBuilderEdit)
}) => {
  const { mode } = useContext(IntuThemeContext);

  // Cell Plugins
  const cellPlugins = [
    customSlate,
    divider,
    spacer,
    pieChartPlugin,
    xyChartPlugin,
    imagePlugin,
    videoPlugin,
    contentCreationPlugin,
  ];

  const supportedLanguages = languagesList
    .filter((language) => language.supported)
    .map((lang) => ({
      lang: lang.code,
      label: lang.name,
    }));

  const { t: transPageEditor } = useTranslation('pageEditor');

  const translations = pageBuilderTranslations({ t: transPageEditor });

  const uiTranslator = useCallback((label) => {
    if (translations[label] !== undefined) {
      return translations[label];
    }
    return `${label}(to translate)`;
  }, []);

  if (!values) {
    return console.error('No Values provided');
  }

  return (
    <Grid container direction="column" sx={{ marginBottom: '2rem' }}>
      <Grid item xs={12}>
        <Editor
          cellPlugins={cellPlugins}
          value={values}
          readOnly={true}
          lang={i18next.language}
          languages={supportedLanguages}
          uiTranslator={uiTranslator}
          themeMode={mode}
          shouldShowErrorInCells={true}
        />
      </Grid>
    </Grid>
  );
};

export { IntuPageBuilderReadOnly };
