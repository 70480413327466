import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import logoThemeDark from '../../assets/logos/logo-light.png';
import logoThemeLight from '../../assets/logos/logo-light.png';
import { Drawer, Box, Grid, IconButton, Avatar, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import useIntuTheme from '../../hooks/useIntuTheme';

// Animation
import { motion } from 'framer-motion';
import { NavContainer } from '../../framer-motion/animations';

// Nav Context
import NonAuthNavItems from './navitems/NonAuthNavItem';
import { NavBarDrawerContext } from '../../context/providers/NavBarDrawerContextProvider';
import useUser from '../../hooks/useUser';
import { IntuAppBar } from '../../mui/appBar';
import { DrawerHeaderContent } from './drawer/DrawerHeaderContent';
import DrawerFooterContent from './drawer/DrawerFooterContent';
import { UserProfileProvider } from '../../context/providers/UserProfileProvider';
import { CompanyProfileProvider } from '../../context/providers/CompanyProfileProvider';
import { useTheme } from '@mui/styles';

const NonAuthNavBarComponent = () => {
  const { handleLanguageChange } = useUser();
  const { mode } = useIntuTheme();

  const navigate = useNavigate();
  const { handleDrawerClose, handleDrawerOpen, drawerOpen } =
    useContext(NavBarDrawerContext);

  const logo = mode === 'dark' ? logoThemeDark : logoThemeLight;
  const theme = useTheme();

  return (
    <CompanyProfileProvider>
      <UserProfileProvider>
        <Box sx={{ flexGrow: 1 }}>
          <IntuAppBar position="fixed" open={drawerOpen}>
            <Toolbar variant="dense" sx={{ maxWidth: '100%' }}>
              <Box display="flex" alignItems="center" flex={1}>
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: 2,
                    ...(drawerOpen && { display: 'none' }),
                  }}
                >
                  <MenuIcon
                    sx={{ color: theme.components.MuiAppBar.menuIcon }}
                  />
                </IconButton>
                <IconButton onClick={() => navigate('/')}>
                  <Avatar
                    alt="IN2TEC"
                    sx={{
                      width: '60px',
                      height: '60px',
                      margin: '10px 0 10px 0',
                    }}
                    src={logo}
                    style={{ backgroundColor: 'transparent' }}
                    variant="square"
                  />
                </IconButton>
              </Box>
              <NonAuthNavItems mode="NavBar" />
            </Toolbar>
            <motion.div>
              <Drawer
                sx={{
                  flexShrink: 0,
                }}
                variant="temporary"
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
                variants={NavContainer}
                animate={drawerOpen ? 'visible' : 'hidden'}
                initial="hidden"
              >
                <Grid container direction={'column'}>
                  <Grid item>
                    <DrawerHeaderContent />
                  </Grid>
                  <Grid item>
                    <NonAuthNavItems />
                  </Grid>
                  <Grid item>
                    <DrawerFooterContent
                      onLanguageChange={handleLanguageChange}
                    />
                  </Grid>
                </Grid>
              </Drawer>
            </motion.div>
          </IntuAppBar>
        </Box>
      </UserProfileProvider>
    </CompanyProfileProvider>
  );
};

export default NonAuthNavBarComponent;
