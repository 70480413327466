// Is being used on  to set Page Title and Description

import { createContext, useState } from 'react';

export const LayoutBodyContext = createContext();

const LayoutContextProvider = ({ children }) => {
  const [layoutTitle, setLayoutTitle] = useState(null);
  const [layoutDescription, setLayoutDescription] = useState(null);

  return (
    <LayoutBodyContext.Provider
      value={{
        layoutTitle,
        setLayoutTitle,
        layoutDescription,
        setLayoutDescription,
      }}
    >
      {children}
    </LayoutBodyContext.Provider>
  );
};

export default LayoutContextProvider;
