import { useContext } from 'react';
import {
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  MenuItem,
  Grid,
} from '@mui/material';
import { searchUsers } from '../../../routes/usersRoutes.js';
import { searchProductTypes } from '../../../routes/productsRoutes.js';
import { searchApplications } from '../../../routes/applicationsRoutes.js';
import { getCompanyDetails } from '../../../routes/companies.js';
import { searchBrands } from '../../../routes/brandRoutes.js';
import { useFormikContext } from 'formik';
import useResponseHandling from '../../../hooks/useResponseHandler.js';
import { useTranslation } from 'react-i18next';
import { CompanyProfileContext } from '../../../context/providers/CompanyProfileProvider.jsx';
import { CompanyNameFieldContext } from './CompanyNameField.jsx';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';

const CompanyRelationshipDialog = () => {
  const {
    companyRelationshipDialogOpen,
    setCompanyRelationshipDialogOpen,
    relationship,
    setRelationship,
  } = useContext(CompanyNameFieldContext);

  const { values, setValues, handleBlur, touched, setTouched } =
    useFormikContext();

  const { setNewCompany, companyClaimer, companyProfile, setCompanyProfile } =
    useContext(CompanyProfileContext);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const { t } = useTranslation('dialogs', {
    keyPrefix: 'CompanyRelationshipDialog',
  });
  const { t: transButtons } = useTranslation('buttons');

  const handleGetCompanyDetails = async (id) => {
    let companyData;
    try {
      const companyDetails = await getCompanyDetails(id);

      if (companyDetails.statusCode === 200) {
        companyData = companyDetails.data;
      }
    } catch (error) {
      return error;
    }
    return companyData;
  };

  const handleGetEmployeeInfo = async (companyData) => {
    let employeeData;
    if (companyData?.employees?.length === 0) {
      return;
    }

    try {
      if (companyData?.employees?.length > 0) {
        const adminIDs = companyData.employees
          .filter((employee) => employee.role === 'admin')
          .map((admin) => admin._id);

        const getAdminInfo = await searchUsers({
          key: '_id',
          values: adminIDs,
        });

        if ((getAdminInfo.statusCode = 200)) {
          const AdminNames = getAdminInfo.data.map((admin) => ({
            name: `${admin.first_name} ${admin.last_name}`,
            _id: admin._id,
          }));
          employeeData = getAdminInfo;
          setCompanyProfile({ ...companyProfile, admins: AdminNames });
        } else {
          handleRegularResponse(getAdminInfo);
        }
        return employeeData;
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleGetBrandDetails = async (companyData) => {
    let companyBrands = [];

    if (companyData?.brands?.length === 0) {
      return companyBrands;
    }

    try {
      const brandIDs = companyData.brands;
      const payload = {
        key: '_id',
        values: brandIDs,
      };
      const getBrandInfo = await searchBrands(payload);

      if ((getBrandInfo.statusCode = 200)) {
        const brandInfo = getBrandInfo.data;
        companyBrands = brandInfo;
      }
      return companyBrands;
    } catch (error) {
      return companyBrands;
    }
  };

  const handleGetProducTypetDetails = async (companyData) => {
    let companyProducts = [];

    if (companyData?.product_types?.length === 0) {
      return companyProducts;
    }

    try {
      const productTypesIDs = companyData.product_types;
      const payload = {
        key: '_id',
        values: productTypesIDs,
      };
      const getProductTypesInfo = await searchProductTypes(payload);

      if ((getProductTypesInfo.statusCode = 200)) {
        const productsTypesInfo = getProductTypesInfo.data;
        companyProducts = productsTypesInfo;
      }
      return companyProducts;
    } catch (error) {
      return companyProducts;
    }
  };

  const handleGetApplicationsDetails = async (companyData) => {
    let companyApplications = [];

    if (companyData?.applications?.length === 0) {
      return companyApplications;
    }

    try {
      const applicationIDs = companyData.applications;
      const payload = {
        key: '_id',
        values: applicationIDs,
      };

      const { status, data } = await searchApplications(payload);
      if (status === 'success') {
        companyApplications = data;
      }

      return companyApplications;
    } catch (error) {
      return companyApplications;
    }
  };

  const getAllCompanyDetails = async (id) => {
    try {
      const companyData = await handleGetCompanyDetails(id);
      const companyBrands = await handleGetBrandDetails(companyData);
      const companyProducts = await handleGetProducTypetDetails(companyData);
      const companyApplications =
        await handleGetApplicationsDetails(companyData);

      await handleGetEmployeeInfo(companyData);

      let newParentFormValues = {
        company: {
          name: companyData?.name,
          address: {
            line1: companyData?.address?.line1,
            line2: companyData?.address?.line2,
            city: companyData?.address?.city,
            state: companyData?.address?.state,
            country: companyData?.address?.country,
            postcode: companyData?.address?.postcode,
            coordinates: companyData?.address?.coordinates,
          },
          phone: companyData?.phone,
          email: companyData?.email,
          website: companyData?.website,
          employees: companyData?.employees,
          brands: companyBrands,
        },
        applications: companyApplications,
        product_types: companyProducts,
      };

      let newCurrentFormValues = {
        company: {
          name: companyData?.name,
          address: {
            line1: companyData?.address?.line1,
            line2: companyData?.address?.line2,
            city: companyData?.address?.city,
            state: companyData?.address?.state,
            country: companyData?.address?.country,
            postcode: companyData?.address?.postcode,
            coordinates: companyData?.address?.coordinates,
          },
          phone: companyData?.phone,
          email: companyData?.email,
          website: companyData?.website,
          employees: companyData?.employees,
          brands: companyBrands,
        },
        applications: companyApplications,
        product_types: companyProducts,
      };

      let newCurrentFormValuesTouched = {
        ...touched,
        company: {
          name: true,
          address: {
            line1: true,
            line2: true,
            city: true,
            state: true,
            postcode: true,
            country: true,
            coordinates: true,
          },
          email: true,
          phone: true,
          website: true,
          employees: true,
          brands: true,
          isSubsidiary: true,
          parent_id: true,
        },
      };

      if (relationship === 'subsidiary') {
        // Set the Form up for Subsidiary (some fields can be edited)
        newCurrentFormValues = {
          ...newCurrentFormValues,
          company: {
            ...newCurrentFormValues.company,
            isSubsidiary: true,
            parent_id: companyData?._id,
          },
        };

        newParentFormValues = {
          ...newParentFormValues,
          company: {
            ...newParentFormValues.company,
            isSubsidiary: true,
            parent_id: companyData?._id,
          },
        };
      } else if (relationship === 'employee') {
        // Set the form for employees, no fields can be edited
        newCurrentFormValues = {
          ...newCurrentFormValues,
          company: {
            ...newCurrentFormValues.company,
            _id: companyData?._id,
          },
        };

        newParentFormValues = {
          ...newParentFormValues,
          company: {
            ...newParentFormValues.company,
            _id: companyData?._id,
          },
        };

        setNewCompany(false);
      }

      setValues({ ...values, ...newCurrentFormValues });
      setTimeout(() => {
        setTouched(newCurrentFormValuesTouched, true);
      }, 500);
    } catch (error) {
      return error;
    }
  };

  const handleConfirmClick = async () => {
    await getAllCompanyDetails(companyClaimer.company_id);
    setCompanyRelationshipDialogOpen(false);
  };

  return (
    <Dialog
      open={companyRelationshipDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
            {t('description', {
              companyName: values?.company?.name,
            })}
          </Typography>
        </DialogContentText>
        <TextField
          select
          required
          autoFocus
          type="text"
          name="relationship"
          id="relationship"
          variant="outlined"
          label={t('form.relationship.label')}
          placeholder={t('form.relationship.placeholder')}
          labelid="business-type-label"
          value={relationship}
          onBlur={(e) => {
            handleBlur(e);
          }}
          onChange={(e) => {
            setRelationship(e.target.value);
          }}
          sx={{ width: '100%' }}
        >
          <MenuItem key={0} value="employee">
            {t('form.relationship.options.employee')}
          </MenuItem>
          <MenuItem key={1} value="subsidiary">
            {t('form.relationship.options.subsidiary')}
          </MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <IntuIconButton
              type="cancel"
              variant="outlined"
              tooltipTitle={transButtons('cancel')}
              onClick={() => setCompanyRelationshipDialogOpen(false)}
            />
          </Grid>
          <Grid item>
            <IntuIconButton
              type="submit"
              variant="outlined"
              tooltipTitle={transButtons('confirm')}
              disabled={!relationship}
              onClick={handleConfirmClick}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyRelationshipDialog;
