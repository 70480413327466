import i18next from 'i18next';
import * as Yup from 'yup';

export const imageCreationPluginValidation = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object().shape({
    imageUrl: Yup.string().required(t('file.image_required')),
    label: Yup.string()
      .required('file.alt_label')
      .min(3, ({ min }) => {
        return t('file.alt_min', { type: transTypes('image'), min });
      })
      .max(30, ({ value, max }) => {
        let characterCount = value.trim().length;
        return t('file.alt_max', {
          type: transTypes('image'),
          max,
          characterCount,
        });
      }),
  });
};
