import { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  Chip,
  Box,
  Skeleton,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useContext } from 'react';
import { PieChartContext } from '../../../charts/pieChart';
import ClusteredMap from '../../../components/maps/ClusteredMap';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { useParams } from 'react-router-dom';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler';
import { CustomerMapContext } from '../../providers/maps/CustomerMapProvider';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';
import { searchBrands } from '../../../routes/brandRoutes';
import { searchApplications } from '../../../routes/applicationsRoutes';
import {
  searchProductTypes,
  searchProducts,
  searchProductGroup,
} from '../../../routes/productsRoutes';
import AuthContext from '../../providers/AuthProvider';
import { IntuRichTextEditor } from '../../../components/IntuRichTextEditor/IntuRichtTextEditor';
import IntuThemeContext from '../../providers/IntuThemeProvider';
import { useTranslation } from 'react-i18next';
import { WarrantyTosDialog } from '../../../components/profile/WarrantyTosDialog';
import { ProfileHeaderComponent } from '../../../components/profile/ProfileHeaderComponent';
import { ProfileBlurbComponent } from '../../../components/profile/ProfileBlurbComponent';
import { ProfileAnalyticsChart } from '../../../components/profile/ProfileAnalyticsChart';
import i18n from '../../../i18n';
import ApplyDialog from './ApplyDialog';
import { CompanyPublicProfileContext } from '../../providers/CompanyPublicProfileProvider';

// Icons
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GavelIcon from '@mui/icons-material/Gavel';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PaidIcon from '@mui/icons-material/Paid';
import VerifiedIcon from '@mui/icons-material/Verified';

export const CompanyPublicProfileContent = () => {
  const { applyToRepresent } = useContext(CompanyPublicProfileContext);
  const {
    findMedian,
    calculateDistanceInMiles,
    calculateFurthestPoints,
    furthestPoints,
    setFurthestPoints,
    zoomLevel,
    setZoomLevel,
  } = useContext(CustomerMapContext);
  const { id } = useParams();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const [publicCompanyInfo, setPublicCompanyInfo] = useState({});
  const [companyOrders, setCompanyOrders] = useState([]);
  const [comapnyOrderItems, setCompanyOrderItems] = useState([]);
  const [companyOffers, setCompanyOffers] = useState([]);
  const [companyOpportunites, setCompanyOpportunities] = useState([]);
  const [companyProducts, setCompanyProducts] = useState([]);
  const [orderChartData, setOrderChartData] = useState([]);
  const [offerChartData, setOfferChartData] = useState([]);
  const [opportunityChartData, setOpportunityChartData] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const [isLoadingPieChart, setIsLoadingPieChart] = useState(true);
  const [activeChartData, setActiveChartData] = useState('order');
  const [isOpen, setIsOpen] = useState(false);
  const [conditionType, setConditionType] = useState('');
  const [associateData, setAssociateData] = useState([]);
  const [associateCoordinates, setAssocaiteCoordinates] = useState([]);
  const [companyMiscInfo, setCompanyMiscInfo] = useState({
    launch_date: '',
    salesreps: '',
    sales_yoy: 0,
    average_commission: 0,
    average_turnaround: 0,
  });
  const [center, setCenter] = useState({ lng: 39.8283, lat: 98.5795 });
  const [pieChartData, setPieChartData] = useState([]);
  const [conversionRate, setConversionRate] = useState(0);
  const [averageRevenue, setAverageRevenue] = useState(0);
  const [isApplyDialogOpen, setIsApplyDialogOpen] = useState(false);

  const { mobileView, mode } = useContext(IntuThemeContext);
  const { auth } = useContext(AuthContext);
  const theme = useTheme();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.PublicProfile',
  });
  const { t: transTypes } = useTranslation('types');

  // SetUp Axios
  const axios = useAxiosPrivate();

  // Search Brands in database
  async function handleGetBrands(brandsById) {
    // Guard Clause
    if (brandsById?.length === 0) {
      return;
    }

    let companyBrands = [];
    try {
      const payload = {
        key: '_id',
        values: brandsById,
      };
      const { data, status } = await searchBrands(payload);

      if (status === 'success') {
        companyBrands = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyBrands;
  }

  // Search Products
  async function handleGetProducts(companyName) {
    // Guard Clause
    if (!companyName) {
      return;
    }
    let companyProducts = [];
    try {
      const payload = {
        'author.name': companyName,
      };

      const { data, status } = await searchProducts(payload);
      if (status === 'success') {
        companyProducts = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyProducts;
  }

  // Search Applications
  async function handleGetApplications(applicationsById) {
    // Guard Clause
    if (applicationsById?.length === 0) {
      return;
    }

    let companyApplications;
    try {
      const payload = {
        key: '_id',
        values: applicationsById,
      };
      const { status, data } = await searchApplications(payload);

      if (status === 'success') {
        companyApplications = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyApplications;
  }

  // Search Product Types
  async function handleGetProductTypes(productTypeId) {
    // Guard Clause
    if (productTypeId?.length === 0) {
      return;
    }
    let productTypes;
    try {
      const payload = {
        key: '_id',
        values: productTypeId,
      };
      const { status, data } = await searchProductTypes(payload);
      if (status === 'success') {
        productTypes = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return productTypes;
  }

  // Handle Get Company Info
  async function getCompanyPublicProfile(id) {
    // Guard Clause
    if (!id && !auth.user_info && !auth.auth_info) {
      return;
    }

    const payload = {
      _id: id,
    };

    const url = `/api/companies/company/public/`;
    try {
      const { data, status } = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });

      const info = data.data;

      if (status === 200) {
        // Format Image
        let image = '';
        if (info?.profile_picture?.mimetype) {
          image = `data:${info.profile_picture.mimetype};base64,${info.profile_picture.buffer.toString('base64')}`;
        }
        const date = new Date(info.created.date).toLocaleDateString();
        const companyData = {
          _id: info._id,
          name: info?.name,
          email: info?.email,
          address: info?.address,
          phone: info?.phone,
          type: info?.type,
          brands:
            info?.brands?.length > 0 ? await handleGetBrands(info.brands) : [],
          website: info?.website,
          colors: info?.colors,
          applications:
            (info?.applications.length > 0 &&
              (await handleGetApplications(info.applications))) ||
            [],
          product_types:
            (info?.product_types.length > 0 &&
              (await handleGetProductTypes(info.product_types))) ||
            [],
          intro: info?.intro,
          tow: info?.tow,
          tos: info?.tos,
          commission_schedule: info?.commission_schedule,
          profile_picture: info?.profile_picture,
          profile_picture_view_url: image,
          associates:
            info?.associates?.length > 0
              ? await handleGetAssociates(info.associates)
              : [],
          created: date,
        };
        setPublicCompanyInfo(companyData);
        return companyData;
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    }
  }

  // Handle get Company's Orders
  async function getCompanyOrders() {
    // Guard Clause
    if (!auth.user_info && !auth.auth_info) {
      return;
    }
    const url = '/api/orders/orders/search';
    const payload = {
      data: [
        {
          key: 'manufacturer',
          value: [id],
        },
      ],
    };

    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data, status } = response;

      if (status === 200) {
        return data.data;
      } else {
        handleErrorResponse(data);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  // Handle get Company's Offers
  async function getCompanyOffers() {
    // Guard Clause
    if (!auth.user_info && !auth.auth_info) {
      return;
    }
    const url = '/api/offers/offers/search';
    const payload = {
      data: [
        {
          key: 'manufacturer',
          value: [id],
        },
      ],
    };
    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data, status } = response;

      if (status === 200) {
        return data.data;
      } else {
        handleErrorResponse(data);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  async function getCompanyOpportunities() {
    // Guard Clause
    if (!auth.user_info && !auth.auth_info) {
      return;
    }
    const url = '/api/opportunities/search';
    const payload = {
      data: [
        {
          key: 'manufacturer',
          value: [id],
        },
      ],
    };
    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data, status } = response;

      if (status === 200) {
        return data.data;
      } else {
        handleErrorResponse(data);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  async function getCompanyOrderItems(orders) {
    // Guard Clause
    if (!auth.user_info && !auth.auth_info) {
      return;
    }
    const url = '/api/orders/items/search';

    const payload = {
      data: [
        {
          key: 'parent_id',
          value: orders,
        },
      ],
    };

    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data, status } = response;

      if (status === 200) {
        return data.data;
      } else {
        handleErrorResponse(data);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  // Format Order and Offers data by month
  function formatDataByMonth(arr) {
    const newArr = [];
    arr?.map((element) => {
      const date = new Date(element.order_date);
      const month_name = date.toLocaleString('default', { month: 'long' });
      // Search if month key exists
      const lowercase_month = month_name.toLowerCase();
      const trans_month = transTypes(`months.${lowercase_month}`);
      const month_obj = newArr.find((month) => month.category === month_name);
      // If month doesn't exist
      if (!month_obj) {
        newArr.push({
          category: trans_month,
          value: element.grand_total,
        });
      } else {
        month_obj.value += element.grand_total;
      }
    });

    return newArr;
  }

  function getPastSixMonths(arr) {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    const filtered = arr?.filter((item) => {
      const itemDate = new Date(item.order_date);
      return itemDate >= sixMonthsAgo;
    });
    return filtered;
  }

  function getAverageRevenue(arr) {
    let total = 0;
    let count = 0;
    for (let i = 0; i < arr?.length; i++) {
      total += arr[i].grand_total;
      count++;
    }
    return total / count;
  }

  function getAverageTurnaround(arr) {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    let total_duration = 0;
    let count = 0;
    arr?.map((item) => {
      const create_date = new Date(item.created.date);
      const close_date = new Date(item.close_date);
      const create_dateMs = create_date.getTime();
      const close_dateMs = close_date.getTime();

      const difference = Math.abs(create_dateMs - close_dateMs);
      total_duration += Math.round(difference / oneDay);
      count++;
    });
    return total_duration / count;
  }

  // Get Product's Group and Family
  async function getProductGroup(productGroupId) {
    if (!productGroupId) {
      return;
    }

    const payload = {
      key: '_id',
      values: [productGroupId],
    };

    try {
      const { data, status } = await searchProductGroup(payload);

      if (status === 'success') {
        return data;
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  // Get Average Product Commission (Based on $100,000 in sales)
  // TODO: CHANGE TO ROLLING COMMISSION (6 MONTHS)
  function calculateAverageProductCommission(commission_schedule) {
    let payout = 0;
    let totalSales = 100000;
    commission_schedule.map((tier) => {
      if (tier.max_amount < totalSales) {
        totalSales -= tier.max_amount;
        payout += tier.max_amount * tier.commission;
      } else {
        payout += (tier.max_amount - totalSales) * tier.commission;
      }
    });
    return +(payout / totalSales).toFixed(2);
  }

  function calculateAverageCompanyCommission(orderItems) {
    let line_totals = 0;
    let total_commissions = 0;
    orderItems?.map((orderItem) => {
      line_totals += Number(orderItem.line_total);
      total_commissions += Number(orderItem.commission);
    });
    return ((total_commissions / line_totals) * 100).toFixed(2);
  }

  // Get Company's associates
  async function handleGetAssociates(associatesById) {
    // Guard Clause
    if (!auth.user_info && !auth.auth_info) {
      return;
    }
    const url = '/api/users/associates';
    const payload = {
      key: '_id',
      values: associatesById,
    };
    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data, status } = response;

      if (status === 200) {
        return data.data;
      } else {
        handleErrorResponse(data);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  async function getAssociateIndustries(associates) {
    try {
      const newAssociateData = await Promise.all(
        associates.map(async (associate) => {
          const industry = await handleGetApplications(associate.applications);
          return {
            ...associate,
            industry,
          };
        }),
      );
      return newAssociateData;
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  async function preLoadInfo() {
    setIsLoading({
      status: true,
      text: `Loading ${publicCompanyInfo.name}'s Profile`,
      type: 'spinner',
    });
    const orderArr = [];
    const offerArr = [];
    try {
      const companyInfo = await getCompanyPublicProfile(id);
      const orders = await getCompanyOrders();
      orders.map((order) => {
        orderArr.push(order._id);
      });
      const orderItems = await getCompanyOrderItems(orderArr);
      const offers = await getCompanyOffers();
      const opportunites = await getCompanyOpportunities();
      const rawProducts = await handleGetProducts(companyInfo.name);
      const productPromises = rawProducts.map(async (product) => {
        const productFamily = await getProductGroup(
          product?.product_types?.product_group,
        );
        const productApplication = await searchApplications({
          key: '_id',
          values: product?.product_applications?._id,
        });
        return {
          ...product,
          product_types: {
            ...product.product_types,
            product_family: productFamily[0].family_name,
            product_group: productFamily[0].group_name,
          },
          product_applications: {
            application_name: productApplication.data[0].application_name,
            industry_name: productApplication.data[0].industry_name,
          },
          commission:
            product.commission_schedule.type === 'progressive'
              ? calculateAverageProductCommission(
                  product?.commission_schedule?.commission,
                )
              : product.commission_schedule.commission,
        };
      });

      const products = await Promise.all(productPromises);

      const associateData = await getAssociateIndustries(
        companyInfo.associates,
      );
      setPublicCompanyInfo(companyInfo);
      setCompanyOrders(orders);
      setCompanyOrderItems(orderItems);
      setCompanyOffers(offers);
      setCompanyProducts(products);
      setCompanyOpportunities(opportunites);
      setAssociateData(associateData);
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setIsLoading({
        status: false,
        text: '',
        type: 'spinner',
      });
    }
  }

  // Preload Info
  useEffect(() => {
    preLoadInfo();
  }, [i18n.language]);

  // Set Company Misc Info
  useEffect(() => {
    setMiscInfo();

    function getTotalYearSales() {
      let total = 0;
      companyOrders?.map((order) => {
        total += order.grand_total;
      });

      return total.toLocaleString();
    }

    function setMiscInfo() {
      setCompanyMiscInfo({
        ...companyMiscInfo,
        launch_date: publicCompanyInfo.created,
        sales_yoy: getTotalYearSales(),
        salesreps: associateData.length,
        average_commission:
          calculateAverageCompanyCommission(comapnyOrderItems),
        average_turnaround: getAverageTurnaround(companyOpportunites),
      });
    }
  }, [companyOrders]);

  // Format and Set Chart Data
  useEffect(() => {
    setIsChartLoading(true);
    // Format Order Data By Month for Bar Chart
    const orderSalesData = formatDataByMonth(companyOrders);
    orderSalesData.forEach(
      (month) => (month.value = +month.value?.toFixed(2) || 0),
    );
    setOrderChartData([
      {
        data: orderSalesData,
        seriesName: t('revenue'),
      },
    ]);

    const offerData = formatDataByMonth(companyOffers);
    offerData.forEach((month) => (month.value = +month.value.toFixed(2)));
    setOfferChartData([
      {
        data: offerData,
        seriesName: t('offers'),
      },
    ]);

    const opportunityData = formatDataByMonth(companyOpportunites);
    opportunityData.forEach((month) => (month.value = +month.value.toFixed(2)));
    setOpportunityChartData([
      {
        data: opportunityData,
        seriesName: t('opportunities'),
      },
    ]);

    const sixMonthsOrderRevenue = getPastSixMonths(companyOrders);
    const averageSixMonthsOrderRevenue = getAverageRevenue(
      sixMonthsOrderRevenue,
    ).toFixed(2);
    setAverageRevenue(averageSixMonthsOrderRevenue.toLocaleString());

    setIsChartLoading(false);
  }, [companyOrders, companyOffers, companyOpportunites, i18n.language]);

  // Get Coordinates and Set Clustered Map Data
  useEffect(() => {
    if (!associateData.length) return;
    const coordinatesArr = [];
    let clusteredMapData;

    if (associateData.length > 0) {
      clusteredMapData = associateData?.map((associate) => {
        coordinatesArr.push({
          lat: associate.address.coordinates.latitude,
          lng: associate.address.coordinates.longitude,
        });
        return {
          name: associate.first_name + ' ' + associate.last_name,
          category: associate.industry[0].industry_name,
          coordinates: {
            lat: associate.address.coordinates.latitude,
            lng: associate.address.coordinates.longitude,
          },
        };
      });
    }

    setAssocaiteCoordinates(clusteredMapData);
    const median = findMedian(coordinatesArr);
    setCenter(median);
    const points = calculateFurthestPoints(coordinatesArr);
    setFurthestPoints(points);
    let distance;

    setTimeout(() => {
      distance = calculateDistanceInMiles(
        furthestPoints[0]?.lat,
        furthestPoints[0]?.lng,
        furthestPoints[1]?.lat,
        furthestPoints[1]?.lng,
      );

      switch (true) {
        case distance <= 150:
          setZoomLevel(6);
          break;
        case distance > 150 && distance <= 500:
          setZoomLevel(5);
          break;
        case distance > 500 && distance <= 1000:
          setZoomLevel(4);
          break;
        case distance > 1000 && distance <= 3500:
          setZoomLevel(3);
          break;
        default:
          setZoomLevel(2);
      }
    }, '2000');

    setTimeout(() => {
      setIsLoadingMap(false);
    }, '3000');
  }, [associateData, publicCompanyInfo, zoomLevel, setZoomLevel]);

  // Set Pie Chart Data
  useEffect(() => {
    let won = 0;
    let lost = 0;
    if (companyOffers?.length > 0) {
      companyOffers?.forEach((offer) => {
        if (offer.status === 'won') won += 1;
        else if (offer.status === 'lost') {
          lost += 1;
        }
      });
      setPieChartData([
        {
          category: t('won'),
          value: won,
        },
        {
          category: t('lost'),
          value: lost,
        },
      ]);

      setTimeout(() => {
        setIsLoadingPieChart(false);
      }, '2000');
    }
    setConversionRate(won / (won + lost));
  }, [companyOffers, i18n.language]);

  // Conversion Rate Chart settings
  const conversionChartSettings = {
    chartHeight: 500,
    label: '$',
    type: 'Bar Chart',
    minValue: 0,
    maxValue: 10000,
    maxPrecision: 10,
    showCursor: false,
    showDataLabels: false,
    showLegend: false,
  };

  // Columns for data grid
  const columns = [
    {
      field: 'product_name',
      headerName: t('product_name'),
      flex: 3,
      editable: false,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <img src={row.product_image} alt="" />
            </Grid>
            <Grid item ml={2}>
              <Typography>{row.product_name}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'mpn',
      headerName: 'MPN',
      flex: 2,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>{row.mpn}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'product_family',
      headerName: t('product_family'),
      flex: 2,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>{row.product_types.product_family}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'product_group',
      headerName: t('product_group'),
      sortable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>{row.product_types.product_group}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'product_applications',
      headerName: t('applications'),
      sortable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Chip label={row.product_applications.application_name} />
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'commission',
      headerName: t('commission'),
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Grid container>
            <Grid item pr={2} key={Math.random()}>
              <Typography>{row.commission + '%'}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'list_price',
      headerName: t('list_price'),
      sortable: false,
      flex: 1,
    },
  ];

  // Grab ID for row for data grid
  function getRowId(row) {
    return row._id;
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = async (message) => {
    await applyToRepresent(message, id);
  };

  return (
    <Grid container>
      {/* Header */}
      <ProfileHeaderComponent
        profileInfo={publicCompanyInfo}
        miscInfo={companyMiscInfo}
        setIsDialogOpen={() => setIsApplyDialogOpen(true)}
        type="manufacturer"
      />

      {/* Map + Bar Chart */}
      <Grid container item flexDirection="column" xs={12} mt={4}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-around' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          {/* Areas Served */}
          <Grid
            container
            item
            md={5.95}
            flexDirection="column"
            justifyContent="center"
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: '16px',
                backgroundColor: theme.palette.profile[mode],
              }}
            >
              <Grid container item>
                <Grid item ml={2}>
                  <Typography variant="h6">{t('current_salesreps')}</Typography>
                </Grid>
                {isLoadingMap ? (
                  <Skeleton variant="rectangular" height={500} />
                ) : (
                  <ClusteredMap
                    locations={associateCoordinates}
                    initialZoom={zoomLevel}
                    popup={true}
                    cluster={false}
                    projection="globe"
                    rounded
                    isDataLoaded={isLoadingMap}
                    center={center}
                    disableBorder
                  />
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid
            container
            item
            md={5.95}
            flexDirection="column"
            justifyContent="center"
          >
            {/* Analytics */}
            <ProfileAnalyticsChart
              activeChartData={activeChartData}
              setActiveChartData={setActiveChartData}
              isChartLoading={isChartLoading}
              chartSettings={conversionChartSettings}
              mobileView={mobileView}
              data1={orderChartData}
              data2={offerChartData}
              data3={opportunityChartData}
              type="manufacturer"
            />
          </Grid>
        </Grid>

        {/* Additional Company Info */}
        <Box
          mb={4}
          mt={4}
          pb={6}
          sx={{
            backgroundColor: theme.palette.profile[mode],
            borderRadius: '25px',
          }}
        >
          <Grid container item justifyContent="space-around">
            <Grid
              container
              item
              xs={12}
              md={7.5}
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              <Grid item xs={12} md={8.5} mt={4}>
                <Typography variant="h6">{t('about_company')}</Typography>
                <IntuRichTextEditor
                  disabled
                  defaultValue={JSON.stringify(publicCompanyInfo?.intro)}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={3}
                justifyContent="space-around"
                flexDirection="column"
              >
                <Grid item>
                  <ProfileBlurbComponent
                    icon={<GroupAddIcon fontSize="large" />}
                    value={'$' + averageRevenue}
                    description={t('avg_order_revenue')}
                  />
                </Grid>
                <Grid item>
                  <ProfileBlurbComponent
                    icon={<PaidIcon fontSize="large" />}
                    value={companyMiscInfo?.average_commission + '%'}
                    description={t('avg_commission')}
                  />
                </Grid>
                <Grid item>
                  <ProfileBlurbComponent
                    icon={<VerifiedIcon fontSize="large" />}
                    value={
                      companyMiscInfo?.average_turnaround + ' ' + t('days')
                    }
                    description={t('avg_turnaround')}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={8}
                flexDirection="row"
                mt={2}
                justifyContent="space-around"
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  flexDirection="column"
                  xs={6}
                >
                  <Grid item>
                    <Typography variant="h6">{t('t&c')}</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setIsOpen(true);
                        setConditionType('tos');
                      }}
                      startIcon={<GavelIcon />}
                      variant="contained"
                    >
                      {t('t&c')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  flexDirection="column"
                  xs={6}
                >
                  <Grid item>
                    <Typography variant="h6">{t('warranty')}</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setIsOpen(true);
                        setConditionType('warranty');
                      }}
                      startIcon={<WorkspacePremiumIcon />}
                      variant="contained"
                    >
                      {t('warranty')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4} flexDirection="column">
              <Grid
                item
                container
                flexDirection="row"
                justifyContent="space-around"
                mt={6}
              >
                <Grid item xs={12}>
                  {isLoadingPieChart ? (
                    <Skeleton />
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: theme.palette.profile[mode],
                        borderRadius: '25px',
                      }}
                    >
                      <PieChartContext
                        data={pieChartData}
                        mobileView={mobileView}
                        chartId="conversion_rate"
                        description={t('rolling_conversion_rate')}
                        centered
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* Company Products */}
        <Grid pb={25}>
          <Typography variant="h5">{t('brands')}</Typography>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              getRowId={getRowId}
              rows={companyProducts}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 3,
                  },
                },
              }}
              pageSizeOptions={[3]}
              disableRowSelectionOnClick
              rowHeight={97}
              sx={{
                '.MuiDataGrid-columnHeader': {
                  backgroundColor: 'transparent',
                  color: 'white',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {isOpen && conditionType === 'tos' && (
        <>
          <WarrantyTosDialog
            content={publicCompanyInfo?.tos}
            handleClose={handleClose}
            companyName={publicCompanyInfo?.name}
            type={conditionType}
            isOpen
          />
        </>
      )}
      {isOpen && conditionType === 'warranty' && (
        <>
          <WarrantyTosDialog
            content={publicCompanyInfo?.tow}
            handleClose={handleClose}
            companyName={publicCompanyInfo?.name}
            type={conditionType}
            isOpen
          />
        </>
      )}
      <ApplyDialog
        isOpen={isApplyDialogOpen}
        handleClose={() => setIsApplyDialogOpen(false)}
        handleConfirm={handleConfirm}
      />
    </Grid>
  );
};
