import { axiosPrivate } from './axios';
import { axiosPrivateData } from './axios';

import { useContext, useEffect } from 'react';
import useRefreshToken from '../useRefreshToken';
import i18n from '../../i18n';
import AuthContext from '../../context/providers/AuthProvider';

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth?.auth_info}`;
          config.headers['Accept-Language'] = i18n.language;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,

      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        } else if (error?.response?.status === 401 && prevRequest?.sent) {
          console.log('Session expired', error?.response?.message);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth]);

  return axiosPrivate;
};

const useAxiosPrivateData = () => {
  const refresh = useRefreshToken();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    // if (!refresh) {
    //   return;
    // }

    const requestIntercept = axiosPrivateData.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth.auth_info}`;
          config.headers['Accept-Language'] = i18n.language;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    const responseIntercept = axiosPrivateData.interceptors.response.use(
      (response) => response,

      async (error) => {
        // console.log("Response interceptor triggered");

        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        } else if (error?.response?.status === 401 && prevRequest?.sent) {
          console.log(`Session expired ${JSON.stringify(error.response)}`);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axiosPrivateData.interceptors.request.eject(requestIntercept);
      axiosPrivateData.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosPrivateData;
};

export { useAxiosPrivate, useAxiosPrivateData };
