import { useContext } from 'react';
import {
    Grid,
    Divider,
    Button,
    Typography,
    Chip,
    Box,
    Avatar,
    useTheme,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useTranslation } from 'react-i18next';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';

export const ProfileHeaderComponent = ({
    profileInfo,
    miscInfo,
    setIsDialogOpen,
    type,
}) => {
    const theme = useTheme();
    const { mode } = useContext(IntuThemeContext);
    const { t } = useTranslation('translation', {
        keyPrefix: 'components.ProfileHeaderComponent',
    });
    const { t: transButtons } = useTranslation('buttons');

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: theme.palette.profile[mode],
                borderRadius: '25px',
            }}
        >
            <Grid
                item
                container
                flexDirection={{ xs: 'column', md: 'row' }}
                md={12}
                pb={4}
                pt={4}
            >
                <Grid item xs={12} md={2} ml={2}>
                    <Avatar
                        src={profileInfo?.profile_picture_view_url}
                        sx={{
                            width: '15rem',
                            height: '15rem',
                        }}
                    />
                </Grid>
                <Grid container item flexDirection="column" xs={12} md={9.5}>
                    <Grid
                        container
                        flexDirection={{ xs: 'column', md: 'row' }}
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} md={8}>
                            {/* Company Name */}
                            <Typography variant="h3" xs={6}>
                                {profileInfo?.name}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            mb={2}
                            justifyContent="flex-end"
                            xs={12}
                            md={4}
                        >
                            <Grid mr={4}>
                                <Button variant="contained" startIcon={<EmailIcon />}>
                                    {transButtons('message')}
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    startIcon={<VerifiedUserIcon />}
                                    onClick={() => setIsDialogOpen(true)}
                                >
                                    {type === 'manufacturer' ? transButtons('apply') : 'Recruit'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        item
                        flexDirection={{ xs: 'column', md: 'row' }}
                        mt={4}
                        justifyContent={{ xs: 'center', md: 'space-between' }}
                        sx={{ display: 'flex', maxHeight: '100vh' }}
                    >
                        <Grid item container flexDirection="column" xs={12} md={4} ml={12}>
                            {type === 'manufacturer' && (
                                <Grid
                                    item
                                    container
                                    flexDirection="row"
                                    xs={12}
                                    sm={12}
                                    md={7}
                                    sx={{ height: '3rem' }}
                                >
                                    <Grid
                                        container
                                        item
                                        md={5}
                                        sx={{ height: '5rem' }}
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12}>
                                            <Typography>{t('launch_date')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{t('sales_reps')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{t('sales_yoy')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{t('hq')}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={5}
                                        sx={{ height: '5rem' }}
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <Typography>{miscInfo?.launch_date}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{miscInfo?.salesreps}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{'$' + miscInfo?.sales_yoy}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {profileInfo?.address?.city},{' '}
                                                {profileInfo?.address?.state}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {type === 'sales' && (
                                <Grid
                                    item
                                    container
                                    flexDirection="row"
                                    xs={12}
                                    sm={12}
                                    md={7}
                                    sx={{ height: '3rem' }}
                                >
                                    <Grid
                                        container
                                        item
                                        md={5}
                                        sx={{ height: '5rem' }}
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12}>
                                            <Typography>{t('member_since')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{t('located')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{t('brands')}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{t('sales_yoy')}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={5}
                                        sx={{ height: '5rem' }}
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <Typography>{miscInfo?.member_since}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {miscInfo?.location?.city}, {miscInfo?.location?.state}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{miscInfo?.brands}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{miscInfo?.sales_yoy}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            flexDirection="column"
                            xs={12}
                            md={6}
                            spacing={4}
                        >
                            {/* Industries */}
                            <Grid item container>
                                <Grid item md={3}>
                                    <Typography>{t('applications')}</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    {profileInfo?.applications?.map((industry) => {
                                        return (
                                            <Chip
                                                label={industry.application_name}
                                                key={Math.random()}
                                            />
                                        );
                                    })}
                                </Grid>
                            </Grid>
                            {/* Products */}
                            <Grid item container>
                                <Grid item md={3}>
                                    <Typography>{t('products')}</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    {profileInfo?.product_types?.map((product) => {
                                        return (
                                            <Chip label={product.type_name} key={product.type_name} />
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
