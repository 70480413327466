import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ApplyDialog = ({ isOpen, handleConfirm, handleClose }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleConfirmClick = () => {
    handleConfirm(message);
    handleClose();
  };

  const handleCloseClick = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          height: '80%',
          maxHeight: '600px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant="h6">Apply to Represent</Typography>
        <Typography variant="body2">
          Apply today! Selected partners receive a Free IN2TEC sticker and
          exclusive offers & have a direct influence on SqaureIT and its new
          products. Lastly, join one of the most positive growing communities
          around the world. Join the 10,000+ strong sales rep team today and
          fill out this application ASAP. Expect to hear back in 1-2 days.
        </Typography>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <FormControl fullWidth>
          <TextField
            type="text"
            multiline
            rows={12}
            label="Brief explanation on why you should be picked."
            variant="outlined"
            size="small"
            name="message"
            value={message}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </FormControl>
        <Typography variant="body2">
          By clicking send you agree to honor the terms and services of a sales
          rep.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: '16px', justifyContent: 'space-evenly' }}
      >
        <Button
          onClick={handleCloseClick}
          color="secondary"
          variant="contained"
        >
          {t('buttons.close')}
        </Button>
        <Button
          onClick={handleConfirmClick}
          color="primary"
          type="submit"
          disabled={!message.length}
          variant="contained"
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyDialog;
