import { createContext, useState } from 'react';

export const CustomerMapContext = createContext({});

const CustomerMapProvider = ({ children }) => {
    const [center, setCenter] = useState({ "lng": 39.8283, "lat": 98.5795 });
    const [furthestPoints, setFurthestPoints] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(0);

    function findMedian(coordinatesArray) {
        if (coordinatesArray.length === 0) {
            return null;
        }

        // Sort the array of coordinates based on the x-coordinate
        coordinatesArray.sort((a, b) => a[0] - b[0]);

        // Find the middle index
        const mid = Math.floor(coordinatesArray.length / 2);

        // If the array has an even number of coordinates
        if (coordinatesArray.length % 2 === 0) {
            // Return the average of the middle two coordinates
            return [
                (coordinatesArray[mid - 1][0] + coordinatesArray[mid][0]) / 2,
                (coordinatesArray[mid - 1][1] + coordinatesArray[mid][1]) / 2,
            ];
        } else {
            // Return the middle coordinate
            return coordinatesArray[mid];
        }
    };

    function calculateFurthestPoints(coordinates) {
        let maxDistance = 0;
        let furthestPoints = [];

        for (let i = 0; i < coordinates.length; i++) {
            for (let j = i + 1; j < coordinates.length; j++) {
                const distance = calculateDistanceBetweenTwoPoints(coordinates[i], coordinates[j]);
                if (distance > maxDistance) {
                    maxDistance = distance;
                    furthestPoints = [coordinates[i], coordinates[j]];
                } else {
                    return [coordinates[0], coordinates[1]]
                }
            }
        }
        return furthestPoints;
    };

    function calculateDistanceBetweenTwoPoints(point1, point2) {
        const x1 = point1.lng;
        const y1 = point1.lat;

        const x2 = point2.lng;
        const y2 = point2.lat;

        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    };

    function convertDegreesToRadius(degrees) {
        return degrees * (Math.PI / 180);
    };

    function calculateDistanceInMiles(lat1, lng1, lat2, lng2) {
        const radius = 3958.8;
        const latDegrees = convertDegreesToRadius(lat2 - lat1);
        const lngDegrees = convertDegreesToRadius(lng2 - lng1);
        const area =
            Math.sin(latDegrees / 2) * Math.sin(latDegrees / 2) +
            Math.cos(convertDegreesToRadius(lat1)) * Math.cos(convertDegreesToRadius(lat2)) *
            Math.sin(lngDegrees / 2) * Math.sin(lngDegrees / 2)
        const cir = 2 * Math.atan2(Math.sqrt(area), Math.sqrt(1 - area));
        const distance = radius * cir;
        return distance
    };

    return (
        <CustomerMapContext.Provider
            value={{
                findMedian,
                calculateDistanceInMiles,
                calculateFurthestPoints,
                center,
                setCenter,
                furthestPoints,
                setFurthestPoints,
                zoomLevel,
                setZoomLevel
            }}
        >
            {children}
        </CustomerMapContext.Provider>
    );
};

export default CustomerMapProvider;