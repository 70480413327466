import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { IntuRichTextEditor } from "../IntuRichTextEditor/IntuRichtTextEditor";

export const WarrantyTosDialog = ({
    content,
    type,
    companyName,
    isOpen,
    handleClose
}) => {
    return (
        <Dialog
            open={isOpen}
        >
            <DialogTitle>
                {companyName}'s {type}
            </DialogTitle>
            <DialogContent>
                <IntuRichTextEditor
                    defaultValue={JSON.stringify(content)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}