// Holds Industries Context

import React, { createContext, useEffect, useState } from 'react';
import useResponseHandling from '../../hooks/useResponseHandler';
import useAuth from '../../hooks/useAuth';
import useUser from '../../hooks/useUser';
import i18n from '../../i18n';
import {
  getIndustries,
  searchIndustries,
  addIndustries,
} from '../../routes/industriesRoutes';

export const IndustriesContext = createContext();

export const IndustriesProvider = ({ children }) => {
  // Application Dialog States
  const { auth } = useAuth();
  const { user } = useUser();

  const [newIndustriesDialogOpen, setNewIndustriesDialogOpen] = useState(false);
  const [industriesNew, setIndustriesNew] = useState({
    industry_name: '',
    industry_id: '',
  });
  const [industryOptions, setIndustryOptions] = useState([]);
  const [newIndustriesList, setNewIndustriesList] = useState([]);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const addNewIndustriesToDatabase = async () => {
    if (newIndustriesList.length === 0) {
      return;
    }

    try {
      const response = await addIndustries(newIndustriesList);

      if (response.statusCode === 200) {
        setNewIndustriesList([]);
        return response.data;
      } else {
        handleRegularResponse({
          open: true,
          status: 'warning',
          message: response.message,
        });
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  // Get Industries
  async function handleGetIndustries() {
    try {
      const industriesList = await getIndustries();
      if (industriesList.status === 'success') {
        setIndustryOptions(industriesList.data);
      }
    } catch (error) {
      // handleErrorResponse(err);
      console.error('error', error);
    }
  }

  // Get Industries by Name
  async function handleSearchIndustries(industriesByID) {
    const results = await searchIndustries(industriesByID);
    let resultsArray = [];
    if (results?.length > 0) {
      resultsArray = results;
    }

    return resultsArray;
  }

  // Pre Load Form
  useEffect(() => {
    if (!user?.location) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    async function preLoadformInfo() {
      await handleGetIndustries();
      setNewIndustriesList([]);
    }

    preLoadformInfo().then(() => {
      controller.abort(signal);
    });
  }, [auth?.user_info?.account_type, user?.location, i18n?.language]);

  return (
    <IndustriesContext.Provider
      value={{
        newIndustriesDialogOpen,
        setNewIndustriesDialogOpen,
        industryOptions,
        setIndustryOptions,
        handleGetIndustries,
        handleSearchIndustries,
        industriesNew,
        setIndustriesNew,
        newIndustriesList,
        setNewIndustriesList,
        addNewIndustriesToDatabase,
      }}
    >
      {children}
    </IndustriesContext.Provider>
  );
};
