import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import axios from '../../hooks/axios/axios';
import AttributeTypeBox from './AttributeTypeBox';
import AttributeBox, { formatAttribute } from './AttributeBox';
import AttributeValueBox, { formatAttributeValue } from './AttributeValueBox';
import { axiosPrivate } from '../../hooks/axios/axios';

const AttributeContainer = ({
  product,
  setProduct,
  productAttributeOptions,
  setProductAttributeOptions,
}) => {
  const [selectedAttributeType, setSelectedAttributeType] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState(
    new Set(product.product_attributes),
  );

  const [editingAttribute, setEditingAttribute] = useState(null);

  useEffect(() => {
    setSelectedAttributes(new Set(product.product_attributes));
  }, [product.product_attributes]);

  useEffect(() => {
    getAttributesOptions();
  }, []);

  const getAttributesOptions = async () => {
    try {
      const { data, status } = await axios.get('/api/product-attributes');
      if (status === 200) {
        setProductAttributeOptions(data.data);
      }
    } catch (error) {
      console.error(error, 'error');
    }
  };

  const handleAttributeTypeSelect = (newAttributeType) => {
    setSelectedAttributeType(newAttributeType);
    setSelectedAttribute(null);
  };

  const handleAttributeSelect = (newAttribute, isNew = false) => {
    setSelectedAttribute(newAttribute);
    setSelectedAttributeValue(null);
    if (isNew)
      setProductAttributeOptions((prevState) => {
        const newAttributeOption = {
          attribute_type: selectedAttributeType,
          attribute: newAttribute,
          value: null,
        };
        return [...prevState, newAttributeOption];
      });
  };

  const handleAttributeValueSelect = (newAttributeValue) => {
    setSelectedAttributeValue(newAttributeValue);
    handleAttributeSave(newAttributeValue);
  };

  const handleAttributeSave = async (newAttributeValue) => {
    setIsAccordionOpen(false);

    let newAttribute = productAttributeOptions.find(
      (option) =>
        option.attribute_type === selectedAttributeType &&
        option.attribute === selectedAttribute &&
        option.value === newAttributeValue,
    );

    if (!newAttribute) {
      newAttribute = {
        attribute_type: selectedAttributeType,
        attribute: selectedAttribute,
        value: newAttributeValue,
      };
      setProductAttributeOptions((prevState) => [...prevState, newAttribute]);
      try {
        const payload = {
          ...newAttribute,
        };
        const { data, status } = await axiosPrivate.post(
          '/api/product-attributes',
          payload,
        );
        // console.log('back from adding', data, status);
      } catch (error) {
        console.error('error', error);
      }
    }

    if (editingAttribute) {
      setSelectedAttributes((prevState) => {
        const newSet = new Set(prevState);
        prevState.delete(editingAttribute);
        prevState.add(newAttribute);
        return newSet;
      });
      setEditingAttribute(null);
    } else {
      setSelectedAttributes((prevState) => {
        const newSet = new Set(prevState);
        newSet.add(newAttribute);
        return newSet;
      });
    }

    setSelectedAttributeValue(null);
    setSelectedAttribute(null);
    setProduct((prevState) => {
      return {
        ...prevState,
        product_attributes: [...prevState.product_attributes, newAttribute],
      };
    });
  };

  const handleAttributeDelete = (attribute) => {
    setIsAccordionOpen(false);
    setSelectedAttributes((prevState) => {
      const newAttributes = new Set(prevState);
      newAttributes.delete(attribute);
      return newAttributes;
    });

    return;
    setProduct((prevState) => {
      return {
        ...prevState,
        product_attributes: prevState.product_attributes.filter((attribute) => {
          return true;
        }),
      };
    });
  };

  const handleChipSelect = (attribute) => {
    setEditingAttribute(attribute);
    setSelectedAttributeType(attribute.attribute_type);
    setSelectedAttribute(attribute.attribute);
    setSelectedAttributeValue(attribute.value);
    setIsAccordionOpen(true);
  };

  const handleNewAttributeConfirm = (
    selectedAttributeType,
    selectedAttribute,
    newAttributeValue,
  ) => {
    const newAttribute = {
      attribute_type: selectedAttributeType,
      attribute: selectedAttribute,
      value: newAttributeValue,
    };

    setSelectedAttributes((prevState) => prevState.add(newAttribute));

    setProductAttributeOptions((prevState) => [...prevState, newAttribute]);
    setIsAccordionOpen(false);
    // TODO: save onto product
  };

  return (
    <Box>
      <Typography variant="h6" textTransform="none" color="primary">
        Product Attributes
      </Typography>
      <Box mb={2}>
        {selectedAttributes.size
          ? selectedAttributes.map((selectedAttribute) => {
              return (
                <Chip
                  key={
                    selectedAttribute._id ||
                    `${selectedAttribute.attribute}-${selectedAttribute.attribute_type}`
                  }
                  label={`${formatAttribute(selectedAttribute.attribute)} - ${formatAttributeValue(selectedAttribute.value)}`}
                  variant="outlined"
                  clickable
                  onClick={() => handleChipSelect(selectedAttribute)}
                  onDelete={() => handleAttributeDelete(selectedAttribute)}
                  sx={{ ml: 2 }}
                />
              );
            })
          : null}
      </Box>
      <Accordion
        expanded={isAccordionOpen}
        sx={{
          backgroundColor: 'tertiary.backgroundColor',
          borderRadius: '5px',
          boxShadow: 6,
        }}
      >
        <AccordionSummary
          sx={{
            '.MuiAccordionSummary-content': {
              margin: 0,
            },
            '&.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '48px',
            },
          }}
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        >
          <AddIcon color="primary" />
          <Typography
            textTransform="none"
            p={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
            color="primary"
            fontSize="1.2rem"
          >
            Add a Product Attribute
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            alignItems="stretch"
            justifyContent="space-evenly"
            gap={2}
          >
            <AttributeTypeBox
              options={productAttributeOptions}
              selectedAttributeType={selectedAttributeType}
              handleAttributeTypeSelect={handleAttributeTypeSelect}
            />
            <AttributeBox
              options={productAttributeOptions}
              selectedAttributeType={selectedAttributeType}
              handleAttributeSelect={handleAttributeSelect}
              selectedAttribute={selectedAttribute}
              selectedAttributes={selectedAttributes}
            />
            <AttributeValueBox
              options={productAttributeOptions}
              selectedAttributeType={selectedAttributeType}
              selectedAttribute={selectedAttribute}
              handleAttributeValueSelect={handleAttributeValueSelect}
              selectedAttributeValue={selectedAttributeValue}
              handleNewAttributeConfirm={handleNewAttributeConfirm}
            />
            <Box alignSelf="center"></Box>
          </Box>
        </AccordionDetails>
        {/* <AccordionActions>
          <Button
            variant="contained"
            onClick={() => setIsAccordionOpen(false)}
            color="error"
          >
            Cancel
          </Button>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={handleAttributeSave}
            disabled={!selectedAttributeValue}
          >
            Save
          </Button>
        </AccordionActions> */}
      </Accordion>
    </Box>
  );
};

export default AttributeContainer;
