import { Paper, Typography, Grid, Button } from '@mui/material';
import LinearProgressWithLabel from '../formik/LinearProgressWithLabel';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import formatString from '../../hooks/useStringFormat';

const ProfileCompletionStatusItem = ({
  message,
  value,
  cta,
  missingFields,
  type,
}) => {
  const { t } = useTranslation();
  const { t: transFields } = useTranslation('fields');
  if (!message || !value || !cta) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      container
      sx={{ marginBottom: '2rem', position: 'relative' }}
    >
      <Paper
        elevation={20}
        sx={{
          background: 'transparent',
          padding: '1rem',
          width: '100%',
          textAlign: 'left',
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" align="left">
              {message}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs>
              <LinearProgressWithLabel value={value} />
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={cta}>
                {t('buttons.complete')}
              </Button>
            </Grid>
          </Grid>
          {missingFields && (
            <Grid container item alignItems="center">
              <Grid>
                <Typography
                  variant="h6"
                  color="text.primary"
                  textTransform="none"
                >
                  {transFields('profile.missing_fields')}
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                {missingFields.map((field) => {
                  return (
                    <Grid item key={field}>
                      <Typography variant="body1" color="text.secondary">
                        {type === 'company' && field === 'profile_picture'
                          ? transFields(`profile.company_${field}`)
                          : i18n.language === 'en' && field !== 'dob'
                            ? formatString(field)
                            : transFields(`profile.${field}`)}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ProfileCompletionStatusItem;
