import { useContext, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import StyledBadge from './common/StyledBadge';
import { UserProfileContext } from '../../context/providers/UserProfileProvider';
import useAuth from '../../hooks/useAuth';

const ChatRoom = ({
  senders,
  selectedSender,
  conversationOptions,
  handleConversationChange,
  dropdownValue,
  showingConversation,
  handleSend,
}) => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const { userProfile } = useContext(UserProfileContext);
  const { auth } = useAuth();
  const boxRef = useRef(null);

  const [message, setMessage] = useState('');

  const scrollToBottom = () => {
    boxRef.current.scrollTop = boxRef.current.scrollHeight;
  };

  useEffect(() => {
    if (showingConversation) {
      scrollToBottom();
    }
  }, [showingConversation]);

  const getImage = (selectedSender) => {
    const sender = senders.get(selectedSender);
    let img = '';
    if (sender.profile_picture && sender.profile_picture.length) {
      const [profile_picture] = sender.profile_picture;
      img = `data:${profile_picture.mimetype};base64,${profile_picture.buffer.toString('base64')}`;
    }
    return img;
  };

  const onSend = (message, showingConversation) => {
    handleSend(message, showingConversation);
    setMessage('');
  };

  return (
    <Box
      flex={1}
      borderRadius={2}
      boxShadow={6}
      p={2}
      display="flex"
      flexDirection="column"
    >
      {selectedSender ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Box display="flex">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <Avatar src={getImage(selectedSender)} alt="profile picture">
                <FaceIcon sx={{ color: 'primary.main' }} />
              </Avatar>
            </StyledBadge>
            <Box ml={2}>
              <Typography variant="body1">{selectedSender}</Typography>
              <Typography variant="body2">{t('pages.chat.offline')}</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button variant="outlined">
              {transButtons('view', {
                type: transTypes('applications'),
              })}
            </Button>
          </Box>
        </Box>
      ) : null}
      {conversationOptions.length ? (
        <>
          <InputLabel id="conversation-select">
            {t('pages.chat.select')}
          </InputLabel>
          <Select
            labelId="conversation-select"
            onChange={handleConversationChange}
            label={'select a conversation'}
            name="conversation"
            value={dropdownValue}
            // defaultValue={dropdownValue}
          >
            {conversationOptions.map((conversation) => (
              <MenuItem
                key={conversation.chat_id}
                value={conversation.chat_title}
              >
                {conversation.chat_title}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <Typography align="center" variant="body1">
          {t('pages.chat.description')}
        </Typography>
      )}
      {showingConversation ? (
        <>
          <Box
            mt={2}
            flex={1}
            display="flex"
            flexDirection="column"
            sx={{ overflowY: 'scroll' }}
            ref={boxRef}
          >
            <Box>
              {showingConversation.messages.map((message) => {
                const isUser =
                  auth.user_info.account_type === 'manufacturer'
                    ? auth.user_info.company === message.sender_id
                    : auth.user_info._id === message.sender_id;

                const sender = senders.get(showingConversation.sender.name);
                let otherUserImage = '';
                if (sender.profile_picture && sender.profile_picture.length) {
                  const [profile_picture] = sender.profile_picture;
                  otherUserImage = `data:${profile_picture.mimetype};base64,${profile_picture.buffer.toString('base64')}`;
                }
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={isUser ? 'row-reverse' : 'row'}
                    my={1}
                    key={message.created_at}
                  >
                    <Avatar
                      src={
                        isUser
                          ? userProfile.profile_picture_view_url
                          : otherUserImage
                      }
                      alt="profile picture"
                    >
                      <FaceIcon sx={{ color: 'primary.main' }} />
                    </Avatar>
                    <Box
                      bgcolor={
                        isUser ? 'primary.main' : 'tertiary.backgroundColor'
                      }
                      color={
                        isUser ? 'tertiary.backgroundColor' : 'primary.main'
                      }
                      borderRadius={2}
                      boxShadow={6}
                      p={2}
                      mx={2}
                    >
                      {message.message}
                    </Box>
                    <Typography variant="body1">
                      {dayjs(message.created_at).format('h:mm A')}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box mt={2}>
            <TextField
              type="text"
              label="Type a message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && message) {
                  onSend(message, showingConversation);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => onSend(message, showingConversation)}
                      disabled={!message}
                    >
                      {transButtons('send')}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default ChatRoom;
