import { useState, useEffect } from 'react';
import { toTitleCase } from '../../../helpers/toTitleCase.js';
import { Link, useNavigate } from 'react-router-dom';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate.js';
import useAuth from '../../../hooks/useAuth.js';
import {
  Card,
  Grid,
  CardContent,
  CardMedia,
  Typography,
  Skeleton,
  Box,
  CardActionArea,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler.js';
import { useTranslation } from 'react-i18next';

const BlogList = () => {
  const axios = useAxiosPrivate();
  const { isLoading, setIsLoading } = useProcessingHandler();
  const { auth } = useAuth();
  const [posts, setPosts] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsLoading({
      status: true,
      type: 'skeleton',
      text: t('form.message.fetch_posts'),
    });

    // Get all posts from database
    const getPosts = async () => {
      const url = '/api/blog/posts/public/list';

      try {
        const response = await axios.get(
          url,
          { signal },
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );

        const { status, data } = response;

        if (status === 200) {
          setPosts(data.data);
        }
      } catch (error) {
        console.error('error', error);
      } finally {
        setIsLoading({ status: false, type: '', text: '' });
        controller.abort(signal);
      }
    };
    getPosts();
  }, []);

  async function newPost() {
    if (auth?.auth_info) {
      navigate('/app/blog/create');
    }
  }

  const BlogContent = () => {
    return (
      <>
        {posts.length > 0 ? (
          posts.map((post) => {
            return (
              <Grid
                key={post._id}
                item
                xs={12}
                md={3}
                style={{ display: 'flex', justifyContent: 'center' }}
                mb={4}
              >
                <Card
                  sx={{
                    width: 350,
                    maxWidth: 345,
                    border: 'none',
                    boxshadow: 'none',
                  }}
                >
                  <CardActionArea
                    component={Link}
                    to={`/blog/${post.link}`}
                    relative="path"
                  >
                    {post?.image_url > '' && (
                      <CardMedia
                        sx={{ height: 140 }}
                        image={post?.image_url}
                        title={post?.title}
                      />
                    )}
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="text.secondary"
                      >
                        {toTitleCase(post?.title)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {post?.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Grid
            key={999}
            item
            container
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'stretch', md: 'center' }}
            alignItems="center"
          >
            <Grid container item xs={12} direction="column" textAlign="center">
              <Typography variant="h5">
                {t('context.blog.blog.none_title')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="column"
              alignItems="center"
              textAlign="center"
            >
              <Card
                sx={{
                  width: { xs: '100%', md: '30%' },
                  maxWidth: { xs: '100%', md: '50%' },
                }}
              >
                <CardActionArea onClick={() => newPost()}>
                  <CardContent>
                    <Typography variant="h5">
                      {t('context.blog.blog.none_note')}
                    </Typography>
                    <AddIcon />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const LoadingContent = () => {
    const blog = [];
    for (let i = 0; i < 10; i++) {
      blog.push(
        <Grid key={i} item style={{ display: 'flex' }}>
          <Card
            sx={{
              width: 300,
              maxWidth: 345,
              border: 'none',
              boxshadow: 'none',
            }}
            variant="outlined"
          >
            <CardContent>
              <Skeleton variant="rectangular" width="100%" height={118} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton width="100%" />
                <Skeleton width="75%" />
                <Skeleton width="85%" />
              </Box>
            </CardContent>
          </Card>
        </Grid>,
      );
    }
    return blog;
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ minHeight: '500px' }}
      direction={{ xs: 'column', md: 'row' }}
    >
      {isLoading.status ? <LoadingContent /> : <BlogContent />}
    </Grid>
  );
};

export { BlogList };
