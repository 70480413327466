import React, { useContext } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import '../blog.css';
import { useTranslation } from 'react-i18next';
import IntuPlaceHolderBanner from '../../../assets/core/intuPlacerBanner.png';

import ImageIcon from '@mui/icons-material/Image';
import { PostContext } from '../../../context/providers/PostContextProvider';
import useAuth from '../../../hooks/useAuth';

const PostHeaderContainer = ({ content, ...props }) => {
  const { postInfo, postImage, handleEditPost, handlePostImageChange } =
    useContext(PostContext);
  const image = postImage.imageBlob;

  const { t } = useTranslation();
  const { auth, isAdmin } = useAuth();
  const values = content;

  return (
    <Grid className="intu__divider">
      <Grid
        item
        className="article"
        sx={{
          backgroundImage: `url(${image > '' ? image : IntuPlaceHolderBanner})`,
          minHeight: '600px',
        }}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          textAlign="center"
        >
          <Grid item>
            <Typography variant="h1" className="header" sx={{ color: 'white' }}>
              {postInfo.title}
            </Typography>
          </Grid>
          {/* Buttons in Edit Mode & View mode */}
          <Grid item>
            {postInfo?.inEditMode === true ? (
              <Button
                variant="contained"
                component="label"
                color="tertiary"
                endIcon={<ImageIcon />}
              >
                {t('buttons.change_img')}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handlePostImageChange(e, values)}
                  hidden
                />
              </Button>
            ) : isAdmin === true ||
              auth?.user_info?.full_name === postInfo?.author?.name ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleEditPost(true)}
              >
                {t('buttons.edit_post')}
              </Button>
            ) : null}
          </Grid>
        </Grid>

        <Grid
          container
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          sx={{
            minWidth: 300,
            width: 'auto',
            height: 'auto',
          }}
        ></Grid>
      </Grid>
    </Grid>
  );
};

export default PostHeaderContainer;
