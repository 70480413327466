import { PostContent } from '../../components/blog/PostContent';
import { Container } from '@mui/material';
import './blog.css';

import { useLocation } from 'react-router-dom';

const PostCreate = () => {
  const location = useLocation();
  const postInfo = location.state;
  const path = location.pathname;

  return (
    <Container maxWidth="auto" disableGutters>
      <Container component="main" maxWidth="xxl" className="intu__page">
        <PostContent content={postInfo} path={path} mode="createPost" />
      </Container>
    </Container>
  );
};

export default PostCreate;
