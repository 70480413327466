import { IntuDrawerHeader } from '../../../mui/appBar';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { NavBarDrawerContext } from '../../../context/providers/NavBarDrawerContextProvider';
import { useContext } from 'react';
import useAuth from '../../../hooks/useAuth';
import { UserProfileContext } from '../../../context/providers/UserProfileProvider';
import { useNavigate } from 'react-router';

const DrawerHeaderContent = () => {
  const theme = useTheme();
  const { auth } = useAuth();
  const { userProfile } = useContext(UserProfileContext);
  const { handleDrawerClose, drawerOpen } = useContext(NavBarDrawerContext);

  const navigate = useNavigate();

  return (
    <>
      <IntuDrawerHeader>
        <Grid container alignItems="center" alignContent="center">
          {auth?.auth_info ? (
            // Auth Header
            <>
              <Grid item xs={4}>
                <Tooltip title={`${userProfile.full_name}'s Profile`}>
                  <IconButton
                    onClick={() => {
                      navigate('/app/user/profile');
                      handleDrawerClose();
                    }}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={drawerOpen ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={drawerOpen ? 'true' : undefined}
                  >
                    {auth?.auth_info && (
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        src={userProfile.profile_picture_view_url}
                      >
                        <Typography variant="body1" sx={{ color: '#333333' }}>
                          {userProfile.initials}
                        </Typography>
                      </Avatar>
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{userProfile.full_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={handleDrawerClose}
                  sx={theme.components.IconButton}
                >
                  {theme.direction === 'ltr' ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </Grid>
            </>
          ) : (
            // Non Auth Header

            <Grid item xs={2}>
              <IconButton
                onClick={handleDrawerClose}
                sx={theme?.components?.IconButton}
              >
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </Grid>
          )}
        </Grid>
      </IntuDrawerHeader>
      <Divider sx={theme.components.DrawerDivider} />
    </>
  );
};

export { DrawerHeaderContent };
