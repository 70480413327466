import React from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { VideoPlayer } from '../../../../plugins/VideoPlayer';
import { Grid } from '@mui/material';

const videoPlugin = {
  Renderer: (props) => {
    return (
      <Grid
        sx={{
          minWidth: '300px',
          height: `${props.data.height}${props.data.unitHeight}`,
          padding: '20px',
        }}
        alignContent="center"
        alignItems="center"
      >
        <VideoPlayer
          videoWidth={`${props.data.width}${props.data.unitWidth}`}
          videoHeight={`${props.data.height}${props.data.unitHeight}`}
          videoSource={props.data.videoURL}
          blur={props.data.blur}
          controls={props.data.controls}
          muted={props.data.muted}
          playing={props.data.autoplay}
          loop={props.data.loop}
        />
      </Grid>
    );
  },
  id: 'ContentVideoPlugin',
  title: 'Video Player',
  description:
    'Playing a variety of URLs, including file paths, YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, DailyMotion and Kalturan',
  version: 1,
  icon: <PlayCircleIcon />,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        videoURL: {
          type: 'string',
          uniforms: {
            label: 'Video URL',
            placeholder: 'Enter the URL of your Video',
          },
        },
        controls: {
          type: 'boolean',
          default: true,
          uniforms: {
            label: 'Video Controls (Required with Vimeo',
          },
        },
        autoplay: {
          type: 'boolean',
          default: false,
          uniforms: {
            label: 'Autoplay',
          },
        },
        loop: {
          type: 'boolean',
          default: false,
          uniforms: {
            label: 'Video Loops',
          },
        },
        muted: {
          type: 'boolean',
          default: false,
          uniforms: {
            label: 'Video is muted',
          },
        },
        blur: {
          type: 'number',
          default: 0,
          uniforms: {
            label: 'Blur',
          },
        },
      },
    },
  },
};

export default videoPlugin;
