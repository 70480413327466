import { useState, useRef, useEffect, useContext } from 'react';
import useUser from '../../hooks/useUser.js';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsTracker.jsx';
import axios from '../../hooks/axios/useAxios.js';
import ReactHtmlParser from 'react-html-parser';
import ReCAPTCHA from 'react-google-recaptcha';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Materail UI
import { Grid, Stack, FormControl, Button } from '@mui/material';
import { classes } from '../../settings/theme.js';

// Country Dialing Code
import countries from '../../data/CountryData.json';

// Response & Loading Handlers
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';

// Translator
import { useTranslation } from 'react-i18next';
import IndividualFirstNameField from '../account/individual/IndividualFirstNameField.jsx';
import IndividualLastNameField from '../account/individual/IndividualLastNameField.jsx';
import EmailField from '../account/fields/email/EmailField.jsx';
import PhoneField from '../account/PhoneField .jsx';
import MessageFieldRegular from '../account/MessageFieldRegular.jsx';
import { ContactFormContext } from '../../pages/contact/ContactUs.jsx';
import BusinessNameTextField from '../account/business/BusinessNameTextField.jsx';

// FORM CONTENT
const ContactUsForm = () => {
  const { setIsSubmitted } = useContext(ContactFormContext);

  // Google Event Tracker
  const { gaEventTracker } = useAnalyticsEventTracker();

  // Translator
  const { t } = useTranslation();
  const { t: transMessage } = useTranslation('messages');

  // State & Context
  const [formStatus, setFormStatus] = useState(true);
  const [formStatusText, setFormStatusText] = useState('');
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    message: '',
    phone: {
      value: '',
      info: {
        countryCallingCode: null,
        countryCode: null,
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    },
    ip: '',
    sessionID: '',
    captcha: '',
  });

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { isLoading, setIsLoading } = useProcessingHandler();

  // User Information
  const { user } = useUser();

  // Setup Initial Values for Form Validation
  var validationSchema = Yup.object();
  var initialValues = {
    ...userInfo,
  };

  useEffect(() => {
    let userPhone = userInfo.phone;

    if (user.location.country) {
      for (let i = 0; i < countries.length; i++) {
        const element = countries[i];

        if (element.code === user.location.country) {
          userPhone = {
            value: `+${element.dialCode}`,
            info: {
              countryCallingCode: `+${element.dialCode}`,
              countryCode: element.code,
              nationalNumber: null,
              numberType: null,
              numberValue: null,
              reason: 'change',
            },
          };
        }
      }
    }

    setUserInfo({
      ...userInfo,
      phone: userPhone,
      ip: user.location.ip,
      sessionID: user.sessionID,
    });
  }, [user.location]);

  // Validation Schema
  validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        t('validation.name.first_matches'),
      )
      .required(t('validation.name.first_required')),
    last_name: Yup.string()
      .min(2, t('validation.short'))
      .max(50, t('validation.long'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
        t('validation.name.last_matches'),
      )
      .required(t('validation.name.last_required')),
    email: Yup.string()
      .required(t('validation.email.contact_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('validation.email.invalid'),
      ),
    phone: Yup.object({
      value: Yup.string().required(t('validation.phone.required_alt')),
      info: Yup.object({
        countryCallingCode: Yup.number(
          t('validation.phone.country_code.invalid'),
        )
          .required(t('validation.phone.country_code.required'))
          .typeError(t('validation.phone.country_code.error')),
        nationalNumber: Yup.string()
          .required(t('validation.phone.required'))
          .min(8, t('validation.phone.min'))
          .max(15, t('validation.phone.max')),
      }),
    }),
    captcha: Yup.string().required(t('validation.captcha_required')),
    message: Yup.string()
      .required(t('validation.message_required'))
      .min(8, t('validation.message_min')),
  });

  // Set Up From refs
  const formRef = useRef(null);

  //handle form submission process
  async function submitForm(values, actions) {
    const first_name = values.first_name;
    const last_name = values.last_name;
    const phone = values.phone;
    const company = values.company;
    const email = values.email;
    const message = values.message;
    const ip = values.ip;

    const url = `/api/contacts/request`;
    const payload = {
      first_name,
      last_name,
      email,
      phone,
      company,
      message,
      ip,
    };
    try {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: t('form.message.send_message'),
      });
      const response = await axios.post(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const { status, data } = response;
      //Handle Response
      if (status === 200) {
        //message sent
        actions.resetForm();
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
        gaEventTracker({
          category: 'General',
          action: 'contact_request',
          label: 'New Contact Request',
          value: 5,
        });
        setFormStatus(false);
        setFormStatusText(
          transMessage('ContactUsForm.signup_confirm_title', {
            first_name: first_name,
            email: email,
          }),
        );
        setIsSubmitted(true);
        window.scrollTo(0, 0);
      } else {
        // all other responses
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  return (
    <Grid>
      {formStatus === true ? (
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => submitForm(values, actions)}
            validateOnMount={true}
            validateOnChange={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {(formik) => {
              const { setFieldValue, handleSubmit, handleBlur, isValid } =
                formik;
              return (
                <Form
                  className="form-horizontal"
                  role="form"
                  onSubmit={handleSubmit}
                >
                  <FormControl fullWidth>
                    <Stack spacing={4} sx={{ paddingBottom: '50px' }}>
                      <Stack
                        spacing={1}
                        direction={{ xs: 'column', sm: 'row' }}
                        style={classes.root}
                      >
                        {/* First & Last Name */}
                        <IndividualFirstNameField
                          required
                          fieldID="first_name"
                        />

                        <IndividualLastNameField required fieldID="last_name" />
                      </Stack>

                      {/* Business Name */}
                      <BusinessNameTextField />

                      {/* Email */}
                      <EmailField transTypeID="your" required />

                      {/* Phone Number */}
                      <PhoneField fieldID="phone" transTypeID="your" required />

                      {/* Message */}
                      <MessageFieldRegular />
                    </Stack>

                    {/* CTA */}
                    <Stack
                      spacing={4}
                      direction="column"
                      style={classes.root}
                      sx={{ paddingBottom: '50px' }}
                    >
                      {/* CAPTCHA */}
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                        onChange={(token) => {
                          setFieldValue('captcha', token);
                        }}
                      />
                      {/* Submit Button */}

                      <Button
                        variant="contained"
                        type="submit"
                        disabled={!isValid}
                      >
                        {t('buttons.submit_message')}
                      </Button>
                    </Stack>
                  </FormControl>
                  {/* <Typography variant="h1">Initial Values:</Typography>
                  {JSON.stringify(formik.initialValues)}

                  <Typography variant="h1">Values:</Typography>
                  {JSON.stringify(formik.values)}

                  <Typography variant="h1">Touched:</Typography>
                  {JSON.stringify(formik.touched)}

                  <Typography variant="h1">Errors:</Typography>
                  {JSON.stringify(formik.errors)}

                  <Typography variant="h1">Valid:</Typography>
                  {JSON.stringify(formik.isValid)}

                  <Typography variant="h1">User Info</Typography>
                  {JSON.stringify(userInfo)}

                  <Typography variant="h1">Countries</Typography>
                  {JSON.stringify(countries)} */}
                </Form>
              );
            }}
          </Formik>
        </Grid>
      ) : (
        <Grid container sx={{ minHeight: '500px' }}>
          <Grid item xs={12}>
            {ReactHtmlParser(formStatusText)}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ContactUsForm;
