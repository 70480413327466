// Handles Loading State, and supports either 'spinner' or 'skeleton'

import React, { createContext, useState } from 'react';

const ProcessingContext = createContext({});

export const ProcessingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState({
    status: false,
    text: '',
    type: '',
    callback: null,
  });

  return (
    <ProcessingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </ProcessingContext.Provider>
  );
};

export default ProcessingContext;
