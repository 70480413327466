import {
  DataGrid,
  GridToolbar,
  GridFooterContainer,
  GridFooter,
} from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import { useFormikContext } from 'formik';
import { createContext, memo, useContext, useEffect, useState } from 'react';
import { CompanyProfileContext } from '../../../../context/providers/CompanyProfileProvider';
import { Button, Fade, IconButton, Skeleton, Typography } from '@mui/material';
import IntuThemeContext from '../../../../context/providers/IntuThemeProvider';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../../hooks/useWindowDimension';
import { EmployeeDetailsDialog } from './EmployeeDetailsDialog';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import IntuIconButton from '../../../buttons/IntuIconButton';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from 'immutable';
import { useTheme } from '@emotion/react';

export const CompanyEmployeesListContext = createContext();

const CompanyEmployeesList = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  transition = true, // Whether the field is being shown and faded in
}) => {
  // Formik Context
  const { values, setValues, errors, touched } = useFormikContext();

  const theme = useTheme();

  const { isLoading } = useContext(ProcessingContext);
  const { setEmployeeDetails } = useContext(CompanyProfileContext);
  const { transitionInterval } = useContext(IntuThemeContext);
  const [action, setAction] = useState('');

  // Translator
  const { t } = useTranslation('fields');
  const { t: transDialogs } = useTranslation('dialogs');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const { companyProfile } = useContext(CompanyProfileContext);

  //   Employee Details Dialog
  const [employeeDialog, setEmployeeDialog] = useState({
    open: false,
    type: null,
  });

  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  // Lead actvivity list
  const employeeColumns = [
    {
      field: 'name',
      headerName: t('global.label.name'),
      editable: false,
      minWidth: 100,
      flex: 0.4,
    },
    {
      field: 'email',
      headerName: t('global.label.email'),
      editable: false,
      minWidth: 100,
      flex: 0.3,
    },
    {
      field: 'role',
      headerName: t('global.label.role'),
      editable: false,
      sortable: false,
    },
    {
      field: 'edit',
      headerName: t('global.label.edit'),
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          let contactContent = params.row;
          setEmployeeDetails(contactContent);
          setTimeout(
            setEmployeeDialog({
              open: true,
              action: 'edit',
            }),
            '10000',
          );
        };
        return (
          <>
            <IconButton
              sx={{
                // borderRadius: '50%',
                // color: theme.palette.tertiary.contrastText,
                // backgroundColor: theme.palette.tertiary.main,
                borderRadius: '50%',
                border: '1px solid',
                borderColor: theme.palette.edit.main,
                // backgroundColor: theme.palette.edit.main,
                marginRight: '0.5rem',
              }}
              onClick={onClick}
            >
              <EditIcon
                fontSize="small"
                sx={{ color: theme.palette.edit.main }}
              />
            </IconButton>

            {/* <IconButton
              variant="contained"
              color="tertiary"
              sx={{
                borderRadius: '50%',
                border: '1px solid',
                borderColor: theme.palette.error.main,
              }}
              onClick={onClick}
            >
              <CloseIcon
                fontSize="small"
                sx={{ color: theme.palette.error.main }}
              />
            </IconButton> */}
          </>
        );
      },
    },
  ];

  const [mobileView, setMobileView] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 767) {
      setMobileView(false);
    } else if (width < 767) {
      setMobileView(true);
    }
  }, [width]);

  const handleAddUserOnClick = () => {
    setTimeout(
      setEmployeeDetails({
        _id: '',
        email: '',
        name: '',
        role: '',
      }),
      setEmployeeDialog({
        open: true,
        action: 'add',
      }),
      '10000',
    );
  };

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <IntuIconButton
          type="add"
          tooltipTitle={transButtons('add', { type: transTypes('employee') })}
          onClick={handleAddUserOnClick}
        />
        <GridFooter
          sx={{
            border: 'none', // To delete double border.
          }}
        />
      </GridFooterContainer>
    );
  };

  return (
    companyProfile.isAdmin &&
    (isLoading.status ? (
      <Skeleton variant="rectangular" width={'100%'} height={60} />
    ) : (
      <CompanyEmployeesListContext.Provider
        value={{
          employeeDialog,
          setEmployeeDialog,
        }}
      >
        {fieldTitle && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
              {fieldTitle}
            </Typography>
          </Fade>
        )}
        {fieldDescription && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography
              className="form-note"
              textAlign={{ xs: 'center', md: 'left' }}
            >
              {fieldDescription}
            </Typography>
          </Fade>
        )}
        <DataGrid
          isCellEditable={(params) => false}
          isReadOnly={true}
          getRowId={(row) => row._id}
          rows={companyProfile.employees}
          columns={employeeColumns}
          columnVisibilityModel={{
            email: !mobileView,
            role: !mobileView,
          }}
          components={{
            Row: MemoizedRow,
            ColumnHeaders: MemoizedColumnHeaders,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                first_name: !mobileView,
              },
            },
          }}
          sx={{
            '&::-webkit-scrollbar': {
              width: 20,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'orange',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'red',
              borderRadius: 2,
            },
          }}
          pageSizeOptions={[5, 10, 100]}
          // checkboxSelection={!mobileView}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
            footer: CustomFooter,
          }}
        />
        <EmployeeDetailsDialog action={action} />
      </CompanyEmployeesListContext.Provider>
    ))
  );
};

export default CompanyEmployeesList;
