import React from 'react';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import { Chip, Divider, Grid, MenuItem, Select } from '@mui/material';

// Intu Page Editor Components
import { ColorPickerField } from '@in2tec/page-editor';
import { connectField } from 'uniforms';

const variantMapping = {
  'Full Width': 'fullWidth',
  Inset: 'inset',
  Middle: 'middle',
};

const reverseVariantMapping = Object.fromEntries(
  Object.entries(variantMapping).map(([key, value]) => [value, key]),
);

const DividerSettings = connectField(
  ({ onChange, value, showInlineError, ...props }) => (
    <Grid container sx={{ marginBottom: '20px' }}>
      <Select
        {...props}
        value={reverseVariantMapping[value]}
        variant="standard"
        sx={{ minWidth: '200px' }}
        onChange={(event) => onChange(variantMapping[event.target.value])}
      >
        {Object.keys(variantMapping).map((key) => (
          <MenuItem key={key} value={key}>
            {key}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  ),
);

const dividerPlugin = {
  Renderer: (props) => {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          minHeight: '20px',
          padding: `${props.data.paddingTop}px ${props.data.paddingRight}px ${props.data.paddingBottom}px ${props.data.paddingLeft}px`,
        }}
      >
        <Grid item xs>
          {props.data.label === true ? (
            <Divider
              sx={{ bgcolor: props.data.color }}
              flexItem
              textAlign={props.data.labelTextAlign}
              variant={props.data.variant}
            >
              {props.data.decor === true ? (
                <Chip label={props.data.labelText} />
              ) : (
                props.data.labelText
              )}
            </Divider>
          ) : (
            <Divider
              sx={{ bgcolor: props.data.color }}
              flexItem
              variant={props.data.variant}
            />
          )}
        </Grid>
      </Grid>
    );
  },
  id: 'DividerPlugin',
  title: 'Divider',
  description: 'Implement a Divider',
  version: 1,
  icon: <HorizontalRuleIcon />,
  controls: [
    {
      title: 'Divider',
      controls: {
        type: 'autoform',
        columnCount: 1,
        schema: {
          properties: {
            variant: {
              type: 'string',
              default: 'fullWidth',
              enum: Object.values(variantMapping), // Use the technical values
              uniforms: {
                component: DividerSettings, // Custom component if needed
                transform: (value) => reverseVariantMapping[value], // Transform to user-friendly name for display
                allowedValues: Object.keys(reverseVariantMapping), // User-friendly names for options
              },
            },
            color: {
              type: 'string',
              default: '#a8c957',
              uniforms: {
                component: ColorPickerField,
              },
            },
            label: {
              type: 'boolean',
              default: false,
              uniforms: {
                label: 'Label',
              },
            },
            labelText: {
              type: 'string',
              uniforms: {
                label: 'Label Text', // custom label
                showIf: (data) => data.label === true, // show the field conditionally
              },
            },
            labelTextAlign: {
              type: 'string',
              default: 'center',
              enum: ['left', 'right', 'center'],
              uniforms: {
                label: 'Label Text', // custom label
                showIf: (data) => data.label === true, // show the field conditionally
              },
            },
            decor: {
              type: 'boolean',
              default: false,
              uniforms: {
                label: 'Decoration',
                showIf: (data) => data.label === true, // show the field conditionally
              },
            },
          },
        },
      },
    },
    {
      title: 'Padding',
      controls: {
        type: 'autoform',
        columnCount: 2,
        schema: {
          properties: {
            paddingTop: {
              type: 'integer',
              default: 20,
              uniforms: {
                label: 'Top (px)',
              },
            },
            paddingBottom: {
              type: 'integer',
              default: 20,
              uniforms: {
                label: 'Bottom (px)',
              },
            },
            paddingLeft: {
              type: 'integer',
              default: 0,
              uniforms: {
                label: 'Left (px)',
              },
            },
            paddingRight: {
              type: 'integer',
              default: 0,
              uniforms: {
                label: 'Right (px)',
              },
            },
          },
        },
      },
    },
  ],
};

export default dividerPlugin;
