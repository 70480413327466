import { useContext } from "react";
import { Grid, Button, Typography, Box, Skeleton, useTheme } from "@mui/material";
import { BarChartContext } from "../../charts/barChart";
import IntuThemeContext from "../../context/providers/IntuThemeProvider";
import { useTranslation } from "react-i18next";

export const ProfileAnalyticsChart = ({
    activeChartData,
    setActiveChartData,
    isChartLoading,
    chartSettings,
    mobileView,
    data1,
    data2,
    data3,
    type
}) => {
    const theme = useTheme();
    const { mode } = useContext(IntuThemeContext);
    const { t } = useTranslation('translation', { keyPrefix: 'components.ProfileAnalyticsChart' })

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.profile[mode],
                borderRadius: '16px',
            }}
        >
            <Grid container justifyContent='space-between'>
                <Grid item ml={2} mt={type === 'sales' ? 2 : 0}>
                    <Typography variant="h6">{t('analytics')}</Typography>
                </Grid>
                <Grid item mt={type === 'sales' ? 2 : 0}>
                    <Button
                        onClick={() => { setActiveChartData('order') }}
                        style={{
                            backgroundColor: activeChartData === 'order' ? theme.palette.primary[mode] : '',
                            color: mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
                        }}
                    >
                        {t("orders")}
                    </Button>
                    <Button
                        onClick={() => { setActiveChartData('offer') }}
                        style={{
                            backgroundColor: activeChartData === 'offer' ? theme.palette.primary[mode] : '',
                            color: mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
                        }}
                    >
                        {t("offers")}
                    </Button>
                    {type === 'manufacturer' &&
                        <Button
                            onClick={() => { setActiveChartData('opportunity') }}
                            style={{
                                backgroundColor: activeChartData === 'opportunity' ? theme.palette.primary[mode] : '',
                                color: mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
                            }}
                        >
                            {t("opportunities")}
                        </Button>
                    }
                </Grid>
            </Grid>

            <Grid item xs={12}>
                {isChartLoading ?
                    <Skeleton />
                    :
                    <BarChartContext
                        chartId={'test2'}
                        data={
                            activeChartData === 'order' ? data1
                                : activeChartData === 'offer' ? data2
                                    : data3
                        }
                        settings={chartSettings}
                        mobileView={mobileView}
                    />
                }
            </Grid>
        </Box>
    )
}