import axios from '../hooks/axios/useAxios';
import { axiosPrivate } from '../hooks/axios/axios';
import i18n from '../i18n.js';

// Get Idnustries
async function getIndustries(showAll = false) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/industries/list';

  try {
    let params = {
      showall: showAll,
    };

    let request = await axios.get(url, {
      signal,
      params,
    });

    let response = request?.data;

    if (request?.status) {
      return response;
    }
  } catch (err) {
    return err;
  }
}

// Search Industries
async function searchIndustries(industries) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/industries/search';

  if (industries.length === 0) {
    return null;
  }

  try {
    let payload = {
      key: '_id',
      values: industries,
    };
    let request = await axios.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    let response = request?.data;
    // console.log(response)

    if (request?.status) {
      // console.log(JSON.stringify(response))
      return response.data;
    }
  } catch (err) {
    return err;
  }
}

// Add applications to database
async function addIndustries(industriesList) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/industries/add';

  let req_fields = [];
  !industriesList &&
    req_fields.push(i18n.t('types:new', { type: i18n.t('types:industries') }));
  !Array.isArray(industriesList) &&
    req_fields.push(
      i18n.t('validations:req_data_type', {
        dataType: i18n.t('types:data.array'),
      }),
    );

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validations:missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const payload = {
      data: industriesList,
    };

    const response = await axiosPrivate.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    const { data, status } = response;

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

export { getIndustries, searchIndustries, addIndustries };
