import * as React from 'react';
import { useContext } from 'react';

// Formik
import { useFormikContext } from 'formik';

// MUI Compoents
import {
  GridToolbar,
  GridRow,
  GridColumnHeaders,
} from '@mui/x-data-grid-premium';

import { Button, Typography, Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { ManageLeadContext } from '../../../context/providers/leads/ManageLeadContextProvider.jsx';
import useIntuTheme from '../../../hooks/useIntuTheme.js';

const AssociateActivity = () => {
  const { values } = useFormikContext();

  // Get Context
  const MemoizedRow = React.memo(GridRow);
  const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

  const { setActivityDialog } = useContext(ManageLeadContext);
  const { mobileView } = useIntuTheme();

  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');

  // Associate Activity Table
  const associateActivityColumns = [
    {
      field: 'date',
      headerName: t('form.date'),
      editable: false,
      minWidth: 50,
    },
    {
      field: 'activity',
      headerName: t('form.activity.activity'),
      editable: false,
      minWidth: 50,
    },
    {
      field: 'user',
      headerName: t('form.user'),
      editable: false,
      minWidth: 50,
    },
    {
      field: 'comment',
      headerName: t('form.comment.label'),
      editable: false,
      flex: 0.3,
      minWidth: 200,
    },
    {
      field: 'edit',
      headerName: transButtons('edit'),
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          let activityContent = params.row;
          setActivityDialog({
            openDialog: true,
            leadID: values._id,
            activityDialogTitle: t('context.admin.dialog.edit_activity_title'),
            activityDialogText: t('context.admin.dialog.edit_activity_text'),
            activityPrimaryButtonText: transButtons('update'),
            activitySecondaryButtonText: transButtons('close'),
            activityDialogAction: 'update',
            activityLog: values.associate_activity,
            activityContent,
          });
        };

        return (
          <Button variant="contained" color="tertiary" onClick={onClick}>
            {transButtons('edit')}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Typography variant="h5">
        {t('context.admin.intutecLeads.associate_activity')}
      </Typography>

      {/* Associate Activities Columns */}
      <Box>
        <DataGrid
          isCellEditable={(params) => false}
          getRowId={(row) => row._id}
          rows={values.associate_activity}
          columns={associateActivityColumns}
          components={{
            Row: MemoizedRow,
            ColumnHeaders: MemoizedColumnHeaders,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          sx={{
            '&::-webkit-scrollbar': {
              width: 20,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'orange',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'red',
              borderRadius: 2,
            },
          }}
          pageSizeOptions={[5, 10, 100]}
          checkboxSelection={!mobileView}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </>
  );
};

export { AssociateActivity };
