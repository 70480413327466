// Provider for OTP Authentication e.g.
// - Events,
// - User Login

import React, { createContext, useState } from 'react';

export const TwoFactorVerificationContext = createContext({});

export const TwoFactorVerificationProvider = ({ children }) => {
  // States
  const [otpSent, setOTPSent] = useState(false);
  const [isSendingOTP, setIsOTPSending] = useState(false);
  const [isConfirmingOTP, setIsConfirmingOTP] = useState(false);
  const [twoFactorVerificationDialog, setTwoFactorVerificationDialog] =
    useState(false);

  return (
    <TwoFactorVerificationContext.Provider
      value={{
        isSendingOTP,
        setIsOTPSending,
        otpSent,
        setOTPSent,
        isConfirmingOTP,
        setIsConfirmingOTP,
        twoFactorVerificationDialog,
        setTwoFactorVerificationDialog,
      }}
    >
      {children}
    </TwoFactorVerificationContext.Provider>
  );
};
