import {
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import './hubSpotMeeting.css';

const HubSpotMeeting = () => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;

    // Append script to the body
    document.body.appendChild(script);

    // Clean up
    return () => {
      // Check if the script is still a child of document.body before removing
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    const iframe = document.querySelector('.meetings-iframe-container iframe');
    if (iframe) {
      const handleMessage = (event) => {
        // Check origin for security
        if (event.origin !== 'https://meetings.hubspot.com') {
          return;
        }

        // Example: Change button color upon receiving message
        const button = iframe.contentDocument.querySelector(
          '.private-button--primary',
        );
        console.log(button);
        if (button) {
          button.style.backgroundColor = 'red';
        }
      };

      // Add event listener for messages from iframe
      window.addEventListener('message', handleMessage);

      // Cleanup
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
  }, []);

  return (
    <Grid
      container
      alignItems="flex-start"
      direction="row"
      justifyContent="space-between"
    >
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h1">
            {t('components.hubSpotMeeting.title')}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: small ? '20vh' : '0' }}>
          <Container
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/mkrostewitz/learn-more-about-in2tec?embed=true"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HubSpotMeeting;
