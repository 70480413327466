import MUIRichTextEditor from 'mui-rte';
import { makeStyles, useTheme } from '@mui/styles';
import { FormControl, FormHelperText, Skeleton } from '@mui/material';
import { useFormikContext } from 'formik';
import { convertToRaw } from 'draft-js';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import { useTranslation } from 'react-i18next';
import FormikFieldWrapper from '../..//formik/FormikFieldWrapper';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { useContext } from 'react';
import { getMuiRichTextEditorContent } from '../../../helpers/muiRichtTextEditor';

const intuGreen = '#A8C957';
const intuLightGrey = '#00000061';

const useStyles = makeStyles(() => ({
  editorContainer: {
    border: `1px solid ${intuGreen}`,
    borderRadius: '0.375rem',
    padding: '0.5em 1em',
  },
  editorContainerDisabled: {
    border: `1px solid ${intuLightGrey}`,
    borderRadius: '0.375rem',
    padding: '0.5em 1em',
  },
  editor: {
    minHeight: '4em',
    marginTop: '0.5em',
  },
  toolbar: {
    borderBottom: `1px solid ${intuLightGrey}`,
  },
}));

const RichTextEditorField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  defaultValue, // override the default value
  toolbar = true, // Rich Text Editor Toolbar tru / false,
  controls = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'highlight',
    'undo',
    'redo',
    'link',
    'media',
    'numberList',
    'bulletList',
    'quote',
    'code',
    'clear',
    'save',
  ],
  minRows = 10, // Minimum Amoutn of rows
  fieldID = 'intro', // ID of the Field
  editorID = 'intro_rte', // ID of the Editor Field (Used to save the editor state)
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'RichTextEditorField', // KeyPrefix which holds label and placeholder
  type = 'content', // Translation Type Definition
  maxLength = null, // the maximum amount of Charachters
  error = false, // Whether the Field has an Error
  helperText = null, // the Error Text to display,
  onChange, // Override regular on change behaviot, make sure to adapt the components onChange behavior for proper error handling
  onBlur, // Cusomize the onBlur Event
  ...props
}) => {
  // Translations
  const { t: transTypes } = useTranslation('types');
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { setFieldValue, setFieldTouched, values, errors, touched } =
    useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  const classes = useStyles();
  const theme = useTheme();

  const editorValue = getNestedValue(values, editorID);
  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const handleEditorChange = async (e) => {
    const content = JSON.stringify(convertToRaw(await e.getCurrentContent()));
    setFieldValue(fieldID, content);
  };

  // Use the provided onChange if it exists, otherwise fallback to the default handleEditorChange
  const handleChange = onChange || handleEditorChange;

  // Set Default Editor State if no Content is provided
  if (!editorValue) {
    editorValue = JSON.stringify(getMuiRichTextEditorContent(' '));
  }
  // const defaultValue = JSON.stringify(getMuiRichTextEditorContent(' '))

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <FormControl>
        <MUIRichTextEditor
          {...props}
          id={fieldID}
          name={fieldID}
          aria-invalid={errors[fieldID] ? 'true' : 'false'}
          aria-describedby="uidnote"
          onChange={handleChange}
          onBlur={() => setFieldTouched(fieldID, true)}
          toolbarButtonSize="small"
          controls={controls}
          readOnly={disabled}
          toolbar={toolbar && !disabled}
          defaultValue={editorValue}
          error={fieldError && fieldTouched ? true : false}
          label={transFields('label', { type: transTypes(type) })}
          placeholder={transFields('placeholder', {
            type: transTypes(type),
          })}
          inputProps={{
            minRows: minRows,
          }}
          classes={{
            root: classes.editorContainer,
            editor: classes.editor,
          }}
        />
        {fieldError && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {fieldError}
          </FormHelperText>
        )}
      </FormControl>
    </FormikFieldWrapper>
  );
};

export { RichTextEditorField };
