import React, { useContext } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Skeleton, TextField } from '@mui/material';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import { toTitleCase } from '../../../helpers/toTitleCase';

const IndividualLastNameField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  fieldID = 'individual.last_name', // Defines the ID and Name of the Field
  variant = 'outlined', // MUI variant
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'account.individual.IndividualLastNameField', // KeyPrefix which holds label and placeholder
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  //   Formik Context
  const { values, touched, errors, handleBlur, setFieldValue } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return (
    <>
      {isLoading.status ? (
        <Skeleton variant="rectangular" width={'100%'} height={60} />
      ) : (
        <FormikFieldWrapper
          fieldTitle={fieldTitle}
          fieldDescription={fieldDescription}
          transition={transition}
        >
          <TextField
            required={required}
            disabled={disabled}
            id={fieldID}
            name={fieldID}
            className="form-select-field"
            aria-invalid={errors.last_name ? 'true' : 'false'}
            aria-describedby="uidnote"
            variant={variant}
            label={t('label')}
            placeholder={t('placeholder')}
            type="text"
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
            onBlurCapture={(e) => {
              const proper = toTitleCase(e.target.value);
              setFieldValue(e.target.name, proper);
              handleBlur(e);
            }}
            inputProps={{
              autoComplete: 'family-name',
            }}
            onBlur={handleBlur}
            value={fieldValue}
            error={fieldError && fieldTouched ? true : false}
            helperText={fieldError && fieldTouched ? fieldError : null}
          />
        </FormikFieldWrapper>
      )}
    </>
  );
};

export default IndividualLastNameField;
