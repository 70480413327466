import { useState, useEffect, memo } from 'react';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate.js';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard.jsx';
import { Link, useNavigate } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';

// Form Body
import { randomStr } from '../../../helpers/randomString.js';
import useAuth from '../../../hooks/useAuth.js';

import {
  GridToolbar,
  GridRow,
  GridColumnHeaders,
} from '@mui/x-data-grid-premium';
import {
  Typography,
  Skeleton,
  Box,
  Stack,
  Grid,
  Button,
  Avatar,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { classes } from '../../../settings/theme.js';

// Material Icons
import LinkIcon from '@mui/icons-material/Link';

// Response & Loading Handlers
import useResponseHandling from '../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler.js';

// Translator
import { useTranslation } from 'react-i18next';

// Routes
import { getPosts } from '../../../routes/blogRoutes.js';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';

const UserPostsList = () => {
  // State & Context
  const { auth } = useAuth();

  const [posts, setPosts] = useState();

  // SetUp Navigation
  const navigate = useNavigate();
  const host_url = window.location.host;

  // copy to clipboard
  const { copy } = useCopyToClipboard();

  // Axios
  const axios = useAxiosPrivate();

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { isLoading, setIsLoading } = useProcessingHandler();

  // Translator
  const { t } = useTranslation();

  // Posts List
  const postColumns = [
    {
      field: 'image',
      headerName: 'Image',
      sortable: false,
      editable: false,
      minWidth: 50,
      renderCell: (params) => {
        const postContent = params.row;
        const image = postContent?.image_url;

        return (
          <Avatar
            size="large"
            name="imageBlob"
            id="imageBlob"
            variant="square"
            src={image}
            imgProps={{ width: '100%', objectfit: 'fill' }}
          >
            <ImageIcon />
          </Avatar>
        );
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      editable: false,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      editable: false,
      flex: 1,
    },
    {
      field: 'author_label',
      headerName: 'Author',
      editable: false,
      minWidth: 50,
    },
    {
      field: 'created',
      headerName: 'Created',
      editable: false,
      minWidth: 50,
    },
    {
      field: 'status_label',
      headerName: 'Status',
      editable: false,
    },
    {
      field: 'share',
      headerName: 'Share',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const postContent = params.row;
        const full_url = `https://${host_url}/blog/${postContent.link}`;

        return (
          <IconButton
            color="primary"
            aria-label="share link"
            onClick={() => {
              copy(full_url);
            }}
          >
            <LinkIcon />
          </IconButton>
        );
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      renderCell: (params) => {
        const postContent = params.row;
        return (
          <IntuIconButton
            type="edit"
            tooltipTitle="edit_post"
            variant="outlined"
            color="secondary"
            component={Link}
            onClick={() => navigate(`/app/blog/edit/${postContent.link}`)}
            state={{ mode: 'editPost' }}
          />
        );
      },
    },
  ];

  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 767) {
        setMobileView(false);
      } else if (window.innerWidth < 767) {
        setMobileView(true);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Get all Posts from database
  async function handleGetPosts() {
    try {
      const postsList = await getPosts(auth);
      if (postsList.status === 'success') {
        setPosts(postsList.data);
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  // Pre-Load Info
  useEffect(() => {
    setIsLoading({ status: true, type: 'skeleton', text: '' });

    handleGetPosts().then(() => {
      setIsLoading({ status: false, type: '', text: '' });
    });
  }, []);

  // Create a Post
  async function createPost() {
    const controller = new AbortController();
    const { signal } = controller;
    const url = '/api/blog/posts/create';
    const link = randomStr(10, '1234567890abcdefghijklmnop');

    const full_url = `https://${host_url}/blog/${link}`;

    const payload = {
      link,
      full_url,
    };

    try {
      const response = await axios.post(url, payload, {
        signal,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const { status, data } = response;

      if (status === 200) {
        // Set Post Data
        navigate(`/app/blog/edit/${link}`, {
          state: {
            mode: 'createPost',
            link: link,
            id: data.id,
          },
        });
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      controller.abort();
    }
  }

  return (
    <Grid container className="content" mb="8rem" spacing={5}>
      <Grid item className="content" xs={12}>
        {isLoading?.status === true && isLoading?.type === 'skeleton' ? (
          <Stack direction="column" spacing={-1}>
            <Stack spacing={1} direction="row" height={50}>
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
            </Stack>
            <Stack spacing={1} direction="row" height={50}>
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
            </Stack>
            <Stack spacing={1} direction="row" height={50}>
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
            </Stack>
            <Stack spacing={1} direction="row" height={50}>
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
            </Stack>
            <Stack spacing={1} direction="row" height={50}>
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
            </Stack>
            <Stack spacing={1} direction="row" height={50}>
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
            </Stack>
            <Stack spacing={1} direction="row" height={50}>
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
            </Stack>
            <Stack spacing={1} direction="row" height={50}>
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
              <Skeleton variant="rectangular" animation="wave" width="25%" />
            </Stack>
          </Stack>
        ) : !posts?.length ? (
          <Grid item className="title content-centered" xs={12}>
            <Typography variant="h4">
              {t('context.blog.post.no_posts')}
            </Typography>
          </Grid>
        ) : (
          <Grid>
            <Box height="70%" width="100%" sx={{ overflow: 'hidden' }}>
              <DataGrid
                isCellEditable={(params) => false}
                getRowId={(row) => row._id}
                rows={posts}
                columns={postColumns}
                autosizeOnMount
                columnVisibilityModel={{
                  // Hide columns status and traderName, the other columns will remain visible
                  company: !mobileView,
                  status_label: !mobileView,
                  created: !mobileView,
                  share: !mobileView,
                  author_label: !mobileView,
                  description: !mobileView,
                  type_label: !mobileView,
                  origin_label: !mobileView,
                  associate_label: !mobileView,
                }}
                slots={{
                  Row: MemoizedRow,
                  ColumnHeaders: MemoizedColumnHeaders,
                  toolbar: GridToolbar,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      first_name: !mobileView,
                    },
                  },
                }}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: 20,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'red',
                    borderRadius: 2,
                  },
                }}
                pageSizeOptions={[5, 10, 100]}
                checkboxSelection={!mobileView}
                disableRowSelectionOnClick
              />
            </Box>
          </Grid>
        )}
        {/* CTA */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Grid
            container
            item
            xs={4}
            justifyContent="center"
            alignItems="center"
            pr="20px"
          >
            <Stack
              spacing={1}
              direction={{ xs: 'column', sm: 'row' }}
              style={classes.root}
              pb="50px"
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ minWidth: 150, maxHeight: 40 }}
                onClick={createPost}
              >
                {t('buttons.create_post')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="info"
                onClick={() => handleGetPosts()}
                sx={{ minWidth: 150, maxHeight: 40 }}
              >
                {t('buttons.refresh_post')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { UserPostsList };
