import { useState, useEffect, useRef, useContext, memo } from 'react';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate.js';
import { toTitleCase } from '../../helpers/toTitleCase.js';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  GridToolbar,
  GridRow,
  GridColumnHeaders,
} from '@mui/x-data-grid-premium';
import {
  Box,
  Stack,
  FormControl,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Typography,
  TextField,
  Select,
  InputAdornment,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { classes } from '../../settings/theme.js';
import ResponseContext from '../../context/providers/ResponseProvider.jsx';
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { useTranslation } from 'react-i18next';
import ProductTypeField from '../product-type/ProductTypeField.jsx';
import ApplicationsField from '../applications/ApplicationsField.jsx';
import IndividualFirstNameField from '../account/individual/IndividualFirstNameField.jsx';
import IndividualLastNameField from '../account/individual/IndividualLastNameField.jsx';
import UserProfilePicture from '../account/individual/IndividualProfilePicture.jsx';
import EmailField from '../account/fields/email/EmailField.jsx';
import IndividualCompanyNameField from '../account/individual/IndividualCompanyNameField.jsx';
import AccountTypeField from '../account/fields/type/AccountTypeField.jsx';
import MobileNumberField from '../account/MobileNumberField.jsx';
import AddressField from '../address/AddressField.jsx';
import MobileNotificationCheckBox from '../account/two-factor-authentication/MobileNotificationCheckBox.jsx';
import IndividualIntroField from '../account/individual/IndividualIntroField.jsx';
import { UserProfileContext } from '../../context/providers/UserProfileProvider.jsx';
import IntuIconButton from '../buttons/IntuIconButton.jsx';
import AccountStatusField from '../account/fields/status/AccountStatusField.jsx';
import ProcessingContext from '../../context/providers/ProcessingProvider.jsx';

const IntuUserList = () => {
  const [users, setUsers] = useState();

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  // Use Context
  const { handleGetProductTypes, handleGetApplications } =
    useContext(UserProfileContext);

  // Axios
  const axios = useAxiosPrivate();

  // Loading Spinner Status
  const { setIsLoading } = useContext(ProcessingContext);

  // Translator
  const { t } = useTranslation();
  const { t: transFields } = useTranslation('fields');

  const [userRoles, setUserRoles] = useState([]);
  const [toggleUserEditOpen, setToggleUserEditOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    account_type: '',
    first_name: '',
    last_name: '',
    company: {
      _id: '',
      company: '',
      city: '',
      state: '',
      country: '',
      postcode: '',
    },
    email: '',
    industries: [],
    applications: [],
    product_types: [],
    mobile: {
      value: '',
      info: {
        countryCallingCode: null,
        countryCode: null,
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    },
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postcode: '',
      country: { label: 'United States', dialCode: '1', code: 'US' },
    },
    profile_picture: '',
    profile_picture_view_url: '',
  });

  const columns = [
    {
      field: 'first_name',
      headerName: t('form.first_name'),
      editable: false,
      flex: 2,
    },
    {
      field: 'last_name',
      headerName: t('form.last_name'),
      editable: false,
      flex: 2,
    },
    {
      field: 'email',
      headerName: t('form.email.label_alt'),
      editable: false,
      flex: 3,
    },
    {
      field: 'role_label',
      headerName: t('form.global.label.role'),
      flex: 1,
      editable: false,
    },
    {
      field: 'account_status_label',
      headerName: t('form.account.acct_status'),
      flex: 1,
      editable: false,
    },
    {
      field: 'account_type_label',
      headerName: t('form.account.acct_type'),
      flex: 1,
      editable: false,
    },
    {
      field: 'edit',
      headerName: t('buttons.edit'),
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const userInfo = params.row;
          const applicationNames = await handleGetApplications(
            userInfo.applications,
          );
          const productNames = await handleGetProductTypes(userInfo.products);
          setUserInfo({
            ...userInfo,
            applications: applicationNames,
            product_types: productNames,
          });
          setTimeout(setToggleUserEditOpen(true), '10000');
        };

        return (
          <Button variant="contained" color="info" onClick={onClick}>
            {t('buttons.edit')}
          </Button>
        );
      },
    },
  ];

  // Set Up Form refs
  const errRef = useRef();

  // Initial Values
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    ...userInfo,
    company: userInfo?.company
      ? userInfo?.company
      : {
          _id: '',
          company: '',
          city: '',
          state: '',
          country: '',
          postcode: '',
        },
    account_type: userInfo.account_type ? userInfo.account_type : '',
    account_status: userInfo.account_status ? userInfo.account_status : '',
    applications: userInfo?.applications ? userInfo?.applications : [],
    applications_new: [],
    product_types: userInfo?.product_types ? userInfo?.product_types : [],
    product_types_new: [],
    product_group_options: [],
    address_options_company: [],
    toggleUserEditOpen,
    toggleProductOpen: false,
    toggleApplicationOpen: false,
  };

  const validationSchema = Yup.object().shape({
    toggleUserEditOpen: Yup.boolean(),
    first_name: Yup.string().when('toggleUserEditOpen', {
      is: true,
      then: () =>
        Yup.string()
          .min(2, t('validation.short'))
          .max(50, t('validation.long'))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
            t('validation.name.first_matches'),
          )
          .required(t('validation.name.first_required')),
    }),
    last_name: Yup.string().when('toggleUserEditOpen', {
      is: true,
      then: () =>
        Yup.string()
          .min(2, t('validation.short'))
          .max(50, t('validation.long'))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
            t('validation.name.last_matches'),
          )
          .required(t('validation.name.last_required')),
    }),
    email: Yup.string().when('toggleUserEditOpen', {
      is: true,
      then: () =>
        Yup.string()
          .label(t('form.email.label_alt'))
          .required(t('validation.email.user_required_alt'))
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            t('validation.email.invalid'),
          ),
    }),
    address: Yup.object().when('toggleUserEditOpen', {
      is: true,
      then: () =>
        Yup.object({
          line1: Yup.string()
            .min(5, t('validation.short'))
            .required(t('validation.address.line1_required')),
          city: Yup.string()
            .min(3, t('validation.short'))
            .required(t('validation.address.city_required')),
          state: Yup.string()
            .min(2, t('validation.short'))
            .required(t('validation.address.state_required')),
          postcode: Yup.string()
            .min(3, t('validation.short'))
            .required(t('validation.address.postcode_required')),
          country: Yup.object({
            label: Yup.string().required(t('validation.country.required')),
            dialCode: Yup.number(t('validation.country.dial_code.invalid'))
              .required(t('validation.country.dial_code.required'))
              .typeError(t('validation.country.dial_code.error')),
            code: Yup.string()
              .required(t('validation.country.code.required'))
              .min(2, t('validation.country.code.min')),
          }),
        }),
    }),
    mobile: Yup.object().when('toggleUserEditOpen', {
      is: true,
      then: () =>
        Yup.object({
          value: Yup.string().required(t('validation.company.phone_required')),
          info: Yup.object({
            countryCallingCode: Yup.number(
              t('validation.phone.country_code.invalid'),
            )
              .required(t('validation.phone.country_code.required'))
              .typeError(t('validation.phone.country_code.error')),
            nationalNumber: Yup.string()
              .required(t('validation.phone.required'))
              .min(8, t('validation.phone.min'))
              .max(15, t('validation.phone.max')),
          }),
        }),
    }),
    account_type: Yup.string().when('toggleUserEditOpen', {
      is: true,
      then: () => Yup.string().required(t('validation.account.type_required')),
    }),
    account_status: Yup.string().when('toggleUserEditOpen', {
      is: true,
      then: () =>
        Yup.string().required(t('validation.account.status_required')),
    }),
  });

  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  function getUserRoleNice(reqCode) {
    if (userRoles.length > 0) {
      userRoles.find((role) => {
        if (role.code === reqCode) {
          return toTitleCase(role.role);
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  }

  const refreshUsers = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const response = await axios.get('/api/users/list', { signal });

      const { data, status } = response;
      if (status === 200) {
        // Structure User Data to comply with data grid requirements
        const userArray = data.data.map((user) => {
          return {
            _id: user._id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            role: user.role,
            role_label: getUserRoleNice(user.role),
            role_code: user.role,
            address: {
              line1: user?.address?.line1,
              line2: user?.address?.line2,
              city: user?.address?.city,
              state: user?.address?.state,
              postcode: user?.address?.postcode,
              country: user?.address?.country,
            },
            mobile: user.mobile,
            profile_picture: user.profile_picture,
            profile_picture_view_url: getProfilePictureLink(
              user.profile_picture,
            ),
            postcode: user.postcode,
            product_types: user.product_types,
            applications: user.applications,
            company: user.company,
            otp: user.otp,
            account_complete: user.account_complete,
            account_status: user.account_status,
            account_status_label:
              user.account_status && toTitleCase(user.account_status),
            account_type: user.account_type,
            account_type_label:
              user.account_type && toTitleCase(user.account_type),
            intro_rte: user.intro,
            two_factor: user.two_factor,
            initial_setup: user.initial_setup,
            profile_link: user.profile_link,
            refresh_token: user.refresh_token,
            auth_method: user.auth_method,
            linkedin: user.linkedin,
            account_notifications: user.account_notifications,
          };
        });
        setUsers(userArray);
      }
    } catch (err) {
      if (err.name === 'CanceledError') return;
      handleErrorResponse(err);
    }
  };

  // Make Profile Picture viewable
  function getProfilePictureLink(picture) {
    if (picture?.mimetype) {
      let image = `data:${picture.mimetype};base64,${picture.buffer.toString('base64')}`;
      return image;
    } else {
      // No Pitcure to process
      return;
    }
  }

  // Pre-Load Info
  useEffect(() => {
    let isMounted = true;
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('form.message.prepare_users'),
    });

    const controller = new AbortController();
    const signal = controller.signal;

    // Get User Roles from Database
    let userRoles;
    const getUserRoles = async () => {
      try {
        const { data } = await axios.get('/api/users/roles/list', { signal });
        if (data.status === 'success') {
          setUserRoles(data.data);
          userRoles = data.data;
          getUsers();
        }
      } catch (err) {
        if (err.name === 'CanceledError') return;
        console.error(err);
      }
    };

    // Make User roles readable
    function getUserRoleNice(reqCode) {
      // Guard Clause
      if (!userRoles || userRoles?.length === 0) {
        return null;
      }

      let roleName;

      userRoles.find((roleInfo) => {
        // let roleName;
        if (roleInfo.code === reqCode) {
          roleName = toTitleCase(roleInfo.role);
          return roleName;
        }

        return roleName;
      });
    }

    // Get all Users from Database
    const getUsers = async () => {
      try {
        const { data } = await axios.get('/api/users/list', { signal });
        if (isMounted) {
          // Structure User Data to comply with data grid requirements
          let userArray = [];
          data.data.map((user) => {
            return userArray.push({
              _id: user._id,
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
              role: user.role,
              role_label: getUserRoleNice(user?.role),
              role_code: user.role,
              address: {
                line1: user?.address?.line1,
                line2: user?.address?.line2,
                city: user?.address?.city,
                state: user?.address?.state,
                postcode: user?.address?.postcode,
                country: user?.address?.country,
              },
              mobile: user.mobile,
              profile_picture: user.profile_picture,
              profile_picture_view_url: getProfilePictureLink(
                user.profile_picture,
              ),
              postcode: user.postcode,
              product_types: user.product_types,
              applications: user.applications,
              company: user.company,
              otp: user.otp,
              account_complete: user.account_complete,
              account_status: user.account_status,
              account_status_label:
                user.account_status && toTitleCase(user.account_status),
              account_type: user.account_type,
              account_type_label:
                user.account_type && toTitleCase(user.account_type),
              intro_rte: user.intro,
              two_factor: user.two_factor,
              initial_setup: user.initial_setup,
              profile_link: user.profile_link,
              refresh_token: user.refresh_token,
              auth_method: user.auth_method,
              linkedin: user.linkedin,
              account_notifications: user.account_notifications,
            });
          });
          setUsers(userArray);
          setIsLoading({ status: false, type: '', text: '' });
        }
      } catch (err) {
        if (err.name === 'CanceledError') return;

        handleErrorResponse(err);
        setIsLoading({ status: false, type: '', text: '' });
      }
    };

    // Check if useEffect has run the first time
    const PreLoadForm = async () => {
      await getUserRoles();
      await getUsers();
    };

    PreLoadForm();

    return () => {
      isMounted = false;
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  // Handle form submission process
  async function submitForm(values) {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('form.message.update_user'),
    });

    // Update User Account
    const url = '/api/users/account/update';
    const payload = {
      _id: values._id,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      account_type: values.account_type,
      account_status: values.account_status,
      address: values.address,
      role: values.role,
      mobile: values.mobile,
      mobileNumber: values.mobile.info.nationalNumber,
      dialCode: values.mobile.info.countryCallingCode,
      refresh_token: values.refresh_token,
      account_notifications: values.account_notifications,
      auth_method: values.auth_method,
      two_factor: values.two_factor,
      linkedin: values.linkedin,
    };

    try {
      const { data, status } = await axios.put(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      if (status === 200) {
        // Update User Info
        setUserInfo(userInfo);
      }

      handleRegularResponse({
        open: true,
        status: data.status,
        message: data.message,
      });
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
    }
  }

  // Viewport
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 767) {
        setMobileView(false);
      } else if (window.innerWidth < 767) {
        setMobileView(true);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
  });

  return (
    <Grid
      container
      className="content"
      sx={{ marginBottom: '8rem' }}
      spacing={5}
    >
      <Grid item className="content" xs={12}>
        <Formik
          values={formik.values}
          initialValues={formik.initialValues}
          errors={formik.errors}
          validationSchema={validationSchema}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => {
            const {
              values,
              setFieldValue,
              errors,
              touched,
              handleBlur,
              setFieldTouched,
              setTouched,
              setValues,
              resetForm,
            } = formik;
            return (
              <>
                {users?.length ? (
                  <>
                    <Box sx={{ height: '70%', width: '100%' }}>
                      <DataGrid
                        isCellEditable={(params) => false}
                        getRowId={(row) => row._id}
                        rows={users}
                        columns={columns}
                        columnVisibilityModel={{
                          // Hide columns status and traderName, the other columns will remain visible
                          email: !mobileView,
                          role_label: !mobileView,
                          account_status_label: !mobileView,
                          account_type_label: !mobileView,
                        }}
                        components={{
                          Row: MemoizedRow,
                          ColumnHeaders: MemoizedColumnHeaders,
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                          columns: {
                            columnVisibilityModel: {
                              // Hide columns status and traderName, the other columns will remain visible
                              first_name: !mobileView,
                            },
                          },
                        }}
                        sx={{
                          '&::-webkit-scrollbar': {
                            width: 20,
                          },
                          '&::-webkit-scrollbar-track': {
                            backgroundColor: 'orange',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'red',
                            borderRadius: 2,
                          },
                        }}
                        pageSizeOptions={[5, 10, 100]}
                        checkboxSelection={!mobileView}
                        disableRowSelectionOnClick
                        slots={{ toolbar: GridToolbar }}
                      />
                    </Box>

                    {/* Edit User dialog */}
                    <Form role="form">
                      <FormControl fullWidth>
                        <Dialog
                          maxWidth="sm"
                          fullWidth
                          open={toggleUserEditOpen}
                        >
                          <DialogTitle>
                            {t('form.profile.edit.user')}
                          </DialogTitle>
                          <DialogContent>
                            <Stack
                              spacing={1}
                              style={classes.root}
                              sx={{ paddingBottom: '10px' }}
                            >
                              <DialogContentText
                                sx={{
                                  color: 'var(--color-text-error-message)',
                                }}
                              >
                                {t('context.admin.dialog.danger_zone')}
                              </DialogContentText>

                              {/* Profile Picture  */}
                              <Grid
                                container
                                justifyContent="center"
                                direction={{
                                  xs: 'column',
                                  sm: 'column',
                                  md: 'row',
                                }}
                              >
                                <Grid
                                  container
                                  item
                                  xs={4}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  sx={{ paddingRight: '20px' }}
                                >
                                  <UserProfilePicture
                                    src={userInfo.profile_picture_view_url}
                                    initials={userInfo?.initials}
                                  />
                                </Grid>

                                {/* Personal Details */}
                                <Grid item xs={7}>
                                  <Stack
                                    spacing={{ xs: 3, sm: 3, md: 1 }}
                                    direction="column"
                                    sx={{ paddingTop: '10px' }}
                                    style={classes.root}
                                    alignItems="stretch"
                                  >
                                    {/* First Name */}
                                    <IndividualFirstNameField
                                      required
                                      variant="standard"
                                      fieldID="first_name"
                                    />

                                    {/* Last Name */}
                                    <IndividualLastNameField
                                      required
                                      fieldID="last_name"
                                      variant="standard"
                                    />

                                    {/* User Email */}
                                    <EmailField
                                      required
                                      fieldID="email"
                                      variant="standard"
                                    />
                                  </Stack>
                                </Grid>
                              </Grid>

                              {/* Personal Address */}
                              <AddressField
                                labelID={values?.individual?.name}
                                fieldID="address"
                                autocompleteID="address"
                                transNS="fields"
                                transPrefix="AddressField"
                                disabled
                                fieldDescription="Address"
                              />

                              {/* Personal Mobile Phone */}
                              <MobileNumberField
                                fieldID="mobile"
                                fieldDescription="Mobile Number"
                              />
                              {/* Account Notifications */}
                              <MobileNotificationCheckBox fieldDescription="Account Notifications" />

                              {/* Account Type */}
                              <AccountTypeField fieldDescription="Account Type" />

                              {/* Company */}
                              <IndividualCompanyNameField
                                required
                                fieldID="company"
                                disabled
                                fieldDescription="Company"
                              />

                              {/* Profile Link */}
                              <TextField
                                required
                                disabled
                                id="profile_link"
                                name="profile_link"
                                margin="dense"
                                className="form-select-field"
                                aria-invalid={
                                  errors.profile_link ? 'true' : 'false'
                                }
                                aria-describedby="uidnote"
                                variant="standard"
                                label={transFields('global.label.profile_link')}
                                type="text"
                                placeholder={transFields(
                                  'global.label.profile_link',
                                )}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values?.profile_link}
                              />

                              {/* LinkedIn */}
                              <TextField
                                required
                                disabled
                                id="linkedin"
                                name="linkedin"
                                margin="dense"
                                className="form-select-field"
                                aria-invalid={
                                  errors.linkedin ? 'true' : 'false'
                                }
                                aria-describedby="uidnote"
                                variant="standard"
                                label={t('form.linkedin')}
                                type="text"
                                placeholder={t('form.linkedin')}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                onBlur={handleBlur}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IntuIconButton
                                        variant="contained"
                                        tooltipTitle="clear"
                                        tooltipType="auth"
                                        type="error"
                                        onClick={() => {
                                          setFieldValue('linkedin', '');
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                value={values?.linkedin?.provider}
                              />

                              {/* Intro */}
                              <IndividualIntroField
                                fieldDescription="Intro"
                                disabled
                              />

                              {values?.account_type !== 'intutec' && (
                                <>
                                  {/* Applications*/}
                                  <ApplicationsField
                                    fieldDescription="Applications"
                                    disabled
                                  />

                                  {/* Products */}
                                  <ProductTypeField
                                    fieldDescription="Products"
                                    disabled
                                  />
                                </>
                              )}
                            </Stack>

                            <Stack
                              spacing={1}
                              style={classes.root}
                              sx={{ paddingBottom: '10px' }}
                            >
                              <Typography>Account Details</Typography>
                              {/* Account Complete */}
                              <TextField
                                disabled
                                id="account_complete"
                                name="account_complete"
                                margin="dense"
                                className="form-select-field"
                                aria-invalid={
                                  errors.account_complete ? 'true' : 'false'
                                }
                                aria-describedby="uidnote"
                                variant="standard"
                                label={transFields(
                                  'global.label.account_complete',
                                )}
                                type="text"
                                placeholder={transFields(
                                  'global.label.account_complete',
                                )}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values?.account_complete}
                              />
                              {/* Initial Setup */}
                              <TextField
                                disabled
                                id="initial_setup"
                                name="initial_setup"
                                margin="dense"
                                className="form-select-field"
                                aria-invalid={
                                  errors.initial_setup ? 'true' : 'false'
                                }
                                aria-describedby="uidnote"
                                variant="standard"
                                label={transFields(
                                  'global.label.initial_setup',
                                )}
                                type="text"
                                placeholder={transFields(
                                  'global.label.initial_setup',
                                )}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values?.initial_setup}
                              />
                              {/* Refresh Token */}
                              <TextField
                                id="refresh_token"
                                name="refresh_token"
                                margin="dense"
                                className="form-select-field"
                                aria-invalid={
                                  errors.refresh_token ? 'true' : 'false'
                                }
                                aria-describedby="uidnote"
                                variant="standard"
                                label={transFields(
                                  'global.label.refresh_token',
                                )}
                                type="text"
                                placeholder={t('form.refresh_token')}
                                onChange={(e) => {
                                  setFieldValue(e.target.name, e.target.value);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IntuIconButton
                                        variant="contained"
                                        tooltipTitle="clear"
                                        tooltipType="token"
                                        type="error"
                                        onClick={() => {
                                          setFieldValue('refresh_token', '');
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                onBlur={handleBlur}
                                value={values?.refresh_token}
                              />
                              {/* Auth Method */}
                              <Grid>
                                <TextField
                                  disabled
                                  id="auth_method"
                                  name="auth_method"
                                  margin="dense"
                                  className="form-select-field"
                                  aria-invalid={
                                    errors.auth_method ? 'true' : 'false'
                                  }
                                  aria-describedby="uidnote"
                                  variant="standard"
                                  label={transFields(
                                    'global.label.auth_method',
                                  )}
                                  type="text"
                                  placeholder={transFields(
                                    'global.label.auth_method',
                                  )}
                                  onChange={(e) => {
                                    setFieldValue(
                                      e.target.name,
                                      e.target.value,
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IntuIconButton
                                          variant="contained"
                                          tooltipTitle="reset"
                                          tooltipType="auth"
                                          type="error"
                                          onClick={() => {
                                            setFieldValue('auth_method', {
                                              id: null,
                                              provider: 'password',
                                              url: null,
                                            });
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={values?.auth_method?.provider}
                                />
                              </Grid>
                              {/* Account Status */}
                              <Grid>
                                <AccountStatusField
                                  fieldDescription={transFields(
                                    'AccountStatusField.label',
                                  )}
                                />
                              </Grid>
                              {/* Role */}
                              <FormControl>
                                <Typography>
                                  {transFields('global.label.role')}
                                </Typography>
                                <Select
                                  required
                                  type="text"
                                  id="role"
                                  name="role"
                                  variant="standard"
                                  value={values?.role}
                                  label={transFields('global.label.role')}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(
                                      e.target.name,
                                      e.target.value,
                                    );
                                  }}
                                  error={
                                    errors.role && touched.role ? true : false
                                  }
                                  helpertext={
                                    errors.role && touched.role
                                      ? errors.role
                                      : null
                                  }
                                >
                                  <MenuItem key={0} value="">
                                    Select a role
                                  </MenuItem>
                                  <MenuItem key={1} value="ed8k4gftsdr">
                                    User
                                  </MenuItem>
                                  <MenuItem key={2} value="yd6j783eudj">
                                    Admin
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Stack>
                          </DialogContent>

                          <DialogActions>
                            {/* Cancel */}
                            <Button
                              variant="standard"
                              color="error"
                              onClick={() => {
                                setToggleUserEditOpen(false);
                              }}
                            >
                              {t('buttons.cancel', { type: t('') })}
                            </Button>

                            {/* Update Button */}
                            <Button
                              variant="contained"
                              color="warning"
                              disabled={
                                !formik.isValid ||
                                Object.keys(touched).length === 0
                              }
                              type="submit"
                              onClick={(e) => {
                                const update = submitForm(values, formik);
                                if (update.status === 'error ') {
                                  resetForm();
                                } else {
                                  setTouched({}, false);
                                  setToggleUserEditOpen(false);
                                }
                              }}
                            >
                              {t('buttons.update_user')}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </FormControl>
                    </Form>
                  </>
                ) : (
                  <Typography variant="body1">
                    {t('context.admin.dialog.no_users')}
                  </Typography>
                )}
                <Button
                  varianed="contained"
                  color="secondary"
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    refreshUsers();
                  }}
                >
                  {t('buttons.refresh_user')}
                </Button>
              </>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export { IntuUserList };
