import { useState, useEffect, useRef, useContext } from 'react';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsTracker.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../../../hooks/axios/axios.js';
import bcrypt from 'bcryptjs';
import { Formik, Form } from 'formik';
import {
  Button,
  Stack,
  FormControl,
  CircularProgress,
  Grid,
} from '@mui/material';
import { classes } from '../../../../settings/theme.js';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n.js';
import { createSignUpValidationSchema } from './ValidationSchema.js';
import { createSignUpInitialValues } from './InitialValues.js';
import AccountTypeField from '../../fields/type/AccountTypeField.jsx';
import IndividualFirstNameField from '../../individual/IndividualFirstNameField.jsx';
import IndividualLastNameField from '../../individual/IndividualLastNameField.jsx';
import EmailField from '../../fields/email/EmailField.jsx';
import PasswordField from '../../PasswordField.jsx';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AuthContext from '../../../../context/providers/AuthProvider.jsx';

const SignUpForm = ({ firstName, lastName, type, email }) => {
  const { gaEventTracker } = useAnalyticsEventTracker();

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  // State & Context
  const { setAuth } = useContext(AuthContext);

  // SALT should be created ONE TIME upon sign up
  const salt = bcrypt.genSaltSync(10);

  const navigate = useNavigate();
  const host_url = window.location.host;
  const location = useLocation();
  const destination_url = location.state?.from?.pathname;

  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignUpForm',
  });

  const { t: transButtons } = useTranslation('buttons');

  // Setup Initial Values for Form Validation
  const initialValues = createSignUpInitialValues({
    firstName,
    lastName,
    type,
    email,
  });

  // Validation Schema
  const validationSchema = createSignUpValidationSchema();
  const formRef = useRef(null);

  //handle form submission process
  async function submitForm(values, formik) {
    const hashedPassword = bcrypt.hashSync(values.password, salt);

    let account_type = values.account_type;
    let first_name = values.first_name;
    let last_name = values.last_name;
    let email = values.email;
    let password = hashedPassword;

    //create new user account
    const url = `/api/users/account/create`;
    const payload = {
      account_type,
      first_name,
      last_name,
      email,
      password,
      host_url: host_url,
    };

    const controller = new AbortController();
    const { signal } = controller;

    try {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: i18n.t('form.message.sign_up'),
      });

      const response = await axios.post(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      const { data, status } = response;

      if (status === 200) {
        //user created
        formik.resetForm();
        gaEventTracker({
          category: 'account',
          action: 'events',
          label: 'New Account SignUp',
          value: 100,
        });
        navigate(`${data.navigate_to}&userAction=accConfirm`, {
          replace: true,
        });
      } else if (status === 230) {
        // user pending
        formik.resetForm();
        setIsLoading({ status: false, type: '', text: '' });
        navigate(`${data.navigate_to}&userAction=accConfirm`, {
          replace: true,
        });
      } else if (status === 231) {
        formik.resetForm();
        setTimeout(() => formik.validateForm(), 300);
        handleRegularResponse({
          open: true,
          status: 'error',
          message: data.message,
        });
      } else {
        // all other responses
        handleRegularResponse({
          open: true,
          status: 'error',
          message: data.message,
        });
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
      controller.abort(signal);
    }
  }

  // handle linkedin SignUp
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_SIGNIN_CLIENT_ID,
    scope: 'openid profile email',
    redirectUri: `${window.location.origin}/linkedin-callback`,
    onSuccess: async (authCode) => {
      const controller = new AbortController();
      const { signal } = controller;

      try {
        setIsLoading({
          status: true,
          type: 'spinner',
          text: i18n.t('form.message.sign_up'),
        });

        // console.log('Authorization code received:', authCode);

        const url = `/api/linkedin/signin`;
        const params = {
          code: authCode,
          host_url: host_url,
        };

        const userInfoResponse = await axios.get(url, {
          params,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        if (userInfoResponse.status === 200) {
          // SIgnUp successful
          const { user_info, auth_info, navigate_to } = userInfoResponse.data;

          gaEventTracker({
            category: 'Account Management',
            action: 'Event',
            label: 'User Logged In',
          });

          let image = '';
          // Set Profile Picture
          if (user_info?.profile_picture?.mimetype) {
            image = `data:${user_info.profile_picture.mimetype};base64,${user_info.profile_picture.buffer.toString('base64')}`;
          }

          // Set User Auth State
          setAuth({
            auth_info,
            user_info: {
              ...user_info,
              profile_picture_view_url: image,
            },
          });

          // Navigate user
          if (destination_url) {
            // Redirect to original page
            navigate(destination_url, { replace: true });
          } else {
            // Navigate to user dashboard
            navigate(navigate_to, { replace: true });
          }
        } else {
          const { status, message, navigate_to } = userInfoResponse.data;

          if (navigate_to) {
            navigate(navigate_to, { replace: true });
          }

          handleRegularResponse({
            open: true,
            status: status,
            message: message,
          });
        }
      } catch (error) {
        console.log(error);
        handleErrorResponse(error);
      } finally {
        setIsLoading({ status: false, type: '', text: '' });
        controller.abort(signal);
      }
    },
    onError: (error) => {
      console.log('Error Authenticating: ', error);
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formik) => submitForm(values, formik)}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const { handleSubmit, isValid, isSubmitting } = formik;
        return (
          <Form
            className="form-horizontal"
            role="form"
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
          >
            <FormControl fullWidth>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                sx={{ paddingBottom: '50px' }}
                direction="column"
              >
                <AccountTypeField required />
                <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                  <IndividualFirstNameField required fieldID="first_name" />
                  <IndividualLastNameField required fieldID="last_name" />
                </Stack>
                <Stack spacing={4} style={classes.root}>
                  {/* Email address & Phone Number */}
                  <EmailField
                    required
                    fieldID="email"
                    transNS="fields"
                    transTypeID="personal"
                    transPrefix="account.EmailField"
                  />
                </Stack>
                {/* User Password and Confirmation */}
                <Stack
                  spacing={1}
                  direction={{ xs: 'column', sm: 'row' }}
                  style={classes.root}
                >
                  {/* Define Password */}
                  <PasswordField type="define" fieldID="password" />
                  {/* Password Confirmation */}
                  <PasswordField type="confirm" fieldID="passwordConf" />
                </Stack>
              </Stack>
              <Grid
                container
                spacing={1}
                direction={{ xs: 'column', sm: 'row' }}
                style={classes.root}
                sx={{ paddingBottom: '50px' }}
              >
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <Grid container spacing={2}>
                    <Grid item>
                      {/* Regular SignUp Button  */}

                      <Button
                        variant="submit"
                        type="submit"
                        startIcon={
                          isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={isSubmitting || !isValid}
                      >
                        {transButtons('sign_up')}
                      </Button>
                    </Grid>

                    {/* LinkedIn SinUp Button */}
                    <Grid item>
                      <Button
                        variant="contained"
                        type="button"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.linkedIn.main,
                          color: (theme) => theme.palette.linkedIn.contrastText,
                          '&:hover': {
                            backgroundColor: (theme) =>
                              theme.palette.linkedIn.dark,
                          },
                        }}
                        onClick={linkedInLogin}
                        startIcon={<LinkedInIcon sx={{ color: '#fff' }} />}
                      >
                        {transButtons('sign_up_linkedin')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

export { SignUpForm };
