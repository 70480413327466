import { useContext, useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n.js';
import useAuth from '../../../../hooks/useAuth.js';
import { ApplicationsContext } from '../../../../context/providers/ApplicationsContextProvider.jsx';
import { ProductTypeContext } from '../../../../context/providers/ProductTypeContextProvider.jsx';
import { CompanyProfileContext } from '../../../../context/providers/CompanyProfileProvider.jsx';
import { FormikStepperContext } from '../../../formik/FormikStepper.jsx';
import { BrandsContext } from '../../../../context/providers/BrandsProvider.jsx';
import CompanyNameField from '../../company/CompanyNameField.jsx';
import CompanyBrandsField from '../../company/CompanyBrandsField.jsx';
import BusinessTypeField from '../../BusinessTypeField.jsx';
import EmailField from '../../fields/email/EmailField.jsx';
import WebsiteField from '../../WebsiteField.jsx';
import PhoneField from '../../PhoneField .jsx';
import AddressField from '../../../address/AddressField.jsx';
import AccountTypeField from '../../fields/type/AccountTypeField.jsx';
import { createInitialValuesBusinessDetailsForm } from './InitialValues.js';
import useUser from '../../../../hooks/useUser.js';

const BusinessDetailsForm = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignUpComplete.BusinessDetailsForm',
  });
  const { auth } = useAuth();
  const { user } = useUser();
  const { setNewApplicationList } = useContext(ApplicationsContext);
  const { setNewProductTypeList } = useContext(ProductTypeContext);
  const { newCompany, setNewCompany, setCompanyProfile } = useContext(
    CompanyProfileContext,
  );
  const { setNewBrandsList } = useContext(BrandsContext);
  const { values, resetForm, validateForm, setFieldValue, setFieldTouched } =
    useFormikContext();
  const { setStepperFormData } = useContext(FormikStepperContext);

  // Handle Business Type Change

  // Set Business TYpe
  useEffect(() => {
    if (values.account_type === 'manufacturer') {
      setFieldValue('business_type', 'company');
      setFieldTouched('business_type', true, true);
    }
  }, [values.account_type]);

  const handleClear = async () => {
    const blankForm = createInitialValuesBusinessDetailsForm({ user, auth });

    // Clear Parent Form Values
    setNewCompany(true);
    setNewApplicationList([]);
    setNewProductTypeList([]);
    setNewBrandsList([]);
    setCompanyProfile({});
    setStepperFormData(blankForm);

    // Clear Child Foem
    resetForm(blankForm);
    setTimeout(() => validateForm(), 300);
  };

  return (
    <>
      <Stack spacing={4} sx={{ paddingBottom: '50px' }}>
        {((values.business_type === 'company' && newCompany === false) ||
          values?.company?.isSubsidiary) && (
          <>
            <Button variant="outlined" color="error" onClick={handleClear}>
              {i18n.t('buttons.clear')}
            </Button>
          </>
        )}

        {/* Show Account Type Field for LinkedIn Users */}
        {!auth?.user_info?.account_type && (
          <AccountTypeField
            fieldTitle={t('form.account_type.title')}
            fieldDescription={t('form.account_type.description')}
            required
          />
        )}

        <BusinessTypeField
          fieldTitle={t('form.business_type.title')}
          fieldDescription={t('form.business_type.description')}
          transition={
            !values?.account_type || values.account_type === 'manufacturer'
              ? false
              : true
          }
          disabled={values?.account_type === 'manufacturer'}
        />

        <CompanyNameField
          fieldTitle={t('form.company.title')}
          fieldDescription={t('form.company.description')}
          transition={values.business_type === 'company'}
          required={values?.business_type === 'company'}
          disabled={values?.business_type === 'company' && newCompany === false}
          showAdmins={true}
          transNS="fields"
          transPrefix="account.company.CompanyNameField"
        />

        <CompanyBrandsField
          fieldTitle={t('form.brands.title')}
          fieldDescription={t('form.brands.description')}
          transition={
            values.account_type === 'manufacturer' &&
            (newCompany === true || values?.company?.isSubsidiary === true)
              ? true
              : false
          }
          required={
            auth?.user_info?.account_type === 'manufacturer' &&
            (newCompany === false || values?.company?.isSubsidiary === true)
          }
          disabled={
            (auth?.user_info?.account_type !== 'manufacturer' &&
              values.account_type !== 'manufacturer') ||
            (values.business_type === 'company' &&
              (newCompany === false || values?.company?.isSubsidiary === true))
          }
        />
        <AddressField
          fieldTitle={t('form.address.title')}
          fieldDescription={t('form.address.description')}
          labelID={values?.company?.name}
          autocompleteID="company.address"
          phoneFieldID="company.phone"
          fieldID="company.address"
          transNS="fields"
          transPrefix="AddressField"
          required={values?.company ? true : false}
          transition={
            (values.business_type === 'company' ? true : false) ||
            (auth?.user_info?.account_type === 'manufacturer' &&
              values.business_type === 'company')
              ? true
              : false
          }
          disabled={!newCompany}
        />
        <WebsiteField
          fieldTitle={t('form.website.title')}
          fieldDescription={t('form.website.description')}
          transition={values.business_type === 'company'}
          disabled={values.business_type === 'company' && newCompany === false}
          required={values.business_type === 'company'}
          fieldID="company.website"
          transNS="fields"
          transPrefix="account.company.WebsiteField"
        />
        <EmailField
          fieldTitle={t('form.email.title')}
          fieldDescription={t('form.email.description')}
          fieldID="company.email"
          transNS="fields"
          transPrefix="account.company.EmailField"
          transition={values.business_type === 'company' ? true : false}
          required={values.business_type === 'company'}
          disabled={values.business_type === 'company' && newCompany === false}
        />
        <PhoneField
          fieldTitle={t('form.phone.title')}
          fieldDescription={t('form.phone.description')}
          fieldID="company.phone"
          transition={values.business_type === 'company'}
          required={values.business_type === 'company'}
          disabled={values.business_type === 'company' && !newCompany}
          transNS="fields"
          transPrefix="account.company.PhoneField"
        />
      </Stack>

      {/* <Typography variant="body1">New Company</Typography>
      {JSON.stringify(newCompany)}

      <Typography variant="body1">Values</Typography>
      {JSON.stringify(values)}

      <Typography variant="body1">Touched</Typography>
      {JSON.stringify(touched)}

      <Typography variant="body1">Errors</Typography>
      {JSON.stringify(errors)} */}
    </>
  );
};

export { BusinessDetailsForm };
