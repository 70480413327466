import { createContext, useContext, useState } from 'react';
import { CompanyProfileContext } from './../CompanyProfileProvider';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';
import dayjs from 'dayjs';
import languagesDataList from '../../../data/LanguagesData.json';
import { searchApplications } from '../../../routes/applicationsRoutes';
import { searchProductTypes } from '../../../routes/productsRoutes';
import { searchCustomers } from '../../../routes/customerRoutes';
import { getMuiRichTextEditorContent } from '../../../helpers/muiRichtTextEditor';
import { useTranslation } from 'react-i18next';
import AuthContext from './../AuthProvider';
import UserContext from '../UserInfoProvider';
import { useNavigate } from 'react-router-dom';

export const UserPublicProfileContext = createContext({});

export const UserPublicProfileProvider = ({ children }) => {
  const { auth } = useContext(AuthContext);
  const { getConversations } = useContext(UserContext);
  const { getCompanyProfile } = useContext(CompanyProfileContext);
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { handleErrorResponse } = useResponseHandling();
  const navigate = useNavigate();

  // Search Products
  async function handleGetProducts(productsById) {
    if (!productsById || productsById?.length === 0) {
      return;
    }

    let userProducts = [];
    try {
      const payload = {
        key: '_id',
        values: productsById,
      };
      const { data, status } = await searchProductTypes(payload);
      if (status === 'success') {
        userProducts = data;
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    }

    return userProducts;
  }

  // Search Applications
  async function handleGetApplications(applicationsById) {
    // Guard Clause
    if (!applicationsById || applicationsById?.length === 0) {
      return;
    }

    let userApplications = [];
    try {
      const payload = {
        key: '_id',
        values: applicationsById,
      };
      const { data, status } = await searchApplications(payload);
      if (status === 'success') {
        userApplications = data;
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    }

    return userApplications;
  }

  // Search for Language Details
  function handleGetLanguages(languagesByCode) {
    // Guard Clause
    if (languagesByCode?.length === 0) {
      return [];
    }

    // Get Language Details
    let userLanguagess = [];

    languagesByCode.map((element) => {
      const languageInfo = languagesDataList.filter(
        (languageInfo) => element === languageInfo.code,
      );
      userLanguagess.push(...languageInfo);
    });

    return userLanguagess;
  }

  // Get User Profile Info
  async function getUserInfo(id) {
    // Guard Clause
    if (!auth.auth_info) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    try {
      const url = `/api/users/account/profile/user/${id}`;
      const response = await axios.get(url, { signal });

      const { data, status } = response;

      if (status === 200) {
        const userData = data.data;
        let image = '';
        if (userData?.profile_picture?.mimetype) {
          image = `data:${userData.profile_picture.mimetype};base64,${userData.profile_picture.buffer.toString('base64')}`;
        }

        let companyData = {};
        if (userData?.company?.length > 0) {
          companyData = await getCompanyProfile(userData?.company);
        }

        console.log('userData ***********', userData);

        return {
          _id: userData?._id,
          email: userData?.email,
          company: {
            _id: companyData?._id,
            name: companyData?.name,
          },
          linkedin: userData?.linkedin,
          languages: userData?.languages
            ? handleGetLanguages(userData?.languages)
            : [],
          dob: userData?.dob ? dayjs(userData?.dob) : '',
          profile_link: userData?.profile_link || '',
          address: userData?.address,
          initials: `${userData?.first_name.charAt(0).toUpperCase()} ${userData?.last_name
            .charAt(0)
            .toUpperCase()}`,
          name: `${userData?.first_name} ${userData?.last_name}`,
          product_types:
            (await handleGetProducts(userData?.product_types)) || [],
          applications:
            (await handleGetApplications(userData?.applications)) || [],
          intro: userData?.intro,
          intro_rte: userData?.intro || getMuiRichTextEditorContent(' '),
          account_type: userData?.account_type,
          profile_picture_view_url: image,
          created: userData.created,
          brands: userData.brands || [],
        };
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    }
  }

  async function handleSearchForAssociatedCustomers(id) {
    if (!id) {
      return;
    }

    const payload = {
      key: 'associated_to',
      values: [id],
    };

    try {
      const { data, statusCode, message, status } =
        await searchCustomers(payload);

      if (statusCode === 200) {
        return data;
      } else {
        return {
          message,
          status,
          statusCode,
        };
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    }
  }

  async function requestToRecruit(message, id, name) {
    const url = `/api/chat`;
    const payload = {
      message,
      id,
      chat_title: `Recruit Request ${name}`,
    };

    try {
      const { status } = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      if (status === 200) {
        getConversations();
        navigate('/app/chat');
      }
    } catch (error) {
      console.error('error', error);
    }
  }

  return (
    <UserPublicProfileContext.Provider
      value={{
        handleSearchForAssociatedCustomers,
        getUserInfo,
        requestToRecruit,
      }}
    >
      {children}
    </UserPublicProfileContext.Provider>
  );
};
