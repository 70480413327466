import { axiosPrivate } from '../hooks/axios/axios';

async function searchCustomers(payload) {
  const controller = new AbortController();
  const { signal } = controller;
  const url = '/api/customers/search';

  try {
    const response = await axiosPrivate.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    const { data, status } = response;

    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  } finally {
    controller.abort();
  }
}

export { searchCustomers };
