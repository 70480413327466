import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useResponseHandling from '../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import {
  formatMuiRichTextEditor,
  getMuiRichTextEditorContent,
} from '../../helpers/muiRichtTextEditor';
import { searchBrands } from '../../routes/brandRoutes';
import { searchApplications } from '../../routes/applicationsRoutes';
import { searchProductTypes } from '../../routes/productsRoutes';
import AuthContext from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import UserContext from './UserInfoProvider';

export const CompanyPublicProfileContext = createContext();

export const CompanyPublicProfileProvider = ({ children }) => {
  const navigate = useNavigate();
  const [currentCompanyProfile, setCurrentCompanyProfile] = useState({});
  const { auth } = useContext(AuthContext);
  const { getConversations } = useContext(UserContext);

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  // SetUp Axios
  const axios = useAxiosPrivate();

  // Search Brands in database
  async function handleGetBrands(brandsById) {
    // Guard Clause
    if (brandsById?.length === 0) {
      return;
    }

    let companyBrands = [];
    try {
      const payload = {
        key: '_id',
        values: brandsById,
      };
      const { data, status } = await searchBrands(payload);
      if (status === 'success') {
        companyBrands = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyBrands;
  }

  // Search Products
  async function handleGetProducts(productsById) {
    // Guard Clause
    if (productsById?.length === 0) {
      return;
    }

    let companyProducts = [];
    try {
      const payload = {
        key: '_id',
        values: productsById,
      };
      const { data, status } = await searchProductTypes(payload);
      if (status === 'success') {
        companyProducts = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyProducts;
  }

  // Search Applications
  async function handleGetApplications(applicationsById) {
    // Guard Clause
    if (applicationsById?.length === 0) {
      return;
    }

    let companyApplications = [];
    try {
      const payload = {
        key: '_id',
        values: applicationsById,
      };
      const { status, data } = await searchApplications(payload);
      if (status === 'success') {
        companyApplications = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyApplications;
  }

  // Handle Get Company Info
  async function getCompanyPublicProfile(id) {
    // Guard Clause
    if (!id && !auth.user_info && !auth.auth_info) {
      return;
    }

    const url = `/api/companies/company/details/${id}`;
    try {
      const response = await axios.get(url, {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data, status } = response;
      const info = data.data;

      if (status === 200) {
        // Format Image
        let image = '';
        if (info?.profile_picture?.mimetype) {
          image = `data:${info.profile_picture.mimetype};base64,${info.profile_picture.buffer.toString('base64')}`;
        }

        let companyData = {
          ...currentCompanyProfile,
          _id: info._id,
          name: info?.name,
          email: info?.email,
          address: info?.address,
          phone: info?.phone,
          type: info?.type,
          brands:
            info?.brands?.length > 0 ? await handleGetBrands(info.brands) : [],
          website: info?.website,
          colors: info?.colors,
          applications:
            (info?.applications.length > 0 &&
              (await handleGetApplications(info.applications))) ||
            [],
          products:
            (info?.products.length > 0 &&
              (await handleGetProducts(info.products))) ||
            [],
          intro: info?.intro,
          intro_rte: info?.intro || formatMuiRichTextEditor(' '),
          tow: info?.tow,
          tow_rte: info?.tow || formatMuiRichTextEditor(' '),
          tos: info?.tos,
          tos_rte: info?.tos || formatMuiRichTextEditor(' '),
          commission_schedule: info?.commission_schedule,
          profile_picture: info?.profile_picture,
          profile_picture_view_url: image,
        };

        setCurrentCompanyProfile(companyData);
        return companyData;
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  async function applyToRepresent(message, id) {
    const url = `/api/chat`;
    const payload = {
      message,
      id,
      chat_title: `Representation Request ${auth.user_info.full_name}`,
    };

    try {
      const { status } = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      if (status === 200) {
        getConversations();
        navigate('/app/chat');
      }
    } catch (error) {
      console.error('error', error);
    }
  }

  return (
    <CompanyPublicProfileContext.Provider
      value={{
        currentCompanyProfile,
        getCompanyPublicProfile,
        applyToRepresent,
      }}
    >
      {children}
    </CompanyPublicProfileContext.Provider>
  );
};
